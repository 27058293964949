import axios from 'axios';
import {
	axiosMixin
} from "./axiosMixin.js";

const moment = require('moment');

export const playFunctionMixin = {

	data() {
		return {
			retrieveData: [],
			address: '',
			city: '',
			civic: '',
			date: '',
			documentType: 'receipt',
			filename1: '',
			filename2: '',
			hasWin: false,
			statusReceipt: 600,
			validProductSum: true,
			validProductQnt: true,
			input: '',
			input2: '',
			invoiceFront: '',
			invoiceFrontBucket: '',
			invoiceFrontBucket_jwt: '',
			invoiceFrontName: '',
			invoiceRear: '',
			invoiceRearBucket: '',
			invoiceRearBucket_jwt: '',
			invoiceRearName: '',
			invoiceStatus: 'OK',
			invoiceStatusOcr: 'OK',
			justWinCheck: false,
			lastnamex: '',
			loadingState: false,
			loadingStateOcr: false,
			near: '',
			number: null,
			ocrData: {},
			ocrDataRead: {},
			ocrDataReceipt: {},
			ocrErrors: {},
			ocrProducts: {},
			oldWinPlay: {},
			pratica: 0,
			price: null,
			priceCent: null,
			phone: '',
			productsType: [{
					id: 1,
					text: 'Crodino Rosso X10'
				}, {
					id: 2,
					text: 'Crodino Rosso X8'
				}, {
					id: 3,
					text: 'Crodino Biondo X10'
				}, {
					id: 4,
					text: 'Crodino Biondo X8'
				},
				{
					id: 5,
					text: 'Crodino Biondo X6'
				}
			],
			productType: '',
			productQuantity: '',
			productAmount: '',
			products: [],
			progress: 'START',
			province: '',
			scrActiv: true,
			shakeDocType: false,
			softCheck: '',
			spec: '',
			time: {
				HH: '',
				mm: '',
			},
			UFO: false,
			uploadFrontPercentage: 0,
			uploadRearPercentage: 0,
			userToken: '',
			xuserx: '',
			zip: '',
			MAX_IMAGE_SIZE: 10240000,
			MIN_IMAGE_SIZE: 10240,
			// MIN_IMAGE_SIZE: 102400,
			showFrontInput: true,
			showRearInput: true,
			// PLAY NO LOGIN 
			first_name_player: '',
			last_name_player: '',
			username_player: '',
			phone_player: '',
			iban: '',
			productMinQnt:4,
			agreement2_player: '',
			agreement3_player: '',
			agreement4_player: '',
			agreement5_player: '',
			refund_manage_play: '',
			play_specification_play: '',
			// SPECIFIC FOR CONTEST
			productNumber: 0,
			refund: 0,
			refundArray: [{
				id: 1,
				refund: 10.00
			}, {
				id: 2,
				refund: 30.00
			}],
		};
	},


	methods: {


		setItemWW() {
			localStorage.setItem(
				'$%r332d2we24#@$234' + process.env.VUE_APP_ICODE + '1SDe452@#$%',
				'$&@(*(@Jde20)($40E#@_(_Q#($_@(*_EIS'
			);
			localStorage.setItem(
				'$%r332d324#@$234' + process.env.VUE_APP_ICODE + '245DSDe452@#$%',
				this.e(JSON.stringify(this.profile.username), false))
		},

		// CONVERT PRODUCT N IN REFUND AMOUNT 
		productNumberToRefund() {
			const arr = this.refundArray;
			const result = arr.filter(el => el.id == this.productNumber)
			this.refund = result[0].refund
		},

		winMethod(message) {

			this.progress = 'SUCCESS_WIN';
			this.pratica = message.practice_code_play;
			var _vue = this;
			setTimeout(function () {
				_vue.$scrollTo('#SUCCESS_WIN', 700, {
					offset: -110
				});
			}, 400);
			localStorage.setItem(
				'$%r332d2we24#@$234' + process.env.VUE_APP_ICODE + '1SDe452@#$%',
				'$&@(*(@Jde20)($40E#@_(_Q#($_@(*_EIS'
			);
			localStorage.setItem(
				'$%r332d324#@$234' + process.env.VUE_APP_ICODE + '245DSDe452@#$%',
				axiosMixin.methods.e(this.getProfile.email_player, false)
			);
		},

		playOkMethod() {
			const html = '<h4 class=" Bold color-2 popup-image_title" >Grazie per aver partecipato!</h4> <br> <p class="popup-image_paragraph Regular">Entro 5 giorni lavorativi da oggi <br>(esclusi i sabati, le domeniche ed i festivi) riceverai un messaggio <br> con l’esito della tua richiesta, sia in caso di moderazione positiva <br> che in caso di moderazione negativa.</p>'
			this.popupImage(html, 'reload');
		},

		loseMethod() {
			this.progress = 'SUCCESS_LOSE';
			var _vue = this;
			setTimeout(function () {
				_vue.$scrollTo('#SUCCESS_LOSE', 700, {
					offset: -110
				});
			}, 400);
		},

		checkMinProdAmount() {

			if (!this.productMinAmount) return true

			let prodottiArr = Object.values(this.productToValidate);
			prodottiArr.forEach(element => {
				element.total_product = element.quantity_product * element.amount_product
			});

			let result = prodottiArr.reduce(function (acc, obj) {
				return acc + obj.total_product;
			}, 0);


			if (result >= this.productMinAmount) {
				this.validProductSum = true
				return true
			} else {
				this.validProductSum = false
				return false
			}



		},
		checkMinProdQnt() {
			
			if (!this.productMinQnt) return true
			let prodottiArr = Object.values(this.productToValidate);
			prodottiArr.forEach(element => {
				element.total_product = element.quantity_product
			});

			let result = prodottiArr.reduce(function (acc, obj) {
				return acc + obj.total_product;
			}, 0);
			if (result >= this.productMinQnt) {
				this.validProductQnt = true
				return true
			} else {
				this.validProductQnt = false
				return false
			}



		},

		async callOCR(fileFront, fileRear) {
			this.ocrProducts = {};
			var _vue = this;

			if (process.env.VUE_APP_HAS_LOGIN == 'ON') var tk = this.getProfile.player_token_player;
			else var tk = this.userToken;
			let data = {
				icode: process.env.VUE_APP_ICODE,
				iseq: tk,
				front: fileFront,
				rear: fileRear ? fileRear : '',
			};

			let r = await axiosMixin.methods.axiosCall('api/callOcr', data, 'POST');
			let message = r.data.message;
			let statusCode = r.data.statusCode;
			this.loadingState = false
			this.loadingStateOcr = false

			try {
				if (r.status < parseInt(process.env.VUE_APP_CATCH)) { // then
					if (message) {
						this.statusReceipt = statusCode;

						this.popupErrorCode(this.statusReceipt);
						_vue.ocrDataRead = message;
						_vue.ocrDataReceipt = _vue.ocrDataRead.receipt
						///////////////////////////////////////////////////////////
						/////////////////////////RESET/////////////////////////////
						///////////////////////////////////////////////////////////
						_vue.price = '';
						_vue.priceCent = '';
						_vue.date = '';
						_vue.time.HH = '';
						_vue.time.mm = '';
						_vue.number = '';
						///////////////////////////////////////////////////////////
						////////////////////STATUS CONTROL/////////////////////////
						///////////////////////////////////////////////////////////
						switch (this.statusReceipt) {
							case 601: //OCR_DATE_TIME_AMOUNT_NUMBER_MISSING
							case 602: //OCR_DATE_TIME_AMOUNT_MISSING
							case 603: //OCR_DATE_TIME_NUMBER_MISSING
							case 604: //OCR_DATE_AMOUNT_NUMBER_MISSING
							case 606: //OCR_DATE_TIME_MISSING
							case 607: //OCR_DATE_AMOUNT_MISSING
							case 608: //OCR_DATE_NUMBER_MISSING
							case 611: //OCR_DATE_MISSING
							case 615: //OCR_LOW_RESOLUTION
							case 616: //OCR_IS_NOT_A_RECEIPT
							case 617: //OCR_RECEIPT_DATE_BEFORE_VALIDY_TIME
							case 618: //OCR_RECEIPT_DATE_AFTER_END_VALIDY_TIME
							case 619: //OCR_DUPLICATE_IMAGE
							case 620: //OCR_DATE_ERROR
								//POPUP ERRORE CON CODICE
								this.saveOcrError(_vue.ocrDataRead, this.statusReceipt)
								break;
						}
						///////////////////////////////////////////////////////////
						////////////////////DATE CONTROL///////////////////////////
						///////////////////////////////////////////////////////////
						var date = moment(_vue.ocrDataRead.receipt.date, 'DD/MM/YYYY');
						var now = moment();
						var then = moment(date);
						var dif = then.diff(now);
						var duration = moment.duration(dif, 'milliseconds');
						var days = duration.asDays();
						_vue.date = date.format('YYYY-MM-DD');


						if (days > process.env.VUE_APP_MAX_BEFORE_NOW_DAYS && process.env.VUE_APP_MAXDAYS == 'ON') {
							this.statusReceipt = 621
							//POP UP GIOCATA DOPO I MAX NUM DAYS COD 621
						}
						///////////////////////////////////////////////////////////
						////////////////////TIME CONTROL///////////////////////////
						///////////////////////////////////////////////////////////
						if (_vue.ocrDataRead.receipt.type != 'ddc') {
							if (_vue.ocrDataRead.receipt.time) {
								var time = _vue.ocrDataRead.receipt.time;
								var hour = time.substring(0, 2);
								var minute = time.substring(3, 5);
								if (parseInt(hour) >= 0 && parseInt(hour) <= 24 && minute >= 0 && minute <= 60) {
									_vue.time = {
										HH: hour,
										mm: minute
									};
								}
							}
						} else _vue.time = {
							HH: '00',
							mm: '00'
						};

						///////////////////////////////////////////////////////////
						////////////////////AMOUNT CONTROL/////////////////////////
						///////////////////////////////////////////////////////////
						var amount = _vue.ocrDataRead.receipt.amount.split(',');
						_vue.price = amount[0];
						_vue.priceCent = amount[1];

						if (parseInt(_vue.price) < process.env.VUE_APP_OCR_MIN_AMOUNT) {
							this.statusReceipt = 622
							//POP UP ERRORE IMPORTO MINIMO NON RAGGIUNTO CON CODICE 622
						}
						///////////////////////////////////////////////////////////
						////////////////////NUMBER CONTROL/////////////////////////
						///////////////////////////////////////////////////////////
						var num = _vue.ocrDataRead.receipt.number;
						num = num.toString();
						num = num.replace('-', '');
						_vue.number = num;
						///////////////////////////////////////////////////////////
						////////////////////STATUS CONTROL/////////////////////////
						///////////////////////////////////////////////////////////
						switch (this.statusReceipt) {
							case 600: //OCR_RECEIPT_DATE_OK
							case 605: //OCR_TIME_AMOUNT_NUMBER_MISSING
							case 609: //OCR_TIME_AMOUNT_MISSING
							case 610: //OCR_TIME_NUMBER_MISSING
							case 612: //OCR_TIME_MISSING
							case 613: //OCR_AMOUNT_MISSING
							case 614: //OCR_NUMBER_MISSING
								break;
							case 621: //PLAY_AFTER_MAX_DAYS
							case 622: //MIN_AMOUNT_NOT_ACHIEVED
								this.saveOcrError(_vue.ocrDataRead, this.statusReceipt)
								break;
						}
					}
				} else { //catch
					this.statusReceipt = statusCode;
					_vue.ocrDataRead = message;
					this.saveOcrError(_vue.ocrDataRead, this.statusReceipt)

					//POP UP CON STATUS RECEIPT 619
					this.popupErrorCode(this.statusReceipt);

				}
			} catch (error) {

			}
		},

		async saveOcrError(ocrDataRead, statusReceipt) {
			var _vue = this
			// PLAYER
			let username_player = this.getProfile.username_player || ''
			let phone_player = this.getProfile.phone_player || ''
			let first_name_player = this.getProfile.first_name_player || ''
			let last_name_player = this.getProfile.last_name_player || ''
			let first_name_play = this.getProfile.first_name_player || ''
			let last_name_play = this.getProfile.last_name_player || ''
			let iban_player = this.getProfile.iban || ''
			this.iban_owner_player = first_name_player + ' ' + last_name_player
			let iban_owner_player = this.getProfile.iban_owner_player || ''
			let agreement2_player = this.getProfile.agreement2_player || 0
			let agreement3_player = this.getProfile.agreement3_player || 0
			// PLAY
			if (ocrDataRead.receipt.date) {
				var date = moment(ocrDataRead.receipt.date, 'DD/MM/YYYY');
				this.date = date.format('YYYY-MM-DD');
			} else this.date = moment().format('YYYY-MM-DD')
			let username_play = this.getProfile.username_player || ''
			let player_token_play = this.getProfile.player_token_player || ''
			let front_picture_play = this.invoiceFrontBucket || ''
			let rear_picture_play = this.invoiceRearBucket || ''
			let receipt_date_play = this.date
			let receipt_time_play = ocrDataRead.receipt.time || ''
			let receipt_amount_play = ocrDataRead.receipt.amount || 0
			let receipt_number_play = ocrDataRead.receipt.number || 0
			let support_id_play = this.getProfile.support_id_player || ''
			let result_play = 405
			let play_specification_play = this.productNumber || ''
			// RECEIPT
			let player_token_receipt = this.getProfile.player_token_player || ''
			let front_url_receipt = this.invoiceFrontBucket || ''
			let rear_url_receipt = this.invoiceRearBucket || ''
			let status_receipt = statusReceipt || ''
			let response_ocr_receipt = this.ocrDataRead || ''

			let data = {
				play: {
					first_name_play,
					last_name_play,
					username_play,
					player_token_play,
					front_picture_play,
					rear_picture_play,
					receipt_date_play,
					receipt_time_play,
					receipt_amount_play,
					receipt_number_play,
					support_id_play,
					result_play,
					play_specification_play,
				},
				receipt: {
					player_token_receipt,
					front_url_receipt,
					rear_url_receipt,
					status_receipt,
					response_ocr_receipt,
				}
			};


			let r = await axiosMixin.methods.axiosCall('api/play/web', data, 'POST');

			let message = r.data.message;
			let statusCode = r.data.statusCode;
			let description = r.data.message.description;

			try {
				if (r.status < parseInt(process.env.VUE_APP_CATCH)) { // then
					if (message) {
						_vue.invoiceFront = '';
						_vue.invoiceFrontName = '';
						_vue.invoiceFrontBucket = '';
						_vue.uploadFrontPercentage = 0;
						_vue.invoiceRear = '';
						_vue.invoiceRearName = '';
						_vue.invoiceRearBucket = '';
						_vue.UFO = true;
						_vue.ocrErrors = {};
						_vue.haveError = false; /* ??? */
					}
				}
			} catch (error) {}
		},



		async updateShipData(sendData = null) {

			this.loadingState = true;

			let manage_play;

			if (!sendData) {
				manage_play = {
					// CHANGE PRACTICE_CODE IF CALL FUNCTION FROM PLAY (this.pratica) OR RECEIPT COMPONENT (this.selectedPlay.practice_code_play)
					practice_code_manage_play: this.pratica != 0 ? this.pratica : this.selectedPlay.practice_code_play,
					player_token_manage_play: this.getProfile.player_token_player,
					shipping_first_name_manage_play: this.xuserx,
					shipping_last_name_manage_play: this.lastnamex,
					shipping_phone_manage_play: this.phone,
					shipping_address_manage_play: this.address,
					shipping_civic_number_manage_play: this.civic,
					shipping_province_manage_play: this.province,
					shipping_city_manage_play: this.city.city,
					shipping_zip_manage_play: this.zip,
					shipping_notes_manage_play: this.near
				}
			} else {
				let objData = JSON.parse(sendData)
				manage_play = {
					shipping_first_name_manage_play: objData.name,
					shipping_last_name_manage_play: objData.lastName,
					shipping_phone_manage_play: objData.phone,
					shipping_address_manage_play: objData.address,
					shipping_civic_number_manage_play: objData.civic,
					shipping_province_manage_play: objData.province,
					shipping_city_manage_play: objData.city.city,
					shipping_zip_manage_play: objData.zip,
					shipping_notes_manage_play: objData.note,
					field6_manage_play: sendData,
					practice_code_manage_play: this.practice_code_play,
					player_token_manage_play: this.player_token_player,
				}
			}

			let data = {
				manage_play,
			};

			let r = await axiosMixin.methods.axiosCall('api/shippingData', data, 'POST');
			let message = r.data.message;
			let statusCode = r.data.statusCode;

			this.loadingState = false;
			try {
				if (r.status < parseInt(process.env.VUE_APP_CATCH)) { // then
					if (message) {
						const shipping = message
						if (statusCode == 1200 && shipping.length > 0) {
							this.popupErrorText('success', 'OK', 'Dati salvati correttamete', '', 'home');
						} else {
							this.popupErrorText('error', 'OPS', 'Errore nel salvataggio', '', '');
						}
					}
				} else { //catch
					this.popupErrorText('error', 'OPS', 'Errore nel salvataggio', '', '');
				}
			} catch (error) {
				this.popupErrorText('error', 'OPS', 'Errore nel salvataggio', '', '');
			}

		},

		async editUser() {

			let data = {
				player: {
					player_token_player: this.profile.player_token_player || '',
					first_name_player: this.first_name_player || '',
					last_name_player: this.last_name_player || '',
					// email_player: this.profile.username_player || '',
					// phone_player: this.profile.phone_player || '',
					agreement2_player: this.agreement2_player,
					agreement3_player: this.agreement3_player,
				}
			};

			this.loadingState = true
			let r = await axiosMixin.methods.axiosCall('api/editUser', data, 'POST');
			this.loadingState = false
			let message = r.data.message;

			try {
				if (r.status < parseInt(process.env.VUE_APP_CATCH)) { // then

					if (message) {

						this.popupErrorText('success', 'OK', 'I tuoi dati di registrazione sono stati correttamente aggiornati', '', 'reload');
						this.$store.dispatch(USER_REQUEST, this.userToken).then(() => {});
					}
				} else { //catch
					this.showAlertError(message);
				}
			} catch (error) {}
		},


		async getIbanCount() {
			let _vue = this
			await this.checkAll('manage_play', 'iban_manage_play', this.iban);
			const arr = this.checkAllIban
			const result = arr.filter(el => el.validation_manage_play != 2)

			if (result.length < 2) {
				this.$track('partecipazione attive', result.length)

				this.productNumberToRefund();

				this.$validator.validate().then((valid) => {
					if (valid) {
						_vue.play()

					}
				})

			} else {
				// hai raggiunto il num max di partecipazione consentite per questa promo 
				this.$track('max partecipazioni raggiunte', result.length)
				const html = '<h4 class=" Bold color-2 popup-image_title" >Attenzione</h4> <br> <p class="popup-image_paragraph Regular">Hai già raggiunto il massimo numero di <br> partecipazioni valide consentite.<br> </p>'
				this.popupImage(html, 'reload');
			}
		},


		async play() {
			this.scrActiv = false;
			// this.checkAll('manage_play', 'iban_manage_play', this.iban)

			if (this.documentType == 'receipt') {
				var sale_time = this.time.HH + ':' + this.time.mm + ':00';
			} else {
				var sale_time = '00:00:01';
			}

			if (
				process.env.VUE_APP_INSTANT_WIN == 'ON' &&
				process.env.VUE_APP_MORE_WIN == 'OFF'

			) {
				var xkeyID = ''
				var xkey = localStorage.getItem(
					'$%r332d2we24#@$234' + process.env.VUE_APP_ICODE + '1SDe452@#$%'
				);
				if (xkey == '$&@(*(@Jde20)($40E#@_(_Q#($_@(*_EIS') {
					xkeyID = localStorage.getItem(
						'$%r332d324#@$234' + process.env.VUE_APP_ICODE + '245DSDe452@#$%'
					);

				}
			}

			// PLAYER
			let username_player = this.getProfile.username_player || ''
			let phone_player = this.getProfile.phone_player || ''
			let first_name_player = this.getProfile.first_name_player || ''
			let last_name_player = this.getProfile.last_name_player || ''
			let first_name_play = this.getProfile.first_name_player || ''
			let last_name_play = this.getProfile.last_name_player || ''
			let iban_player = this.getProfile.iban || ''
			this.iban_owner_player = first_name_player + ' ' + last_name_player
			let iban_owner_player = this.getProfile.iban_owner_player || ''
			let agreement2_player = this.getProfile.agreement2_player || 0
			let agreement3_player = this.getProfile.agreement3_player || 0

			// PLAY
			let username_play = this.getProfile.username_player || ''
			let player_token_play = this.getProfile.player_token_player || ''
			let front_picture_play = this.invoiceFrontBucket || ''
			let rear_picture_play = this.invoiceRearBucket || ''
			let receipt_date_play = this.date ? moment(this.date).format('YYYY-MM-DD') : ''
			let receipt_time_play = sale_time || ''
			let receipt_amount_play = (this.price && this.priceCent) ? parseInt(this.price) + '.' + this.priceCent : ''
			let receipt_number_play = this.number || ''
			let support_id_play = this.getProfile.support_id_player || ''
			let result_play = this.invoiceStatus
			let play_specification_play = this.productNumber || ''

			// RECEIPT
			let player_token_receipt = this.getProfile.player_token_player || ''
			let front_url_receipt = this.invoiceFrontBucket || ''
			let rear_url_receipt = this.invoiceRearBucket || ''
			let status_receipt = this.statusReceipt || ''
			let response_ocr_receipt = this.ocrDataRead || ''

			// MANAGE_PLAY
			let player_token_manage_play = this.getProfile.player_token_player || ''
			let url1_manage_play = this.invoiceFrontBucket || ''
			let url2_manage_play = this.invoiceRearBucket || ''
			let iban_manage_play = this.getProfile.iban || ''
			let iban_owner_manage_play = first_name_player + ' ' + last_name_player

			// REFUND
			let refund_manage_play = this.refund || ''

			// PRODUCT
			let prodottiArr = Object.values(this.productToValidate);
			prodottiArr.forEach(element => {
				element.total_product = element.quantity_product * element.amount_product
			});

			let data = {
				product: prodottiArr,


				// product: this.products,
				// product: this.productToValidate,

				play: {
					first_name_play,
					last_name_play,
					username_play,
					player_token_play,
					front_picture_play,
					rear_picture_play,
					receipt_date_play,
					receipt_time_play,
					receipt_amount_play,
					receipt_number_play,
					support_id_play,
					result_play,
					play_specification_play,
				},
				supreme: {
					receipt_date_play,
					receipt_time_play,
					receipt_amount_play,
					receipt_number_play,
				},
				receipt: {
					player_token_receipt,
					front_url_receipt,
					rear_url_receipt,
					status_receipt,
					response_ocr_receipt,
				},
				manage_play: {
					player_token_manage_play,
					iban_manage_play,
					iban_owner_manage_play,
					url1_manage_play,
					url2_manage_play,
					refund_manage_play
				},
				player: {
					username_player,
					first_name_player,
					last_name_player,
					iban_player,
					iban_owner_player,
					phone_player,
					agreement2_player,
					agreement3_player
				},
				xkeyID,
			};




			this.loadingState = true;
			let r = await axiosMixin.methods.axiosCall('api/play/web', data, 'POST');
			this.loadingState = false;
			let message = r.data.message;

			try {
				if (r.status < parseInt(process.env.VUE_APP_CATCH)) { // then

					if (message) {
						const response = message.result_play;
						switch (response) {
              case 401:
              case 402:
                this.winMethod(message)
                break;
              case 400:
              case 403:
              case 404:
              default:
                this.popupErrorCode(response);
                break;
            }
						this.gtagCheck('play', this.profile.mail, this.progress, this.progress);
					}
				}
			} catch (error) {

			}

		},

		async retrivePlays(token = null) {
			this.loadingState = true
			var _vue = this;

			if ((_vue.profile.player_token_player != undefined && _vue.profile.player_token_player != '') || token) {
				let data = {
					player_token_player: token ? token : _vue.profile.player_token_player
				};

				let r = await axiosMixin.methods.axiosCall('api/myAttempt', data, 'POST');
				let message = r.data.message;

				this.loadingState = false

				try {

					if (r.status < parseInt(process.env.VUE_APP_CATCH)) { // then

						if (message) {
							this.retrieveData = r
							let win = [];
							if (message.length > 0) {
								win = message.find(el => el.result_play == 401 && [0, 1, 10].includes(el.validation_manage_play));
							}

							if (win && win.length > 0) {
								if (process.env.VUE_APP_CAN_PLAY_AFTER_WIN == 'ON') _vue.progress = 'START'
								else _vue.progress = 'ALREADY_WIN'
								if (_vue.progress == 'ALREADY_WIN' &&
									process.env.VUE_APP_MORE_WIN == 'ON' && win.length < process.env.VUE_APP_HOW_MANY_MORE_WIN) {
									_vue.progress = 'START'
								}
								this.setItemWW()
							}
						}
					}
				} catch (error) {

				}
			} else {
				this.loadingState = false
			}
		},



		shakeDocTypeTrigger: function () {
			this.shakeDocType = true;
			var _vue = this;
			setTimeout(function () {
				_vue.shakeDocType = false;
			}, 1500);
		},

		uploadFile: function () {


			var input = this.$refs.fileInput1;
			var input2;

			if (this.$refs.fileInput2) {
				typeof this.$refs.fileInput2.files[0] != 'undefined' ? input2 = this.$refs.fileInput2 : input2 = false
			} else {
				input2 = false
			}

			if (this.documentType) {
				this.loadingStateOcr = true;

				var file = input.files[0].name;
				var file2 = input2 ? input2.files[0].name : false;
				this.filename1 = file;
				this.filename2 = input2 ? file2 : false;

				var extension = input.files[0].type;

				var extension2 = input2 ? input2.files[0].type : 'image/jpeg';
				var size = input.files[0].size;
				var size2 = input2 ? input2.files[0].size : 491942;
				var _vue = this; // << here

				if (
					_vue.documentType != 'receipt' ||
					extension == 'application/pdf'
				) {
					size = 102500;
				}
				if (
					(extension == 'image/jpeg' ||
						extension == 'image/png' ||
						extension == 'application/pdf') &&
					(extension2 == 'image/jpeg' ||
						extension2 == 'image/png' ||
						extension2 == 'application/pdf') &&
					size < this.MAX_IMAGE_SIZE &&
					size2 < this.MAX_IMAGE_SIZE &&
					size > this.MIN_IMAGE_SIZE &&
					size2 > this.MIN_IMAGE_SIZE
				) {
					this.invoiceFront = this.filename1;
					this.invoiceRear = input2 ? this.filename2 : false;

					var formData = new FormData();

					formData.append('file', input.files[0]);
					if (input2) formData.append('file2', input2.files[0]);
					var keyP = axiosMixin.methods.e({
						p: 'a'
					});

					formData.append('key', keyP);
					axios
						.post(process.env.VUE_APP_ROOT_API + 'api/uploadImage', formData, {
							headers: {
								'Content-Type': 'multipart/form-data',
								Authorization: 'Bearer ' + process.env.VUE_APP_KITTY,
							},
							onUploadProgress: function (progressEvent) {
								this.uploadFrontPercentage = parseInt(
									Math.round((progressEvent.loaded * 100) / progressEvent.total)
								);
								this.uploadRearPercentage = parseInt(
									Math.round((progressEvent.loaded * 100) / progressEvent.total)
								);
							}.bind(this),
						})
						.then(function (e) {


							e.data = _vue.k(e.data, process.env.VUE_APP_DOGGY);

							_vue.invoiceFrontBucket = e.data.message.url1;
							_vue.invoiceFrontBucket_jwt = e.data.message.url1_jwt;

							_vue.invoiceFrontBucket ? _vue.showFrontInput = false : ''

							if (_vue.filename2) {
								_vue.invoiceRearBucket = e.data.message.url2;
								_vue.invoiceRearBucket_jwt = e.data.message.url2_jwt;

								_vue.invoiceRearBucket ? _vue.showRearInput = false : ''

							} else e.data.description == 'not'
							if (process.env.VUE_APP_HAS_OCR == 'OFF') {
								if (
									e.data.description == 'dublicate' ||
									e.data.description == 'dublicate'
								) {
									if (e.data.description == 'dublicate') {
										_vue.showDublicateImage(_vue.filename1);
									} else {
										if (_vue.filename2) _vue.showDublicateImage(_vue.filename2);
									}
									// SAVE PLAY
									_vue.saveOcrError(e.data, 'beforeOcr');

									_vue.uploadFrontPercentage = 0;
									_vue.invoiceFront = '';
									_vue.invoiceFrontBucket = '';
									_vue.input = input;
									_vue.filename1 = '';
									input.value = null;
									_vue.uploadRearPercentage = 0;
									_vue.invoiceRear = '';
									_vue.invoiceRearBucket = '';
									_vue.input2 = input2;
									_vue.filename2 = '';
									if (input2) input2.value = null;
									_vue.loadingStateOcr = false;
								} else {
									_vue.input = input;
									_vue.input2 = input2;
									_vue.softCheck = '';
									//if (_vue.filename2) _vue.callOCR(_vue.invoiceFrontBucket_jwt, _vue.invoiceRearBucket_jwt);
									//else _vue.callOCR(_vue.invoiceFrontBucket_jwt, false);
								}
							} else {

								_vue.loadingStateOcr = false


								_vue.input = input;
								_vue.input2 = input2;
								_vue.softCheck = '';
							}

						})
						.catch(function () {
							_vue.loadingStateOcr = false;
							_vue.uploadFrontPercentage = 0;
							_vue.invoiceFront = '';
							_vue.invoiceFrontBucket = '';
							_vue.input = input;
							_vue.filename1 = '';
							input.value = null;
							_vue.uploadRearPercentage = 0;
							_vue.invoiceRear = '';
							_vue.invoiceRearBucket = '';
							_vue.input2 = input2 ? input2 : '';
							_vue.filename2 = '';
							if (input2) input2.value = null;
							_vue.UploadError();
						});
				} else {



					// ERROR FORMAT OR SIZE
					_vue.uploadFrontPercentage = 0;
					_vue.invoiceFront = '';
					_vue.invoiceFrontBucket = '';
					_vue.input = input;
					_vue.filename1 = '';
					input.value = input ? null : ''
					_vue.loadingStateOcr = false;



					_vue.uploadRearPercentage = 0;
					_vue.invoiceRear = '';
					_vue.invoiceRearBucket = '';
					_vue.input2 = input2;
					_vue.filename2 = '';


					if (input2) {
						input2.value = input2 ? null : ''
					}

					_vue.loadingStateOcr = false;


					if (
						extension != 'image/jpeg' &&
						extension != 'image/png' &&
						extension != 'application/pdf' &&
						extension2 != 'image/jpeg' &&
						extension2 != 'image/png' &&
						extension2 != 'application/pdf'
					) {


						this.popupErrorText('error', 'Ops...', 'Attenzione, il formato immagine non è corretto')
					} else {

						if (size < this.MIN_IMAGE_SIZE || size2 < this.MIN_IMAGE_SIZE) {
							this.popupErrorText('error', 'Ops...', 'Attenzione, la dimensione del file non è corretta')

						} else {
							this.popupErrorText('error', 'Ops...', 'Attenzione, la dimensione del file non è corretta')

						}
					}
				}
			} else {

				this.shakeDocType = true;
				var _vue = this;
				setTimeout(function () {
					_vue.shakeDocType = false;
				}, 1500);
			}
		},


		k(data, key) {
			return axiosMixin.methods.d(data);
		},
		uploadStep2() {
			var _vue = this;
			this.$swal({
				//input: 'text',
				confirmButtonText: 'Carica RETRO ',
				cancelButtonText: 'Prosegui',
				reverseButtons: true,
				focusConfirm: false,
				focusCancel: true,
				confirmButtonColor: '#c95c1c',
				cancelButtonColor: '#000000',
				showCancelButton: true,
				allowOutsideClick: false,
				title: '<h3 class="title widthFull">Immagine retro scontrino</h3>',
				html: '<p class="smallText text-center ">Se sono presenti delle informazioni anche sul retro dello scontrino, clicca su carica retro, ' +
					'altrimenti clicca sul pulsante `Prosegui`</p>' +
					'',
			}).then((result) => {
				if (result.value) {
					// DOUBLE OCR WITH SPLIT 2 FILE
					_vue.UFO = false;
					_vue.$refs.fileInput2.click();
				} else {
					// STANDARD OCR WITH 1 FILE
					_vue.UFO = true;
					_vue.uploadFile();
				}
			});
		},

	},


	mounted() {
		// this.progress = 'SUCCESS_WIN'
	},


}