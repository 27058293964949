<template>
	<div class="play w-100">
		<loading v-if="loadingState || !progress" />
		<div class="col-12">
			<div
				v-if="progress == 'START'"
				class="row justify-content-center pb-5 back-2"
			>
				<div class="col-12 white">
					<div class="row justify-content-center py-4">
						<div class="col-12 col-md-8 center justify-content-center d-flex">
							<div class="w-25 px-2 pl-md-3 pr-0 line-h2">
								<hr />
							</div>
							<h1 class="text-center">Compila il modulo</h1>
							<div class="w-25 px-2 pr-md-3 pl-0 line-h2">
								<hr />
							</div>
						</div>

						<div class="col-12 col-md-8">
							<h5 class="white text-center">Tutti i campi sono obbligatori</h5>
						</div>
					</div>

					<form-wizard
						title=""
						subtitle=""
						class="mt-0 pb-5 custForm"
						style="background: transparent !important"
						nextButtonText="PROCEDI"
						backButtonText="INDIETRO"
						finishButtonText="INVIA"
						@on-change="stepIndex"
						:stepSize="stepSize"
						@on-complete="onComplete"
						color="transparent"
						errorColor="#b60920"
					>
						<tab-content
							title="Carica lo scontrino"
							icon=""
						>
							<form
								class="widthFull pb-4"
								autocomplete="qwerqrt"
							>
								<div class="form-row justify-content-center">
									<div class="col-md-8 col-lg-4">
										<div class="group">
											<label for="first_name_player">Nome </label>
											<input
												type="text"
												class="form-control"
												v-model="first_name_player"
												id="first_name_player"
												name="first_name_player"
												data-vv-as="Nome"
												v-validate="{ required: true, max: 128, min: 2 }"
												:class="{
                          'is-invalid': errors.has('first_name_player'),
                          valid: !!first_name_player,
                        }"
											/>

											<div
												v-if="errors.has('first_name_player')"
												class="invalid-feedback"
											>
												{{ errors.first("first_name_player") }}
											</div>
										</div>
									</div>

									<div class="col-md-8 col-lg-4">
										<div class="group">
											<label for="last_name_player">Cognome</label>
											<input
												type="text"
												class="form-control"
												v-model="last_name_player"
												id="last_name_player"
												name="last_name_player"
												data-vv-as="Cognome"
												v-validate="{ required: true, max: 128, min: 2 }"
												:class="{
                          'is-invalid': errors.has('last_name_player'),
                          valid: !!last_name_player,
                        }"
											/>
											<span class="highlight"></span>

											<div
												v-if="errors.has('last_name_player')"
												class="invalid-feedback"
											>
												{{ errors.first("last_name_player") }}
											</div>
										</div>
									</div>
								</div>

								<div class="form-row justify-content-center">
									<div class="col-md-8 col-lg-4">
										<div class="group">
											<label for="phone_player">Telefono</label>
											<input
												type="text"
												class="form-control"
												v-model="phone_player"
												id="phone_player"
												name="phone_player"
												data-vv-as="Telefono"
												v-validate="{
                          required: true,
                          max: 11,
                          min: 8,
                          regex: /^[3]+/,
                        }"
												:class="{
                          'is-invalid': errors.has('phone_player'),
                          valid: !!phone_player,
                        }"
											/>
											<span class="highlight"></span>

											<div
												v-if="existPhone"
												class="invalid-feedback"
												style="display: block"
											>
												<span>Numero di telefono già presente</span>
											</div>
											<div
												v-if="errors.has('phone_player')"
												class="invalid-feedback"
											>
												{{ errors.first("phone_player") }}
											</div>
										</div>
									</div>

									<div class="col-md-8 col-lg-4">
										<div class="group">
											<label for="username_player">Indirizzo E-Mail</label>
											<input
												type="text"
												v-model="username_player"
												class="form-control"
												id="username_player"
												name="username_player"
												ref="username_player"
												data-vv-as="Indirizzo E-Mail"
												v-validate="{
                          required: true,
                          email: true,
                          temporaryEmail: true,
                          plusIssueEmail: true,
                          max: 56,
                        }"
												:class="{
                          'is-invalid':
                            errors.has('username_player') || existEmail,
                          valid: !!username_player,
                        }"
											/>
											<span class="highlight"></span>

											<div
												v-if="existEmail"
												class="invalid-feedback"
												style="display: block"
											>
												<span v-if="existEmailSocial">Email digitato già presente. Sei registrato con:
													<strong class="text-capitalize">{{
                            existEmailSocial
                          }}</strong></span>
												<span v-else>Email digitato già presente</span>
											</div>
											<div
												v-if="errors.has('username_player')"
												class="invalid-feedback"
											>
												{{ errors.first("username_player") }}
											</div>
										</div>
									</div>
								</div>

								<div class="form-row">
									<!-- iban -->
									<div class="col-md-8 col-lg-4 offset-md-2">
										<div class="group">
											<label for="iban">IBAN (corrispondente ad un conto corrente intestato a
												chi richiede il rimborso)</label>

											<!-- @blur="checkIfIban" -->

											<input
												type="text"
												class="form-control"
												v-model="iban"
												id="iban"
												name="iban"
												data-vv-as="IBAN"
												v-validate="{
                          required: true,
                          ibanItalian: true,
                          ibanControl: true,
                          min: 5,
                        }"
												:class="{
                          'is-invalid': errors.has('iban'),
                          valid: !!iban,
                        }"
											/>
											<span class="highlight"></span>
											<span class="bar"></span>

											<div
												v-if="existIban && iban"
												class="invalid-feedback yellow"
												style="display: block"
											>
												<span>Questo iban risulta già inserito</span>
											</div>

											<div
												v-if="errors.has('iban')"
												class="invalid-feedback"
											>
												{{ errors.first("iban") }}
											</div>
										</div>
									</div>
								</div>

								<div class="form-row">
									<div class="col-md-8 col-lg-4 offset-md-2">
										<div class="row">
											<div class="col-12 col-md-6">
												<div class="group">
													<label for="date">Data scontrino</label>
													<datepicker
														:language="it"
														v-model="date"
														:disabled="!!ocrDataReceipt.date"
														:full-month-name="true"
														:initialView="'month'"
														:format="'dd MMMM yyyy'"
														:monday-first="true"
														:disabledDates="disabledDates"
														id="date"
														name="date"
														input-class="form-control "
													></datepicker>

													<input
														type="text"
														v-show="false"
														v-model="date"
														class="form-control datepicker"
														id="date"
														name="date"
														data-vv-as="Data scontrino"
														v-validate="{ required: true }"
														:class="{
                              'is-invalid': errors.has('date'),
                              valid: !!date,
                            }"
													/>

													<span class="highlight"></span>
													<span class="bar"></span>

													<div
														v-if="errors.has('date')"
														class="invalid-feedback"
													>
														{{ errors.first("date") }}
													</div>
												</div>
											</div>
											<div class="col-12 col-md-6">
												<div class="group">
													<label for="time">Ora scontrino</label>
													<vue-timepicker
														v-if="!ocrDataReceipt.time"
														v-model="time"
														class="form-control"
														:disabled="!!ocrDataReceipt.time"
														:hideClearButton="true"
														data-vv-validate-on="none"
														id="time"
														name="time"
														data-vv-as="Ora Scontrino"
														v-validate="{ emptyTime: true }"
														:class="{
                              disabledTime: !!time.HH && !!time.mm,
                              'is-invalid': errors.has('time'),
                              valid: !!time.HH != '',
                            }"
													></vue-timepicker>

													<div
														v-else
														class="form-control valid"
													>
														{{ ocrDataReceipt.time }}
													</div>

													<span class="highlight"></span>
													<span class="bar"></span>

													<div
														v-if="errors.has('time')"
														class="invalid-feedback"
													>
														{{ errors.first("time") }}
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col-md-8 col-lg-4 offset-md-2 offset-lg-0">
										<div class="row">
											<div class="col-12 col-md-6">
												<div class="group">
													<label
														for="price "
														class=""
													>Importo scontrino</label>

													<div class="d-flex justify-content-between">
														<div class="flex-grow-1">
															<input
																type="text"
																v-model="price"
																@focus="priceFocused = true"
																:disabled="!!ocrDataReceipt.amount"
																class="form-control text-right euroInput"
																id="price"
																name="price"
																data-vv-as="Importo"
																v-validate="{
                                  required: true,
                                  regex: /^([0-9][0-9]*)$/,
                                  max: 4,
                                }"
																:class="{
                                  'is-invalid': errors.has('price'),
                                  valid: !!price,
                                }"
															/>
															<span
																v-if="priceFocused || priceCent || price"
																class="pseudoEuro Bold color-2"
															>&euro;</span>

															<div
																v-if="errors.has('price')"
																class="invalid-feedback"
															>
																{{ errors.first("price") }}
															</div>
														</div>
														<div>
															<h2 class="text-center">,</h2>
														</div>
														<div class="flex-grow-1">
															<input
																type="text"
																v-model="priceCent"
																@focus="priceFocused = true"
																:disabled="!!ocrDataReceipt.amount"
																class="form-control text-left centInput"
																id="priceCent"
																name="priceCent"
																data-vv-as="Centesimi"
																v-validate="{
                                  required: true,
                                  min: 2,
                                  max: 2,
                                  regex: /^([0-9]+)$/,
                                }"
																:class="{
                                  'is-invalid': errors.has('priceCent'),
                                  valid: !!priceCent,
                                }"
															/>
															<span
																v-if="priceFocused || priceCent || price"
																class="pseudoCent color-2 Bold"
															>Cent.</span>

															<div
																v-if="
                                  errors.has('priceCent') &&
                                  !errors.has('price')
                                "
																class="invalid-feedback"
															>
																{{ errors.first("priceCent") }}
															</div>
														</div>
													</div>
												</div>
											</div>

											<div class="col-12 col-md-6">
												<div class="group">
													<label for="number">Numero scontrino*</label>
													<input
														type="number"
														v-model="number"
														:disabled="!!ocrDataReceipt.number"
														class="form-control"
														id="number"
														name="number"
														data-vv-as="Numero scontrino"
														v-validate="{
                              required: true,
                              integer: true,
                              is_not: 0,
                            }"
														:class="{
                              'is-invalid': errors.has('number'),
                              valid: !!number,
                            }"
													/>
													<span class="highlight"></span>
													<span class="bar"></span>
													<!-- <label
												class="niceInputLabel"
												for="number"
											>Numero scontrino</label> -->

													<div
														v-if="errors.has('number')"
														class="invalid-feedback"
													>
														{{ errors.first("number") }}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="row justify-content-center">
									<div class="col-12 col-md-8 col-lg-8">
										<div class="group">
											<label
												class="lable-style-custom fa-thermometer-1 mb-0 pb-0"
												for="tappa"
											>Allega in un’unica fotografia in formato jpeg
												l'immagine dello scontrino o il messaggio di conferma
												d'ordine, insieme ai prodotti in promozione acquistati
												acquistati
											</label>
										</div>
									</div>

									<div class="col-12 col-md-8 col-lg-4 offset-lg-2">
										<div
											class="group mt-0"
											:class="{ 'ok-sign': uploadFrontPercentage == 100 }"
										>
											<input
												type="file"
												v-on:change="uploadFile"
												ref="fileInput1"
												name="invoiceFront"
												id="invoiceFront"
												class="form-control"
												data-vv-as="Fronte"
												v-validate="{
                          required: true,
                          size: 10240,
                          ext: ['jpeg', 'jpg', 'png', 'pdf'],
                          mimes: [
                            'image/jpeg',
                            'image/jpg',
                            'image/png',
                            'application/pdf',
                          ],
                        }"
												:class="{
                          'is-invalid': errors.has('invoiceFront'),
                          valid: !!invoiceFront,
                          helper: !!invoiceFrontBucket,
                        }"
											/>

											<span class="highlight"></span>
											<span class="bar"></span>

											<div
												v-if="errors.has('invoiceFront')"
												class="invalid-feedback"
											>
												Il campo Immagine è richiesto
											</div>

											<div
												v-if="
                          uploadFrontPercentage && uploadFrontPercentage < 100
                        "
												class="progress"
												id="progFront"
											>
												<div
													class="progress-bar"
													:class="{
                            'progress-bar-striped': uploadFrontPercentage < 100,
                            'progress-bar-animated':
                              uploadFrontPercentage < 100,
                          }"
													id="progress-bar-front"
													role="progressbar"
													v-bind:style="{ width: uploadFrontPercentage + '%' }"
													aria-valuenow="50"
													aria-valuemin="0"
													aria-valuemax="100"
												>
													{{ uploadFrontPercentage }} %
												</div>
											</div>
											<!-- <div
												class="white"
												v-if="uploadFrontPercentage == 100"
											>ok</div> -->
										</div>

										<span
											v-if="invoiceFrontBucket"
											class="pl-md-1 pl-lg-3"
										>
											Scontrino caricato correttamente
											<span
												class="underline pointer"
												@click="viewerOn = !viewerOn"
												v-if="
                          !invoiceFrontBucket.toUpperCase().includes('.PDF') &&
                          !viewerOn
                        "
											>vedi qui</span>
											<span
												v-if="viewerOn"
												class="underline pointer"
												@click="viewerOn = !viewerOn"
											>
												chiudi
											</span>
										</span>

										<p
											v-if="viewerOn"
											class="mt-2"
											v-viewer
										>
											<img
												:src="invoiceFrontBucket"
												class="img-fluid"
											/>
										</p>

										<!-- <a
											v-if="invoiceFrontBucket"
											v-bind:href="invoiceFrontBucket"
											target="_blank"
											class="pl-md-1 pl-lg-3"
										>
											Scontrino caricato correttamente <a
												v-if="
                          invoiceFrontBucket.substring(
                            invoiceFrontBucket.lastIndexOf('.') + 1,
                            invoiceFrontBucket.length
                          ) != 'pdf'
                        "
												class="white underline"
											>vedi qui
											</a>

										</a> -->
									</div>
									<div class="col-md-6"></div>
								</div>

								<div class="row justify-content-center">
									<div class="col-12 col-md-8 ml-4 mt-4 mb-3 white">
										<div class="form-check my-3 my-md-0">
											<div class="d-flex align-items-center">
												<div class="pt-3 mr-2">
													Numero di prodotti acquistati
												</div>

												<div>
													<input
														v-model="productNumber"
														class="mt-3 mr-3 styled-radio"
														type="radio"
														name="productNumber"
														id="test1"
														value="1"
														v-validate="'required|included:1,2'"
														:class="{
                              'is-invalid': errors.has('productNumber'),
                            }"
													/>

													<label for="test1">
														<div class="">
															<span class="uppercase">1</span>
														</div>
													</label>
												</div>

												<div class="ml-3">
													<input
														v-model="productNumber"
														class="mt-3 mr-3 styled-radio"
														type="radio"
														name="productNumber"
														id="test2"
														value="2"
														v-validate="'required|included:1,2'"
													/>

													<label for="test2">
														<div class="">
															<span class="uppercase">2</span>
														</div>
													</label>
												</div>
											</div>
											<label
												class="red"
												v-show="errors.has('productNumber')"
											>
												Il numero di prodotti acquistati deve essere indicato
											</label>
										</div>

										*ad esempio per il N. 0018-0006 digitare i numeri dopo il
										trattino senza zeri e precisamente 6. <br />
										Per il messaggio di conferma ordine digitare 0000 se non
										presente il numero <br /><br />

										Per maggiori informazioni sulle modalità di trattamento dei
										tuoi dati, ti invitiamo a prendere visione della nostra
										<a
											:href="privacyPolicy"
											target="_blank"
											class="Bold white underline"
										>Informativa Privacy.</a>
										<br /><br />

										<h5>Resta in contatto con Eau Thermale Avène!</h5>
										Pierre Fabre, in relazione ai prodotti e servizi di Eau
										Thermale Avène, tratterà i tuoi dati per gestire la tua
										richiesta di adesione all’iniziativa, nonché, previo tuo
										esplicito consenso, per inviarti comunicazioni pubblicitarie
										promozionali, ricerche di mercato. <br /><br />

										<!-- <div class="form-check">
											<div class="group">
												<div class=" justify-content-start d-flex ">
													<div class="d-flex">
														<div class="form-check form-check-inline ">
															<label class="form-check-label">
																<input
																	type="radio"
																	class="form-check-input inline"
																	v-model="agreement3_player"
																	v-validate="'required|included:1,0'"
																	value="1"
																	name="agreement3_player"
																	:class="{
                                    'is-invalid': errors.has('agreement3_player'),
                                  }"
																/>Si
															</label>
														</div>
														<div class="form-check form-check-inline">
															<label class="form-check-label">
																<input
																	type="radio"
																	class="form-check-input inline"
																	v-model="agreement3_player"
																	value="0"
																	name="agreement3_player"
																	:class="{
                                    'is-invalid': errors.has('agreement3_player'),
                                  }"
																/>No
															</label>
														</div>
													</div>

													<div>
														<label
															class="form-check-label widthFull glade"
															for="agreement3_player"
														>
															Acconsento di ricevere via e-mail comunicazioni relative a iniziative di marketing dal marchio Eau Thermale Avene
															(quali newletters o altre offerte commerciali).
														</label>
														<label
															class="red"
															v-show="errors.has('agreement3_player')"
														>
															Il consenso deve essere accettato oppure rifiutato
														</label>
													</div>
												</div>
											</div>
										</div> -->

										<div class="form-check ml-1 mt-1">
											<input
												class="form-check-input styled-checkbox"
												type="checkbox"
												v-model="agreement3_player"
												id="agreement3_player"
												name="agreement3_player"
												data-vv-as="Consenso al trattamento"
												:class="{
                          'is-invalid': errors.has('agreement3_player'),
                        }"
											/>

											<label
												class="Medium"
												for="agreement3_player"
											>
												Acconsento di ricevere via e-mail comunicazioni relative
												a iniziative di marketing dal marchio Eau Thermale Avene
												(quali newletters o altre offerte commerciali).
											</label>

											<label
												class="red"
												v-show="errors.has('agreement3_player')"
											>
												Il consenso deve essere accettato oppure rifiutato
											</label>
										</div>

										<div
											class="form-check ml-1 mt-1"
											v-on:click="
                        disabled
                          ? popupErrorText(
                              'info',
                              'Attenzione!',
                              'L`opzione è selezionabile solo se viene fornito anche il consenso marketing.'
                            )
                          : ''
                      "
										>
											<input
												:disabled="disabled"
												class="form-check-input styled-checkbox"
												type="checkbox"
												v-model="agreement2_player"
												id="agreement2_player"
												name="agreement2_player"
												data-vv-as="Consenso al trattamento"
												:class="{
                          'is-invalid': errors.has('agreement2_player'),
                        }"
											/>

											<label
												class="Medium"
												for="agreement2_player"
											>
												Acconsento che i miei dati personali siano trattati per
												personalizzare le comunicazioni di offerte commerciali e
												i servizi ricevuti dal marchio Eau Thermale Avene
											</label>

											<label
												class="red"
												v-show="errors.has('agreement2_player')"
											>
												Il consenso deve essere accettato oppure rifiutato
											</label>
										</div>

										Inviando la tua richiesta di adesione all’iniziativa,
										dichiari di accettare i
										<a
											:href="regolamentoPolicy"
											target="_blank"
											class="white underline"
										>Termini e condizioni</a>
										dell’operazione e di aver letto l’<a
											:href="privacyPolicy"
											target="_blank"
											class="Bold white underline"
										>Informativa Privacy.</a>
									</div>
								</div>
							</form>
						</tab-content>
					</form-wizard>

					<!-- <div class="d-flex align-items-center">

						<div class="pt-3 mr-3">
							Numero di prodotti acquistati
						</div>

						<div>

							<input
								class="mt-3 mr-3 styled-radio"
								type="radio"
								name="test"
								id="test1"
							/>

							<label for="test1">
								<div class="">
									<span class="uppercase">1</span>

								</div>

							</label>

						</div>

						<div class="ml-3">

							<input
								class="mt-3 mr-3 styled-radio"
								type="radio"
								name="test"
								id="test2"
							/>

							<label for="test2">
								<div class="">
									<span class="uppercase">2</span>

								</div>

							</label>

						</div>

					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState, mapGetters } from 'vuex';
	import axios from 'axios';
	import { USER_REQUEST, USER_SET_DEFAULT_SHIP_DATA } from 'actions/user';
	import Datepicker from 'vuejs-datepicker';
	import { it } from 'vuejs-datepicker/dist/locale';
	import VueTimepicker from 'vue2-timepicker';
	// import VideoBackground from 'vue-responsive-video-background-player';

	import { FormWizard, TabContent } from 'vue-form-wizard';
	import 'vue-form-wizard/dist/vue-form-wizard.min.css';

	import { locationMixin } from '../../mixins/locationMixin';
	import { checkUniqueMixin } from '../../mixins/checkUniqueMixin';
	import { sharedEnvMixin } from '../../mixins/sharedEnvMixin';
	import { toDateMixin } from '../../mixins/toDateMixin';
	import { innerWidthMixin } from '../../mixins/innerWidthMixin';
	import { playFunctionMixin } from '../../mixins/playFunctionMixin';
	import { popupConfigurationMixin } from '../../mixins/popupConfigurationMixin';

	const moment = require('moment');

	export default {
		name: 'play',
		mixins: [
			locationMixin,
			checkUniqueMixin,
			sharedEnvMixin,
			toDateMixin,
			innerWidthMixin,
			playFunctionMixin,
			popupConfigurationMixin,
		],
		components: {
			Datepicker,
			VueTimepicker,
			// VideoBackground,
			FormWizard,
			TabContent,
		},
		data() {
			return {
				prod: '',
				videoLoaded: false,
				videoOpacity: 1,
				showDateInfoVar: false,
				it: it,
				timeVal: '',
				prod1: '',
				prod2: '',
				stepPopUp: true,
				priceFocused: false,
				colorType: '',
				product: '',
				advisor: false,
				productIndex: 1,
				showProdError: false,
				quantity: '',
				disabledDates: {
					to: '',
					from: '',
				},

				pdvs: [],
				pdvsState: false,
				pdvState: false,
				pdvsError: false,
				pdv_prov: '',
				prodSel1: false,
				prodSel2: false,
				pdv: '',
				pdv_city: '',
				pdv_cities: [],
				items: {},
				provincesState: true,
				cities: [],
				citiesState: false,
				zips: [],
				zipsState: false,
				allInvoiceInfo: false,
				question: '',
				canScroll: true,
				scrollFinished: false,
				stepSize: 'md',
				rebate: '',
				totPrice: '',
				priceError: false,
				timeC: false,
				countdown: 0,
				datenow: '',
				showInfo: false,
				token: '',
				id_image: '',
				zip: '',
				city: '',
				province: '',
				endedVideo: true,
				taglia: '',
				taglie: [],
				disabled: true,
				viewerOn: false,
			};
		},
		methods: {
			showSuccessUpdateSHIP() {
				// Use sweetalret2
				this.$swal({
					type: 'success',
					title:
						'<h3>I dati per la spedizione sono stati correttamente aggiornati</h3>',
					timer: 4500,
				});
			},
			ended: function () {
				this.endedVideo = true;
			},
			videoReady: function () {
				this.videoLoaded = true;
			},
			async recaptcha() {
				var _vue = this;
				const recaptcha = _vue.$recaptchaInstance;

				// (optional) Wait until recaptcha has been loaded.
				await _vue.$recaptchaLoaded();

				// Execute reCAPTCHA with action "login".
				this.token = await _vue.$recaptcha('login');

				// Do stuff with the received token.
			},
			stepIndex(from, to) {
				if (this.scrActiv) {
					//this.$scrollTo('.main', 700, { offset: -170 });
					if (to == 0) {
						this.$route.hash != '#step1'
							? this.$router.push({ hash: '#step1' })
							: '';
					}
					if (to == 1) {
						this.$route.hash != '#step2'
							? this.$router.push({ hash: '#step2' })
							: '';
					}
					if (to == 2) {
						this.$route.hash != '#step3'
							? this.$router.push({ hash: '#step3' })
							: '';
					}
				}

				if (to == 1) {
					this.showInfo = true;
				} else {
					this.showInfo = false;
				}
				this.$track('STEP', from + ' => ' + to);
			},
			checkProducts: function (param) {
				var products = this.products;
				var count = 0;
				if (param) {
					this.showProdError = false;
				} else {
					for (var i in products) {
						var prod = products[i].product;
						if (products[i].quantity) {
							if (prod.includes('30')) {
								count = count + 1 * parseInt(products[i].quantity);
							} else {
								count = count + 3 * parseInt(products[i].quantity);
							}
						}
					}
					if (count >= 3) {
						this.showProdError = false;
					} else {
						this.showProdError = true;
					}
				}
			},
			showViever() {
				var el = this.$refs.inv;
				const viewer = el.$viewer;
				viewer.show();
			},
			timeReactive() {
				if (this.timeStore > moment(process.env.VUE_APP_FINISH)) {
					window.location.replace('/');
				}
			},
			calcTotPrice: function () {
				var prod = this.products;
				var count = 0;
				for (var x in prod) {
					if (prod[x].price && prod[x].quantity) {
						count =
							count +
							parseFloat(prod[x].price).toFixed(2) * parseInt(prod[x].quantity);
					}
				}
				if (isNaN(count)) {
					count = 0.0;
				}

				if (count > 0) {
					this.priceError = false;
				} else {
					this.priceError = true;
				}

				this.totPrice = parseFloat(count).toFixed(2);
			},

			sessionFail: function () {
				this.$swal({
					type: 'error',
					title: '',
					html: '<h4 class="smallText">La tua sessione è scaduta. Effettua nuovamente l`operazione.</h4>',
					confirmButtonText: 'CHIUDI',
					onClose: () => {
						this.$router.push('/');
						setTimeout(function () {
							window.location.reload();
						}, 500);
					},
				});
			},
			reload: function () {
				window.location.reload();
			},

			showErrorOCR(text) {
				var html =
					'<p class="smallText text-left">Nell`immagine eventualmente caricata:</p><ul>';

				var x = 0;
				var z = 0;
				for (var i in text) {
					if (text[i].status == 'KO') {
						if (text[i].description) {
							html +=
								'<li class="smallText text-left">' +
								text[i].description +
								'</li>';
						}

						x++;
					}
					//if ( text[i].error == 'price_less' ){
					//  z = 1
					//}
				}
				html += '</ul>';

				// TO MANY ERROR = IS NOT INVOICE
				if (x >= 4) {
					html =
						'<p class="smallText text-left">Purtroppo l`immagine caricata non sembra essere quella di un scontrino.</p>';
					html +=
						'<ul><li class="smallText text-left">I dati sullo scontrino devono essere ben visibili.</li>';
					html +=
						'<li class=" smallText text-left mt-2">Se lo scontrino è troppo lungo, piegalo a fisarmonica per mostrare solo i dati necessari</li> ';
					html +=
						'<li class=" smallText text-left mt-2">La foto caricata deve avere un peso compreso fra 12KB e 10MB</li></ul> ';
					html +=
						'<p class="smallText text-left mt-3">Prova a rifare la foto e ricarica lo scontrino</p> ';
					z = 4;
				} else if (x <= 3) {
					html += '<p class="smallText text-left">Attenzione!</p>';
					if (x == 1 && text.date.error == 'not_play_period') {
						// IF ONLY DATE OUT OF PERIOD
						// html += '<ul><li class="smallText text-left">Sono passati piú di 5 giorni compreso oggi dalla data di acquisto riportata sul documento di vendita.</li></ul>'
					} else {
						// IF MANY ERRORS
						html +=
							'<ul><li class="smallText text-left">I dati sullo scontrino devono essere ben visibili.</li>';
						html +=
							'<li class=" smallText text-left mt-2">Se lo scontrino è troppo lungo piegalo a fisarmonica per mostrare solo i dati necessari</li>';
						html +=
							'<li class=" smallText text-left mt-2">La foto caricata deve avere un peso compreso fra 12KB e 10MB</li></ul> ';
						html +=
							'<p class="smallText text-left mt-3">Prova a rifare la foto e ricarica lo scontrino</p> ';
					}
				}
				this.$swal({
					type: 'error',
					title: '<strong class="">Ops!</strong>',
					html: html,
					//confirmButtonText: "Esempio scontrino",
					confirmButtonColor: '#c95c1c',
					//showCancelButton: true,
					cancelButtonText: 'Chiudi',
					cancelButtonColor: 'black',
				}).then((okay) => {});
			},
			validateFirstTab: function () {
				return this.$validator.validateAll('form-1');
			},
			validateSecondTab: function () {
				return this.$validator.validateAll('form-3');
			},
			validateThirdTab: function () {
				if (this.invoiceFront) {
					return this.$validator.validateAll('form-4');
				} else {
					return false;
				}
			},
			validate4Tab: function () {
				//if ( this.totPrice > 0 ){
				return this.$validator.validateAll('form-2');
				//  }else{
				//    this.priceError = true
				//    return false
				//  }
			},
			onComplete: function () {
				this.$validator.validate().then((valid) => {
					if (valid) {
						this.getIbanCount();
					}
				});
			},

			lockPremi() {
				// chiamata api per lock premio
				let data = this.$encryptByAES({
					tipo_premio: this.taglia.id,
					user_token: this.profile.userToken,
					HBGRF: process.env.VUE_APP_HBGRF,
					vector_HBGRF: process.env.VUE_APP_ICODE,
				});

				this.$callLog('lockPremi', data);

				if (taglia) {
					axios({
						data: { data },
						url: process.env.VUE_APP_ROOT_API + 'api/lockPremi',
						method: 'POST',
						headers: {
							Authorization: 'Bearer ' + process.env.VUE_APP_KITTY,
							'Content-type': 'application/json',
						},
					})
						.then((resp) => {
							resp.data = this.$decryptWORMY(resp.data);
							this.$respLog('lockPremi', resp.data);
						})
						.catch((resp) => {
							resp.response.data = this.$decryptWORMY(resp.response.data);
							this.$respLog('lockPremi', resp.response);
						});
				}
			},

			showDateInfo() {
				if (this.showDateInfoVar) {
					this.showDateInfoVar = false;
				} else {
					this.showDateInfoVar = true;
				}
			},
			showError(text) {
				this.$swal({
					type: 'error',
					title: '<strong class="modalText danger">' + text + '</strong>',
					html: '',
					confirmButtonText: 'CHIUDI',
				});
			},
			showDublicateImage(img) {
				this.$swal({
					type: 'error',
					title: 'Ops!',
					html:
						'<p class="center smallText">Lo scontrino:</p> <p class="center smallText">' +
						img +
						'</p><p class="center smallText"> risulta già caricata</p>',
					confirmButtonText: 'CHIUDI',
					onClose: () => {
						window.location.reload();
					},
				});
			},

			addProduct() {
				this.productIndex = this.productIndex + 1;
				this.products.push({
					index: 'product' + this.productIndex,
					indexCount: 'quantity' + this.productIndex,
					product: '',
					quantity: '',
					price: '',
				});
			},
			removeProduct(elem) {
				var _vue = this;
				var index = this.products.indexOf(elem);
				this.products.splice(elem, 1);
				_vue.calcTotPrice();
			},

			infoPopUp() {
				this.$swal({
					//input: 'text',
					confirmButtonText: 'Carica FRONTE &rarr;',
					imageUrl: require('../../assets/images/invoice-example.png'),
					imageClass: 'minImg',
					focusCancel: false,
					focusConfirm: true,
					cancelButtonText: 'Annulla',
					showCancelButton: true,
					title: '',
					html:
						'<p class="smallText text-left"><ul  class="smallText text-left"><li class="smallText">I dati sullo scontrino devono essere ben visibili. </li>' +
						'<li class="smallText">Se lo scontrino è troppo lungo piegalo a fisarmonica per mostrare solo i dati necessari</li>' +
						'<li class=" smallText text-left mt-2">La foto caricata deve avere un peso compreso fra 12KB e 10MB</li></ul></p>',
				}).then((result) => {
					if (result.value) {
						this.$refs.fileInput1.click();
					}
				});
			},

			changeSwalImage: function () {
				alert('sdfgasd');
			},
			UploadError() {
				this.$swal({
					type: 'error',
					title:
						'<strong class="modalText danger">Errore caricamento immagine</strong>',
					html: '',
					confirmButtonText: 'CHIUDI',
					confirmButtonColor: '#15267',
				});
			},

			check_pdv_prov: function () {
				const { pdv_prov } = this;
				if (pdv_prov) {
					this.pdv_cityError = false;
				} else {
					this.pdv_cityError = true;
				}
			},

			checkpdv: function () {
				const { pdv } = this;
				if (pdv) {
					this.pdvError = false;
				} else {
					this.pdvError = true;
				}
			},
			openListProducts() {
				// Use sweetalret2
				this.$swal({
					type: '',
					title: '<strong class="modalText h2">prodotti in promozione</strong>',
					html:
						'<br/><br/><h5 class="modalText ">- Birra Messina 33cl</h5>' +
						'<h5 class="modalText even ">- Birra Messina 3x33cl</h5>' +
						'<h5 class="modalText ">- Birra Messina Cristalli di Sale 50cl</h5><br/><br/>',
					width: 600,
					confirmButtonText: 'chiudi',
				});
			},

			checkDefaultShipData() {
				if (this.shipData.name) {
					this.name = this.shipData.name;
					this.lastname = this.shipData.lastname;
					this.address = this.shipData.address;
					this.civic = this.shipData.civic;
					this.near = this.shipData.near;
					this.phone = this.shipData.phone;
					this.province = this.shipData.province;
					this.city = {};
					this.city.city = this.shipData.city;
					this.city.istat = 'toset';

					this.zip = this.shipData.zip;
					//this.zips = [
					//	cap => this.shipData.zip
					//]
				}
			},
			scrollToTop: function () {
				var _vue = this;
				setTimeout(function () {
					//_vue.$scrollTo('.main', 700, { offset: -120 });
				}, 100);
			},
			visibilityHow(isVisible, entry) {
				if (isVisible && this.scrollFinished) {
					this.$route.hash != '#come_partecipare'
						? this.$router.push({ hash: '#come_partecipare' })
						: '';
				}
			},
			visibilityPlay(isVisible, entry) {
				if (isVisible && this.scrollFinished) {
					this.$route.hash != '#partecipa'
						? this.$router.push({ hash: '#partecipa' })
						: '';
				}
			},
			visibilityLogin(isVisible, entry) {
				if (isVisible && this.scrollFinished) {
					this.$route.hash != '#login'
						? this.$router.push({ hash: '#login' })
						: '';
				}
			},

			// setProfileData: function () {
			// 	if (process.env.VUE_APP_HAS_LOGIN == 'ON') {
			// 		this.email = this.profile.email;
			// 		this.userToken = this.profile.id;
			// 	} else {
			// 		this.email = this.email;
			// 		this.userToken = makeid(10);
			// 	}
			// },
		},

		watch: {
			productNumber(val) {},
			agreement3_player: function (val) {
				if (val) {
					this.disabled = false;
				} else {
					this.disabled = true;
					// this.agreement2_player = '';
				}
			},

			prod: {
				handler(val) {
					if (val) {
						this.prodSel1 = true;
						this.advisor = false;
					} else {
						this.prodSel1 = false;
						this.advisor = false;
					}
				},
			},
			prod2: {
				handler(val) {
					if (val) {
						this.prodSel2 = true;
						this.advisor = false;
					} else {
						this.prodSel2 = false;
						this.advisor = false;
					}
				},
			},
			date: function (val) {},
			time: function (val) {},

			xuserx: function (val) {
				if (val) {
					this.xuserx = val.replace(/\d+/g, '');
					this.xuserx = this.xuserx.replace(
						/[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi,
						''
					);
					this.xuserx =
						this.xuserx.charAt(0).toUpperCase() + this.xuserx.slice(1);
				}
			},
			lastnamex: function (val) {
				if (val) {
					this.lastnamex = val.replace(/\d+/g, '');
					this.lastnamex = this.lastnamex.replace(
						/[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi,
						''
					);
					this.lastnamex =
						this.lastnamex.charAt(0).toUpperCase() + this.lastnamex.slice(1);
				}
			},
			pdv_city: function (val) {
				if (val) {
					this.pdv_city = val.replace(/\d+/g, '');
					this.pdv_city = this.pdv_city.replace(
						/[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi,
						''
					);
					this.pdv_city =
						this.pdv_city.charAt(0).toUpperCase() + this.pdv_city.slice(1);
				}
			},
			number: function (val) {
				if (val) {
					this.number = val.replace(/\D+/g, '');
				}
				if (val < 0) {
					this.number = val.replace(val, 0);
				}
			},
			phone: function (val) {
				if (val) {
					this.phone = val.replace(/\D+/g, '');
				}
			},

			// getProfile: function () {
			// 	this.setProfileData();
			// },
		},
		updated: function () {},
		created() {
			this.$validator.extend('notZeros', {
				getMessage: (field) =>
					' Il campo Numero di telefono non ha un formato valido.',
				validate: (value) => {
					// value must be > zero
					if (
						value > 1000000 &&
						value.length > 1 &&
						value != 30000000 &&
						value != 300000000 &&
						value != 3000000000 &&
						value != 30000000000
					)
						return true;
					return false;
				},
			});

			var now = this.timeStore;
			var then = moment(process.env.VUE_APP_OCR_END);
			var dif = then.diff(now, 'days', true);
			// this.setProfileData();

			this.countdown = parseInt(dif * 14.28);
		},
		async mounted() {
			this.populateProvinces();

			if (process.env.VUE_APP_HAS_LOGIN == 'ON') {
				await this.retrivePlays();
			} else {
				this.progress = 'START';
			}
			// this.setProfileData();

			var now = this.timeStore;
			var then = moment(process.env.VUE_APP_OCR_END);
			var dif = then.diff(now, 'hours', true);

			this.countdown = parseInt(dif);

			if (this.canScroll) {
				this.canScroll = false;
			}
			if (process.env.VUE_APP_HAS_LOGIN == 'ON') {
				this.$track('Getprofile', this.getProfile);
				this.lastnamex = this.getProfile.cognome;
				this.xuserx = this.getProfile.nome;

				if (this.isAuthenticated) {
					this.$watch(
						() => {},
						(val) => {}
					);
				}
			}

			var _vue = this;

			setInterval(_vue.timeReactive, 5000);

			setTimeout(function () {
				_vue.populateProvinces(),
					(_vue.disabledDates = {
						to: _vue.calcToDate(),
						from: _vue.calcFromDate(),
					});

				_vue.$track('disabledDates', _vue.disabledDates);

				_vue.scrollFinished = true;
			}, 1500);
		},
		computed: {
			...mapGetters([
				'getProfile',
				'xkey',
				'isAuthenticated',
				'isProfileLoaded',
				'acceptCookies',
				'checkAge',
				'getPopup',
			]),
			...mapState({
				profile: (state) => state.user.profile,
				shipData: (state) => state.user.defaultShipData,
			}),
			timeStore() {
				return this.$timestore.now;
			},
		},
	};
</script>


<style lang="scss" scoped>
	.pseudoEuro,
	.pseudoCent {
		top: 3rem !important;
	}
</style>
