

	<template>
  <div class="widthFull" id="headerSection">
    <div class="headBack" 	v-scroll-to="{ el: '#section01', offset: -15 }" :class="{ 'h-100': vw > 1000, 'h-75': vw < 1001 }">
      <!--<div class="col-12 pt-5 center pb-5 ">
				<h3 class="mt-5 back-color pb-lg-5 pt-lg-5 pt-md-2 pb-md-2 pb-3 pt-3 Medium uppercase">Prenota la tua esclusiva degustazione di Kozel in streaming, in compagnia del nostro Beer Ambassador<br /><a class="black underline" target="_blank" href=" https://www.concorsilab.it/kozeltastingday/">Clicca qui</a></h3>
			</div>-->

      <img
        v-if="vw > 1000"
        src="../../assets/images/home-wide.png"
        class="img-fluid mt-4 prodotti_home"
        style="
          position: absolute;
          bottom: -50;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        "
        alt=""
      />
      <img
        v-if="vw > 600 && vw < 1001"
        src="../../assets/images/home-medium.png"
        class="img-fluid mt-4 prodotti_home"
        style="position: absolute; bottom: 0"
        alt=""
      />
      <img
        v-if="vw < 601"
        src="../../assets/images/home-small.png"
        class="img-fluid mt-4 prodotti_home"
        style="position: absolute; bottom: -30"
        alt=""
      />

      <!-- <h1 class="mt-3 white-title">
							dal 1 Marzo al 31 Marzo 2021
						</h1>
						<h4 class="white-title mb-1 pb-2 mx-auto p-0  Medium">Acquistando due confezioni a scelta tra <strong> INTEGRATORI</strong> o<strong> FIALE ANTICADUTA </strong>riceverai <br />un rimborso direttamente sul tuo Iban </h4>

						<div class="pb-sm-0 pb-5 mb-md-4 mb-lg-5 mt-5 mt-lg-0">
							<a
								v-if="$route.path == '/'"
								href="#award"
								v-scroll-to="{ el: '#award', offset: -85 }"
								class="btn btn-primary-inv btn-lg Bold Bold uppercase"
							>Scopri di più</a>
						</div> -->
    </div>
  </div>
</template>


	<script>
import { mapGetters } from "vuex";

import { innerWidthMixin } from "../../mixins/innerWidthMixin";
import { sharedEnvMixin } from "../../mixins/sharedEnvMixin";

const moment = require("moment");

export default {
  name: "header_",
  mixins: [innerWidthMixin, sharedEnvMixin],
  data() {
    return {
      moment: moment,
    };
  },

  methods: {
    playNow: function (val) {
      var _vue = this;
      if (this.vw > 767) {
        /**Pc */
        if (val == 2) {
          setTimeout(function () {
            _vue.$scrollTo("#how-partecipate", 700, { offset: 30 });
          }, 100);
        }
      } else if (this.vw < 330) {
        /*Iphone 5*/
        if (val == 2) {
          setTimeout(function () {
            _vue.$scrollTo("#how-partecipate", 700, { offset: -50 });
          }, 100);
        }
      } else {
        /**Mobile */
        if (val == 2) {
          setTimeout(function () {
            _vue.$scrollTo("#how-partecipate", 700, { offset: -50 });
          }, 100);
        }
      }
    },

    scrollToTop: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo(".main", 700, { offset: -125 });
      }, 100);
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(["isAuthenticated", "authStatus", "getPromotions"]),
    timeStore() {
      return this.$timestore.now;
    },
  },
};
</script>

<style lang="css" scoped>
.widthFull {
  position: relative;
}

.vinci-btn {
  position: absolute !important;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 3px 3px 8px #2a2313;
}

.white-title {
  color: white;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.8rem 1.4rem;
  line-height: 1;
  border-radius: 0.3rem;
}

.padding-special {
  padding-top: 180px !important;
}

@media screen and (max-width: 1024px) {
  .vinci-btn {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 768px) {
  .vinci-btn {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 450px) {
  .vinci-btn {
    max-width: 220px;
    margin-bottom: 50px;
  }

  h1.white-title {
    font-size: 24px;
  }

  h4.white-title {
    font-size: 20px;
  }
}
</style>
