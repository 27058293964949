<template>
	<div class="widthFull">

		<nav class="navbar navbar-light navbar-expand-lg custom-toggler  fixed-top align-items-center  mt-0 mb-0 ">

			<div class="container customContainer back">

				<a class="navbar-brand navmobile  ">
					<button
						class="ml-3 navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarTogglerDemo01"
						aria-controls="navbarTogglerDemo01"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span class=" navbar-toggler-icon "></span>
					</button>

				</a>

			<router-link
					v-if="vw <= 992 "
					to="/"
					v-scroll-to="{ el: '.main', offset: -35 }"
				>
					<img
						src="../../assets/images/logo.png"
						class="img-fluid mt-3 ml-2 logo_left"
						ref="mainNavLogo"
					>
				</router-link>
	<!--
				 <router-link
					v-if="$route.name != 'pass'"
					to="/"
					:class="{'hidden':scroll < ( vh * 0.2 ) && vw > 992 }"
					v-scroll-to="{ el: '.main', offset: -35 }"
				>
					<img
						src="../../assets/images/logoBioscaline.png"
						class="img-fluid mtFix ml-5 mt-1 mb-4 pt-4 logo "
						ref="mainNavLogo"
					>
				</router-link> -->

				<div
					class="navbar-collapse collapse  "
					id="navbarTogglerDemo01"
				>

					<ul class="navbar-nav nav-fill w-100 lastNavLink">

						<scrollactive
							active-class="activeMenuItem"
							:offset="231"
							:scrollOffset="91"
							class="navbar-nav nav-fill w-100 lastNavLink relative"
						>

							<div class="row justify-content-center widthFull m-auto">
								<div
									v-if="$route.name != 'pass'"
									class="col-sm-12 col-lg-10 navbar-nav nav-fill w-100 lastNavLink mb-0"
								>

									<li
										class="nav-item Regular uppercase"
										data-toggle="collapse"
										data-target=".navbar-collapse.show"
									>
										<a
											v-if="$route.path == '/'"
											href="#"
											v-scroll-to="{ el: '#headerSection', offset: -35 }"
											class="nav-link "
										>Home</a>
										<router-link
											v-else
											to="/#headerSection"
											class="nav-link "
										>Home</router-link>
									</li>

									<li
										class="nav-item Regular"
										data-toggle="collapse"
										data-target=".navbar-collapse.show"
									>
										<a
											v-if="$route.path == '/'"
											href="#section01"
											v-scroll-to="{ el: '#section01', offset: -15 }"
											class="nav-link "
										>Come Partecipare</a>
										<router-link
											v-else
											to="/#section01"
											class="nav-link "
										>Come Partecipare</router-link>
									</li>

									<li
										class="nav-item Regular"
										data-toggle="collapse"
										data-target=".navbar-collapse.show"
									>
										<a
											v-if="$route.path == '/'"
											href="#section02"
											v-scroll-to="{ el: '#section02', offset: -15 }"
											class="nav-link "
										>Premio</a>
										<router-link
											v-else
											to="/#section02"
											class="nav-link "
										>Premio </router-link>
									</li>



							<!--	<div
									class="mt-3  "
									v-if="vw >= 992"
									:class="{'hidden': vw <= 992 }"
								>

									<a
										href="https://www.velkopopovickykozel.com/"
										target="blank"
									>
										<img
											src="../../assets/images/logo.png"
											class="logo"
											ref="mainNavLogo "
										>
									</a>

								</div>

			<div
									class="mt-3 logoFullW hidden"
									v-if="vw >= 992"
									:class="{'hidden':scroll > ( vh * .1 ) && vw > 992 && $route.path == '/' , 'show':scroll < ( vh * .1 ) && $route.path == '/' }"
								>

									<a
										href="https://www.velkopopovickykozel.com/"
										target="blank"
									>
										<img
											src="../../assets/images/logo.png"
											class="logo"
											ref="mainNavLogo "
										>
									</a>

								</div> -->

								<!-- <div
									class="mt-3 logoFullWDown "
									v-if="vw >= 992 "
									:class="{'hidden':scroll <= ( vh * .1 ) && vw > 992 && $route.path == '/',  'show':scroll >= ( vh * .1 ),'show': $route.path != '/'}"
								>

									<a
										href="https://www.velkopopovickykozel.com/"
										target="blank"
									>
										<img
											src="../../assets/images/logo_text.png"
											class="logo_text"
											ref="mainNavLogo "
										>
									</a>

								</div> -->

								<!-- <div
									class="mt-3 logoFullW "
									v-if="vw >= 992 "
								>

									<a
										href="https://www.velkopopovickykozel.com/"
										target="blank"
									>
										<img
											src="../../assets/images/logo_text.png"
											:class="{'hidden':scroll < ( vh * 1 ) && vw > 992 }"
											class="logo_text"
											ref="mainNavLogo "
										>
									</a>

								</div> -->

								<!-- <transition name="fade">
									<h2
										v-show="mostraLogo && $route.name == 'Home'"
										class="sub-title center Regular theme-1 pt-2"
									>
										C'è un villaggio <br> dentro.
									</h2>
								</transition> -->


									<li
										v-if="moment() > moment(start)"
										class="nav-item Regular"
										data-toggle="collapse"
										data-target=".navbar-collapse.show"
									>
										<a
											:href="regolamentoPolicy"
											target="_blank"
											class="nav-link customLink "
										>regolamento</a>
									</li>
									<li
										v-else
										class="nav-item Regular"
										data-toggle="collapse"
										data-target=".navbar-collapse.show"
									>
										<a
											target="_blank"
											class="nav-link customLink "
										>regolamento</a>
									</li>
									<li
										v-if="!isAuthenticated"
										class="nav-item Regular"
									>
										<router-link
											class=" text-right"
											to="/login"
											v-scroll-to="'.main'"
											data-toggle="collapse"
											data-target=".navbar-collapse.show"
										>
											<a
												class="nav-link "
												href="#"
											>Accedi</a>
										</router-link>
									</li>

									<li
										v-if="moment(finish) > moment() && !isAuthenticated"
										class="nav-item Regular "
									>
										<!--Se non sei registrato-->
										<router-link
											class=" text-right"
											to="/registration"
										>
											<a
												class="nav-link "
												href="#"
											>Registrati</a>
										</router-link>

									</li>

									<li
										v-if="isProfileLoaded"
										class="nav-item dropdown mr-0 pd-0 "
									>
										<a
											class="nav-link dropdown-toggle nav-link mr-0 pd-0 Regular "
											href="#"
											id="navbardrop"
											data-toggle="dropdown"
										>
											<font-awesome-icon
												class="pointer mr-0 pd-0"
												icon="user"
												size="1x"
												:style="{ color: '#282018!important' }"
											/>
											{{first_name_player}}
										</a>
										<div
											class="dropdown-menu text-right animate slideIn"
											data-toggle="collapse"
											data-target=".navbar-collapse.show"
										>
											<router-link
												class="dropdown-item menuItem Regular"
												to="/account"
												v-scroll-to="'.main'"
												data-toggle="collapse"
												data-target=".navbar-collapse.show"
											><span class="menuItem ">Profilo</span></router-link>
											<router-link
												class="dropdown-item menuItem Regular"
												to="/receipts"
												v-scroll-to="'.main'"
												data-toggle="collapse"
												data-target=".navbar-collapse.show"
											><span class="menuItem ">La Mia Area</span></router-link>
											<div class="dropdown-divider"></div>
											<a
												class="dropdown-item pointer menuItem black"
												@click="logout"
											><span class="menuItem  ">Esci</span></a>
										</div>
									</li>

								</div>

							</div>

						</scrollactive>

					</ul>
				</div>
			</div>

		</nav>

		<div class="container-fluid ">
			<div
				class="col-12 center fixed cookieBaner Regular"
				v-if="!acceptCookies"
			>
				<span>
					Questo sito utilizza i cookie tecnici e di terze parti per consentire una migliore navigazione.
					Se si continua a navigare sul presente sito, si accettano i cookie. <a
						class="pointer theme underline"
						v-on:click="doAcceptCookies"
						href="#"
					> Continua</a>.
					( Per visualizzare l'informativa completa <a
						:href="cookiePolicy"
						target="_blank"
						class="pointer theme underline"
					>Clicca qui </a> )
				</span>
			</div>
		</div>

	</div>
</template>
 
<script>
import { mapGetters, mapState } from "vuex";
import { AUTH_LOGOUT, SET_PROMOTION, GET_PROMOTIONS } from "actions/auth";
import { ACCEPT_COOKIES } from "actions/user";

import { innerWidthMixin } from "../../mixins/innerWidthMixin";
import { sharedEnvMixin } from "../../mixins/sharedEnvMixin";
import { checkSoftwareVersion } from "../../mixins/checkSoftwareVersion";
import { popupConfigurationMixin } from "../../mixins/popupConfigurationMixin";

const moment = require("moment");
export default {
  name: "navigation",
  mixins: [
    innerWidthMixin,
    sharedEnvMixin,
    checkSoftwareVersion,
    popupConfigurationMixin,
  ],
  data() {
    return {
      currentPage: "",
      visible: true,
      opacity: { opacity: 1 },

      moment: moment,
    };
  },
  methods: {
    visibilityChanged(isVisible, entry) {},
    logout: function () {
      this.$store
        .dispatch(AUTH_LOGOUT)
        .then(() => (this.$route.path != "/" ? this.$router.push("/") : ""));
    },
    scrollToHowParticipate: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo("#how-participate", 700, { offset: -75 });
      }, 100);
    },
    scrollToAward: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo("#award", 700, { offset: -75 });
      }, 100);
    },
    scrollToTop: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo(".main", 700, { offset: -125 });
      }, 100);
    },
    doAcceptCookies: function () {
      this.$store.dispatch(ACCEPT_COOKIES).then((resp) => {
        //this.$router.push('/')
      });
    },
  },
  watch: {
    $route(to, from) {
      this.checkSoftwareVersion();
    },

    "$route.path": function (route) {
      if (route != "/") {
        this.visible = false;
        this.opacity = {
          opacity: 0,
          "-ms-filter": "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)",
          filter: "alpha(opacity=0)",
        };
      } else {
        this.opacity = {
          opacity: 1,
          "-ms-filter": "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)",
          filter: "alpha(opacity=100)",
        };
        this.visible = true;
      }
    },
    acceptCookies: function () {},
  },

  computed: {
    timeStore() {
      return this.$timestore.now;
    },
    ...mapGetters([
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "acceptCookies",
      "promoOpen",
      "getPromotions",
    ]),
    ...mapState({
      authLoading: (state) => state.auth.status === "loading",
      first_name_player: (state) => `${state.user.profile.first_name_player}`,
    }),
  },

  updated() {
    this.currentPage = this.$route.name;
  },
  mounted: function () {
    this.currentPage = this.$route.name;
    this.$track("isAuthenticated", this.isAuthenticated);
    var _vue = this;
    if (this.cookieBot) {
      let cookieBotScript = document.createElement("script");
      cookieBotScript.setAttribute(
        "src",
        "https://consent.cookiebot.com/uc.js"
      );
      cookieBotScript.setAttribute("data-cbid", process.env.SERVICE);
      cookieBotScript.setAttribute("data-blockingmode", "auto");
      cookieBotScript.async = true;
      cookieBotScript.setAttribute("type", "text/javascript");
      document.head.appendChild(cookieBotScript);
    }
    setTimeout(function () {
      if (_vue.$route.path != "/") {
        _vue.visible = false;
        _vue.opacity = {
          opacity: 0,
          "-ms-filter": "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)",
          filter: "alpha(opacity=0)",
        };
      }
    }, 300);
  },
};
</script>
<style lang="css" scoped>
	span a:hover {
		color: #d4a557 !important;
	}

	.logo {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: -10px;
		height: 70px;
	}

	.logo_text {
		height: 80px !important;
		position: absolute;
		left: 40%;
		transform: translateX(-50%);
	}

	.widthFull {
		justify-content: space-around !important;
	}

	.logoFullW {
		position: relative;
		top: -10px;
		left: 4%;
	}

	.logoFullWDown {
		position: relative;
		top: -34px;
		left: -4.5%;
	}

	.navbar > div {
		box-shadow: 3px 3px 8px #2a2313;
	}

	.nav-link {
		font-size: 1.8rem !important;
		padding: 0 !important;
		text-transform: uppercase;
	}

	.sub-title {
		position: absolute;
		top: 50px;
		font-size: 3rem;
		letter-spacing: 0.2px;
		text-shadow: 2px 2px 3px #d4a557;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.2s ease-out;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}

	.hidden {
		opacity: 0;
		transition: all 0.5s ease;
	}

	.show {
		opacity: 1;
		transition: all 0.5s ease;
	}

	.navbar-toggler {
		background: #c5c9cc !important;
		z-index: 30000;
		margin-top: 26px !important;
		margin-right: 34px;
	}

	.dropdown-menu {
		width: 220px;
		background-color: #c5c9cc;
		font-size: 1.2rem;
	}

	.dropdown-item:focus,
	.dropdown-item:hover {
		color: #282018;
		text-decoration: none;
		background-color: #d4a557;
	}

	.dropdown-divider {
		border-top: 1px solid #d4a557;
	}

	/*  */

	@media screen and (max-width: 1325px) {
		.nav-link {
			font-size: 1.3rem !important;
		}

		.sub-title {
			font-size: 1.8rem;
			letter-spacing: 0.2px;
		}
	}

	@media screen and (max-width: 1200px) {
		.logo {
			height: 50px !important;
		}

		.nav-link {
			font-size: 1.2rem !important;
		}
	}

	@media screen and (max-width: 992px) {
		.logo {
			width: 120px;
		}
		.nav-link {
			font-size: 1rem !important;
		}
	}

	@media screen and (max-width: 768px) {
		.navbar-toggler {
			margin-top: 20px !important;
			margin-right: 34px;
		}
	}

	@media screen and (max-width: 767px) {
		.logo {
			left: -15px;
		}

		.nav-link {
			font-size: 1.8rem !important;
		}

		.navbar-toggler {
			background: #edc044 !important;
			z-index: 30000;
			margin-top: 10px !important;
			margin-right: 34px;
		}
	}

	@media screen and (max-width: 375px) {
		.navbar-toggler {
			margin-top: 12px !important;
		}

		.logo_left {
			width: 80px;
			padding-bottom: 20px;
			padding-top: 10px;
		}
	}

	/* animation drop */
	@media (min-width: 993px) {
		.animate {
			animation-duration: 0.3s;
			-webkit-animation-duration: 0.3s;
			animation-fill-mode: both;
			-webkit-animation-fill-mode: both;
		}
		.slideIn {
			-webkit-animation-name: slideIn;
			animation-name: slideIn;
		}
	}
</style>

