<template>
	<footer class="footerBack ">
		<div class="line back"></div>

		<div class="p-5">

			<div class="">
				<h3 class="Black title uppercase col-12 text-center" style="color:white!important">
					O bevi o guidi
				</h3>

			</div>

			<div class="icon text-center">

				<a
					href="https://www.facebook.com/VelkopopovickyKozel/"
					target="blank"
				>
					<font-awesome-icon
						:icon="['fab', 'facebook-f']"
						class="ml-2 icon"
					/>
				</a>

				<a
					href="https://www.instagram.com/kozelbeer/"
					target="blank"
				>
					<font-awesome-icon
						:icon="['fab', 'instagram']"
						class="ml-2 icon "
						style=""
					/>
				</a>

			</div>

			<div class="url Italic center">
				<a
					href="https://www.alcolparliamone.it/"
					class="theme-3"
					target="blank"
				>www.alcolparliamone.it</a>

			</div>

		</div>

		<div class="back py-4 footer-bottom">
			<div class="center Regular operazioni_premi uppercase theme-2">
				Operazione a premi valida dal 1 aprile al 31 maggio 2022.
			</div>

			<div class="d-flex justify-content-center privacy">

				<a
					:href="regolamentoPolicy"
					target="_blank"
					class="Regular  black underline  uppercase text-center"
				><b>Regolamento</b></a>

				<span class="px-2 theme-1">&#9679;</span>

				<a
					:href="privacyPolicy"
					target="_blank"
					class="Regular  black  underline  uppercase text-center"
				><b>Privacy Policy</b></a>

				<span class="px-2 theme-1">&#9679;</span>

				<a
					:href="cookiePolicy"
					target="_blank"
					class="Regular  black underline  uppercase text-center"
				><b>Cookies Policy</b></a>

			</div>
		</div>

		<!-- <p class="pt-3">
						Iniziativa valida dal 1 Marzo 2021 al 31 marzo 2021 esclusivamente su acquisto di due confezioni Bioscalin a scelta tra <br>
						INTEGRATORI o fiale ANTICADUTA (esclusi acquisti online)
					</p> -->

		<!--
				<a class="underline grey h5 Bold" href="regolamento.pdf" target="_blank"> Leggi il regolamento completo </a>
				-->

		<!-- <div class="row back">

			<div class="col-4"><a
					href="regolamento.pdf"
					target="_blank"
					class="Medium  black underline "
				><b>Regolamento</b></a>
			</div>
			<div class="col-4"> <a
					href="privacy.pdf"
					target="_blank"
					class="Medium  black  underline   "
				><b>Privacy Policy</b></a>
			</div>
			<div class="col-4"><a
					href="cookie.pdf"
					target="_blank"
					class="Medium  black underline  "
				><b>Cookies Policy</b></a>
			</div>

		</div> -->

	</footer>
</template>


<script>
import { sharedEnvMixin } from "../../mixins/sharedEnvMixin";

export default {
  name: "Footer",
  mixins: [sharedEnvMixin],
  mounted() {},
};
</script>
<style lang="css" scoped>
	.footerBack {
		/* height: 530px; */
	}

	.footer-bottom {
		padding: 10px;
	}

	.line {
		height: 36px;
	}

	.title,
	.url,
	.icon {
		color: white;
		font-size: 3.4rem;
	}

	.operazioni_premi {
		font-size: 3.6rem;
		letter-spacing: 0.1px;
	}

	.url a:hover {
		color: white !important ;
		text-decoration: underline !important;
	}

	.privacy a {
		font-size: 2.4rem !important;
		text-decoration: none !important;
	}

	span {
		font-size: 2.4rem;
	}

	@media screen and (max-width: 1024px) {
		.operazioni_premi {
			font-size: 2.8rem;
		}
		.privacy a {
			font-size: 2rem !important;
		}
		span {
			font-size: 1.6rem;
		}
	}

	@media screen and (max-width: 768px) {
		.title,
		.url,
		.icon {
			font-size: 2.6rem;
		}

		.operazioni_premi {
			font-size: 2rem;
		}

		.privacy a {
			font-size: 1.4rem !important;
		}

		span {
			font-size: 1.2rem;
		}
	}

	@media screen and (max-width: 375px) {
		.title,
		.url,
		.icon {
			font-size: 2rem;
		}

		.operazioni_premi {
			font-size: 1.4rem;
		}

		.privacy a {
			font-size: 0.8rem !important;
		}

		span {
			font-size: 0.6rem;
		}
	}
</style>
