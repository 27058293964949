<template>
	<div
		class="widthFull  loginBack"
		id="registration"
	>

		<div class=" pt-5 container">
			<loading v-if="loadingState" />
			<div class="row justify-content-center mt-2 pt-5 pb-5 ">
				<div>

					<div
						class="form-row justify-content-center "
						v-if="authStatus != 'success'"
					>
						<div class="text-center  pb-3"><img
								src="../../assets/images/registrati.png"
								class="img-fluid  "
							/></div>
					</div>

					<!-- <h1
						v-if="authStatus != 'success'"
						class="white mainTitleSize widthFull center uppercase pt-md-5 mt-md-5"
					>
						<b>registrati</b>
					</h1> -->
					<form
						v-if="authStatus != 'success'"
						class="pt-0 pb-3  pt-3 pr-md-5 pl-md-5 custForm"
						id="registration-form"
						@submit.prevent="registration"
					>
						<div
							v-show="adult2"
							class="form-row  justify-content-center"
						>
							<div class="col-md-5 mb-3">
								<div class="group">

									<input
										type="text"
										class="form-control inputBold"
										v-model="first_name_player"
										id="first_name_player"
										name="first_name_player"
										data-vv-as="Nome"
										v-validate="{required:true, max: 128, min: 2}"
										:class="{ 'is-invalid': errors.has('first_name_player'), valid: !!first_name_player }"
									>
									<span class="highlight"></span>
									<span class="bar"></span>
									<label
										class="niceInputLabel"
										for="first_name_player"
									>Nome </label>

									<div
										v-if="errors.has('first_name_player')"
										class="invalid-feedback"
									>
										{{ errors.first("first_name_player") }}
									</div>
								</div>
							</div>
							<div class="col-md-5 mb-3">
								<div class="group">

									<input
										type="text"
										class="form-control inputBold"
										v-model="last_name_player"
										id="last_name_player"
										name="last_name_player"
										data-vv-as="Cognome"
										v-validate="{required:true, max: 128, min: 2}"
										:class="{ 'is-invalid': errors.has('last_name_player'), valid: !!last_name_player }"
									>
									<span class="highlight"></span>
									<span class="bar"></span>
									<label
										class="niceInputLabel"
										for="last_name_player"
									>Cognome </label>

									<div
										v-if="errors.has('last_name_player')"
										class="invalid-feedback"
									>
										{{ errors.first("last_name_player") }}
									</div>
								</div>
							</div>
						</div>
						<div
							v-show="adult2"
							class="form-row  justify-content-center "
						>
							<div class="col-md-5 mb-3 ">
								<div class="group ">

									<input
										type="text"
										v-model="username_player"
										class="form-control inputBold"
										id="username_player"
										name="username_player"
										@blur="checkIfExistEmail"
										ref="username_player"
										data-vv-as="Email"
										v-validate="{required:true,email: true, temporaryEmail:true, plusIssueEmail: true, max: 56}"
										:class="{ 'is-invalid': errors.has('username_player') || existEmail, valid: !!username_player }"
									>
									<span class="highlight"></span>
									<span class="bar"></span>
									<label
										class="niceInputLabel"
										for="username_player"
									>Email </label>

									<div
										v-if="existEmail"
										class="invalid-feedback"
										style="display:block;"
									>
										<span v-if="existEmailSocial">Email digitato già presente. Sei registrato con: <strong class="text-capitalize">{{ existEmailSocial }}</strong></span>
										<span v-else>Email digitato già presente</span>
									</div>
									<div
										v-if="errors.has('username_player')"
										class="invalid-feedback"
									>
										{{ errors.first("username_player") }}
									</div>
								</div>
							</div>
							<div class="col-md-5 mb-3 ">
								<div class="group">

									<input
										type="text"
										v-model="confirm_username"
										class="form-control inputBold"
										@paste.prevent
										id="confirm_username"
										name="confirm_username"
										data-vv-as="Conferma email"
										v-validate="{required:true,email: true,confirmed: username_player }"
										:class="{ 'is-invalid': errors.has('confirm_username'), valid: !!confirm_username }"
									>
									<span class="highlight"></span>
									<span class="bar"></span>
									<label
										class="niceInputLabel"
										for="confirm_username"
									>Conferma email </label>

									<div
										v-if="errors.has('confirm_username')"
										class="invalid-feedback"
									>
										{{ errors.first("confirm_username") }}
									</div>
								</div>
							</div>
						</div>
						<div
							v-show="adult2"
							class="form-row  justify-content-center"
						>
							<div class="col-md-5 mb-3">
								<div class="group">

									<input
										type="password"
										class="form-control inputBold"
										v-model="password_player"
										id="password_player"
										name="password_player"
										data-vv-as="Password"
										v-validate="{required:true, passwordCheck: true, min: 8, max: 56}"
										:class="{ 'is-invalid': errors.has('password_player'), valid: !!password_player }"
									>
									<span class="highlight"></span>
									<span class="bar"></span>
									<label
										class="niceInputLabel"
										for="password_player"
									>Password </label>

									<div
										v-if="errors.has('password_player')"
										class="invalid-feedback"
									>
										{{ errors.first("password_player") }}
									</div>
								</div>
							</div>
							<div class="col-md-5 mb-3">
								<div class="group">

									<input
										type="password"
										class="form-control inputBold"
										v-model="confirm_password"
										id="confirm_password"
										name="confirm_password"
										data-vv-as="Conferma Password"
										v-validate="{required:true, passwordCheck: true, min: 8, max: 56,confirmed:password_player}"
										:class="{ 'is-invalid': errors.has('confirm_password'), valid: !!confirm_password }"
									>
									<span class="highlight"></span>
									<span class="bar"></span>
									<label
										class="niceInputLabel"
										for="confirm_password"
									>Conferma Password </label>

									<div
										v-if="errors.has('confirm_password')"
										class="invalid-feedback"
									>
										{{ errors.first("confirm_password") }}
									</div>
								</div>
							</div>
						</div>

						<div class="form-row justify-content-center">

							<!-- data di nascita -->
							<div class="col-md-5 mb-3">
								<div class="group">

									<datepicker
										:language="it"
										v-model="birth_date_player"
										:open-date="openDate"
										:initialView="'year'"
										:full-month-name="true"
										:format="'dd MMMM yyyy'"
										:monday-first="true"
										:disabledDates="disabledDatesStart"
										name="birth_date_player"
										class="data-input-bg"
										input-class="form-control dateInput inputBold"
									></datepicker>

									<input
										type="text"
										v-show="false"
										v-model="birth_date_player"
										class="form-control inputBold"
										id="birth_date_player"
										name="birth_date_player"
										data-vv-as="Data di nascita"
										v-validate="{ required: true }"
										:class="{
                          'is-invalid': errors.has('birth_date_player'),
                          valid: !!birth_date_player,
                        }"
									/>
									<label
										class="niceInputLabel Regular yellow"
										for="birth_date_player"
									>Data di nascita </label>

									<span class="highlight"></span>
									<span class="bar"></span>
									<div
										v-if="errors.has('birth_date_player')"
										class="invalid-feedback"
									>
										{{ errors.first("birth_date_player") }}
									</div>
								</div>
							</div>

							<div class="col-md-5"></div>

						</div>



						<div v-show="adult2">
							<div class="col-md-12 ml-lg-5 mb-3">
								<label
									class="form-check-label privacy widthFull Regular theme-1 pl-3"
									for="agreement3_player"
								>
									Cliccando su REGISTRATI accetto il di aver letto e compreso il
									<a
										class="white underline"
										:href="regolamentoPolicy"
										target="_blank"
									>Regolamento</a> e la <a
										class="white underline"
										:href="privacyPolicy"
										target="_blank"
									>Privacy Policy</a></label>
								<div class="form-check">
									<div class="group">
										<input
											class="form-check-input"
											type="checkbox"
											v-model="agreement2_player"
											id="privacy"
											name="trattamento dati"
											data-vv-as="trattamento dati"
											v-validate="{required: true}"
											:class="{ 'is-invalid': errors.has('trattamento dati') }"
										>
										<label
											class="form-check-label privacy Regular theme-1"
											for="privacy"
										>
											Acconsento al trattamento dei miei dati personali per le sole finalità inerenti il presente concorso ai sensi del Regolamento UE 2016/679
										</label>
										<div
											v-if="errors.has('trattamento dati')"
											class="invalid-feedback"
										>
											{{ errors.first("trattamento dati") }}
										</div>
									</div>
								</div>
								<div class="form-check">
									<div class="group">
										<input
											class="form-check-input"
											type="checkbox"
											v-model="agreement3_player"
											id="agreement2_player"
											name="profilazione"
											data-vv-as="profilazione"
											v-validate="{required: false}"
											:class="{ 'is-invalid': errors.has('profilazione') }"
										>
										<label
											class="form-check-label privacy Regular theme-1"
											for="agreement2_player"
										>
											Acconsento al trattamento dei dati per finalità di profilazione
										</label>
										<div
											v-if="errors.has('profilazione')"
											class="invalid-feedback"
										>
											{{ errors.first("profilazione") }}
										</div>
									</div>

								<h5 class="Regular">Hai bisogno di aiuto? <a class="underline black" href="mailto:info@premiocerto.kozelbeer.it">info@premiocerto.kozelbeer.it</a></h5>

								</div>
								<!--<div class="form-check">
						<div class="group">
							<input class="form-check-input" type="checkbox" v-model="privacy4"  id="privacy4" name="marketing" data-vv-as="marketing" v-validate="{required: false}" :class="{ 'is-invalid': errors.has('marketing') }">
							<label class="form-check-label" for="privacy4">
								Esprimo il consenso al trattamento dei miei dati per le finalità di marketing: comunicazioni promozionali e commerciali relative a servizi/prodotti o eventi aziendali;
							</label>
							<div v-if="errors.has('marketing')" class="invalid-feedback">
								{{ errors.first("marketing") }}
							</div>
						</div>
					</div>-->

							</div>
						</div>
						<div
							class="col-md-12 center badge badge-danger"
							v-if="authStatus == 'error'"
						>
							Errore conessione
						</div>

						<div
							v-show="adult2"
							class="col-md-12 center mt-4  mb-4"
						>
							<button
								class="btn btn-primary  Regular uppercase  "
								type="submit"
							>
								Registrati
							</button>
						</div>

					</form>
					<div
						v-if="authStatus == 'success'"
						class="container pb-5 "
					>
						<div class="row   pb-5">
							<div class="col-12 pb-5 col-md-10 mx-auto">
								<div class="Regular ">
									<h1 class=" center mb-4 ml-3  pt-5 mt-5 theme-1"> <b> Complimenti! La tua registrazione <br /> è avvenuta con successo</b></h1>

									<p class="h5 pt-4 pb-4 pl-4 pr-4 theme-1
							 ">
										Per completarla, conferma il tuo indirizzo e-mail cliccando sul link che trovi nella mail che riceverai entro pochi minuti.<br><br>
										Controlla quindi la tua casella di posta elettronica.<br>Se non hai ricevuto la nostra email, verifica che non si trovi nella cartella spam o della posta indesiderata.<br><br>
										La mancata conferma della registrazione non ti permetterà di accedere al sito<br>
									</p>

									<div class="mt-5 center">
										<router-link
											tag="button"
											class="btn btn-primary  Regular uppercase "
											to="/login"
										>
											Accedi
										</router-link>

										<br class="d-none d-md-block" /> <br class="d-none d-md-block" /> <br class="d-none d-md-block" /> <br class="d-none d-md-block" />
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>



<script>
import { AUTH_REGISTRATION } from "actions/auth";
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import { it } from "vuejs-datepicker/dist/locale";
const moment = require("moment");

import { checkUniqueMixin } from "../../mixins/checkUniqueMixin";
import { locationMixin } from "../../mixins/locationMixin";
import { toDateMixin } from "../../mixins/toDateMixin";
import { sharedEnvMixin } from "../../mixins/sharedEnvMixin";
import { gtagMixin } from "../../mixins/gtagMixin";
import { popupConfigurationMixin } from "../../mixins/popupConfigurationMixin";

export default {
  components: {
    Datepicker,
  },
  name: "registration",
  mixins: [
    checkUniqueMixin,
    locationMixin,
    toDateMixin,
    sharedEnvMixin,
    gtagMixin,
    popupConfigurationMixin,
  ],
  data() {
    return {
      moment: moment,
			it: it,
      birth_date_player: "",
      username_player: "",
      confirm_username: "",
      password_player: "",
      confirm_password: "",
      first_name_player: "",
      last_name_player: "",
      address_player: "",
      civic_number_player: "",
      province_player: "",
      zip_player: "",
      phone_player: "",
      agreement1_player: "",
      agreement2_player: "",
      agreement3_player: "",
      agreement4_player: "",
      gender_player: "",
      txt1_player: "",

      adult2: true,
      registerPage: false,
      colorType: "",
      colorType2: "",

      disabledDates: {
        to: "",
        from: "",
      },
      disabledBirthdays: {
        to: "",
        from: "",
      },
      openDate: new Date(),
      disabledDatesStart: {},
    };
  },

  methods: {
    showPopUp() {
      this.popupErrorText(
        "",
        "",
        "<h4 class='Regular p-4'>Dal numero di cellulare registrato potrai partecipare anche tramite <b>WhatsApp®</b> inviando la tua prova d'acquisto al numero <b>3917054053</b></h4>",
        "",
        ""
      );
    },

    showPdv() {
      var pdv = this.pdv;
      pdv.sort((a, b) => a[1].localeCompare(b[1]));
      var text = "";
      for (var x in pdv) {
        text +=
          '<p class="text-left"> <b class="medium">' +
          pdv[x][1] +
          "</b> - " +
          pdv[x][0] +
          " </p> ";
      }

      this.$swal({
        type: "",
        title: '<strong class="modalT danger">Punti vendita.</strong>',
        html: '<div class="pdvContainer">' + text + "</div>",
        confirmButtonText: "CHIUDI",
        confirmButton: "closeProductsModal",

        background: "rgb(255, 255, 255)",
      });
    },
    registerPagefun() {
      var _vue = this;
      _vue.registerPage = true;

      this.$router.push({ query: { type: this.colorType, viewform: 1 } });
    },
    registration: function (e) {
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //PER CHIAMARE PASSARE I NOME DEI CAMPI SUL DB, PER PASSARE DEI PARAMETRI NEL DB CHE NON SERVONO AI FINI DELL'INSERIMENTO/////
      //MA COME CONTROLLO NEL BACK E' SUFFICIENTE ANDARE AD INSERIRE NELLA VARIABILE "NOPASS" DEL CONFIG I CAMPI DA NON CONSIDERARE/
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      let data = this.$encryptByAES({
        player: {
          username_player: this.username_player || "",
          password_player: this.password_player,
          first_name_player: this.first_name_player || "",
          last_name_player: this.last_name_player || "",
          birth_date_player: this.birth_date_player
            ? moment(this.birth_date_player).format("YYYY-MM-DD")
            : "",
          address_player: this.address_player || "",
          civic_number_player: this.civic_number_player || "",
          province_player: this.province_player || "",
          zip_player: this.zip_player || "",
          phone_player: "39" + this.phone_player || "",
          email_player: this.username_player || "",
          agreement1_player: this.agreement1_player || 0,
          agreement2_player: this.agreement2_player || 0,
          agreement3_player: this.agreement3_player || 0,
          agreement4_player: this.agreement4_player || 0,
          agreement5_player: this.agreement5_player || 0,

          confirmed_player: 0,
          txt1_player: this.txt1_player || "",
          gender_player: this.gender_player || "",
        },
      });

      this.$callLog("registration", data);

      var itGood = false;
      this.$validator.validate().then((valid) => {
        if (valid && !this.existEmail && !this.existPhone) {
          this.$store.dispatch(AUTH_REGISTRATION, data).then(() => {
            this.scrollToTop();

            this.gtagCheck(
              "registration",
              this.username_player,
              "success",
              "success"
            );
          });
        } else {
          this.$scrollTo("#" + this.errors.items[0].field, 700, {
            offset: -170,
          });
        }
      });
    },

    calcNewStartDate: function () {
      this.openDate = new Date(
        moment().format("YYYY") - 18,
        moment().format("MM") - 1,
        moment().format("DD")
      );
      var newStartDate = new Date(1900, 0, 1);
      var now = new Date(
        moment().format("YYYY") - 18,
        moment().format("MM") - 1,
        moment().format("DD")
      );
      this.disabledDatesStart = {
        to: newStartDate, // Disable all dates up to specific date
        from: now, // Disable all dates after specific date
      };
    },
    scrollToTop: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo(".main", 700, { offset: -2000 });
      }, 100);
    },
  },
  watch: {
    agreement1_player(val) {},

    phone_player: function (val) {
      if (val) {
        this.phone_player = val.replace(/\D+/g, "");
      }
    },
    first_name_player: function (val) {
      if (val) {
        this.first_name_player = val.replace(/\d+/g, "");
        this.first_name_player = this.first_name_player.replace(
          /[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi,
          ""
        );
        this.first_name_player =
          this.first_name_player.charAt(0).toUpperCase() +
          this.first_name_player.slice(1);
      }
    },
    txt1_player: function (val) {
      if (val < 0) {
        this.txt1_player = val.replace(val, 0);
      }
    },
    cf: function (val) {
      if (val) {
        this.cf = this.cf.replace(
          /[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi,
          ""
        );
        this.cf = this.cf.toUpperCase();
      }
    },
    last_name_player: function (val) {
      if (val) {
        this.last_name_player = val.replace(/\d+/g, "");
        this.last_name_player = this.last_name_player.replace(
          /[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi,
          ""
        );
        this.last_name_player =
          this.last_name_player.charAt(0).toUpperCase() +
          this.last_name_player.slice(1);
      }
    },
  },
  created: function () {
    this.$validator.extend("notZeros", {
      getMessage: (field) =>
        " Il campo Numero di telefono non ha un formato valido.",
      validate: (value) => {
        // value must be > zero
        if (
          value > 1000000 &&
          value.length > 1 &&
          value != 30000000 &&
          value != 300000000 &&
          value != 3000000000 &&
          value != 30000000000
        )
          return true;
        return false;
      },
    });

    this.colorType = this.$route.query.type;

    if (this.$route.query.viewform) {
      this.registerPage = true;
    }
  },
  mounted: function () {
    // this.scrollToTop();
    (this.disabledDates = {
      to: this.calcToDate(), // Disable all dates up to specific date
      from: this.calcFromDate(), // Disable all dates after specific date
    }),
      (this.disabledBirthdays = {
        from: this.calcToDate18(), // Disable all dates after specific date
      });

    this.$track("disabledDates", this.disabledDates);

    this.calcNewStartDate();
    var _vue = this;
    setTimeout(function () {
      _vue.populateProvinces(), (_vue.scrollFinished = true);
    }, 1500);
  },
  computed: {
    ...mapGetters(["isAuthenticated", "loadingState", "authStatus"]),
  },
};
</script>
<style lang="css" scoped>
</style>

<style lang="scss">
// @import './src/assets/scss/forms.scss';
</style>
<style lang="css" scoped>
	.form-check-label {
		font-size: 1rem !important;
	}

	.privacy {
		font-size: 1.4rem !important;
	}

	input[type='checkbox'] {
		margin-top: 12px;
	}

	@media screen and (max-width: 375px) {
		.form-check-label {
			font-size: 0.8rem !important;
		}
	}
</style>

