<template>
	<div
		class="widthFull h-100  premioBack"
		id="premio"
	>

		<div class="container pt-4">

			<div class="text-center pt-5"><img
					src="../../assets/images/premio_title.png"
					class="img-fluid premio-title  "
				/></div>

			<div class="">
				<img
					src="../../assets/images/iconico_boccale.png"
					class="img-fluid boccale"
				>
			</div>

		</div>

	</div>
</template>


<script>
import { mapGetters } from "vuex";
const moment = require("moment");

import { sharedEnvMixin } from "../../mixins/sharedEnvMixin";
import { innerWidthMixin } from "../../mixins/innerWidthMixin";

export default {
  name: "award",

  mixins: [sharedEnvMixin, innerWidthMixin],
  data() {
    return {
      oldPresent: false,
      moment: moment,
    };
  },
  methods: {},

  mounted: function () {},
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "authStatus",
      "getPromotions",
      "getOldPromotions",
    ]),
    timeStore() {
      return this.$timestore.now;
    },
  },
};
</script>
<style lang="scss" scoped>
	.boccale {
		position: absolute !important;
		max-height: 120px;
		top: 160px;
		left: 50%;
		transform: translateX(-50%);
		box-shadow: 3px 3px 8px #2a2313;
	}

	.btn-group-lg > .btn,
	.btn-lg {
		padding: 0.8rem 3rem;
		font-size: 26px;
		line-height: 1;
		border-radius: 0.3rem;
	}

	.premioBack {
		position: relative;

		.button-partecipa {
			display: inline-block;
			position: absolute;
			bottom: 120px;
			left: 29%;
		}
	}

	@media screen and (max-width: 1366px) {
		.premioBack {
			.button-partecipa {
				left: 20%;
			}
		}
	}

	@media screen and (max-width: 1024px) {
		.premioBack {
			.button-partecipa {
				left: 14%;
				bottom: 36%;
			}

			.boccale {
				top: 250px;
			}
		}
	}

	@media screen and (max-width: 768px) {
		.premioBack {
			.button-partecipa {
				bottom: 35%;
				left: 14%;
			}
		}

		.premio-title {
			max-height: 70px !important;
		}
	}

	@media screen and (max-width: 600px) {
		.premioBack {
			.button-partecipa {
				bottom: 52%;
				left: 12%;
			}
		}

		.premio-title {
			max-height: 40px !important;
		}

		.boccale {
			max-width: 280px;
			top: 160px !important;
		}
	}
</style>
