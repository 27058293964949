<template>

</template>
<script>
	import { mapState, mapGetters } from 'vuex';
	import axios from 'axios';
	import { USER_REQUEST, USER_SET_DEFAULT_SHIP_DATA } from 'actions/user';
	import Datepicker from 'vuejs-datepicker';
	import { it } from 'vuejs-datepicker/dist/locale';
	import VueTimepicker from 'vue2-timepicker';
	// import VideoBackground from 'vue-responsive-video-background-player';

	import { FormWizard, TabContent } from 'vue-form-wizard';
	import 'vue-form-wizard/dist/vue-form-wizard.min.css';

	import { checkUniqueMixin } from '../../mixins/checkUniqueMixin';
	import { toDateMixin } from '../../mixins/toDateMixin';
	import { sharedEnvMixin } from '../../mixins/sharedEnvMixin';
	import { innerWidthMixin } from '../../mixins/innerWidthMixin';
	import { playFunctionMixin } from '../../mixins/playFunctionMixin';
	import { locationMixin } from '../../mixins/locationMixin';

	const moment = require('moment');

	export default {
		name: 'play',
		components: {
			Datepicker,
			VueTimepicker,
			// VideoBackground,
			FormWizard,
			TabContent,
		},

		mixins: [
			checkUniqueMixin,
			toDateMixin,
			sharedEnvMixin,
			innerWidthMixin,
			playFunctionMixin,
			locationMixin,
		],
		data() {
			return {
				prod: '',
				videoLoaded: false,
				videoOpacity: 1,
				showDateInfoVar: false,
				it: it,
				timeVal: '',
				prod1: '',
				prod2: '',
				stepPopUp: true,
				priceFocused: false,
				colorType: '',
				product: '',
				advisor: false,
				productIndex: 1,
				showProdError: false,
				quantity: '',
				disabledDates: {
					to: '',
					from: '',
				},

				pdvs: [],
				pdvsState: false,
				pdvState: false,
				pdvsError: false,
				pdv_prov: '',
				prodSel1: false,
				prodSel2: false,
				pdv: '',
				pdv_city: '',
				pdv_cities: [],
				items: {},
				provincesState: true,
				cities: [],
				citiesState: false,
				zips: [],
				zipsState: false,
				allInvoiceInfo: false,
				question: '',
				canScroll: true,
				scrollFinished: false,
				stepSize: 'md',
				rebate: '',
				totPrice: '',
				priceError: false,
				timeC: false,
				countdown: 0,
				datenow: '',
				showInfo: false,
				token: '',
				id_image: '',
				zip: '',
				city: '',
				province: '',
				endedVideo: true,
				taglia: '',
				taglie: [],
			};
		},
		methods: {
			showSuccessUpdateSHIP() {
				// Use sweetalret2
				this.$swal({
					type: 'success',
					title: 'I dati per la spedizione sono stati correttamente aggiornati',
					timer: 4500,
				});
			},
			ended: function () {
				this.endedVideo = true;
			},
			videoReady: function () {
				this.videoLoaded = true;
			},
			async recaptcha() {
				var _vue = this;
				const recaptcha = _vue.$recaptchaInstance;

				// (optional) Wait until recaptcha has been loaded.
				await _vue.$recaptchaLoaded();

				// Execute reCAPTCHA with action "login".
				this.token = await _vue.$recaptcha('login');

				// Do stuff with the received token.
			},
			stepIndex(from, to) {
				if (this.scrActiv) {
					//this.$scrollTo('.main', 700, { offset: -170 });
					if (to == 0) {
						this.$route.hash != '#step1'
							? this.$router.push({ hash: '#step1' })
							: '';
					}
					if (to == 1) {
						this.$route.hash != '#step2'
							? this.$router.push({ hash: '#step2' })
							: '';
					}
					if (to == 2) {
						this.$route.hash != '#step3'
							? this.$router.push({ hash: '#step3' })
							: '';
					}
				}

				if (to == 1) {
					this.showInfo = true;
				} else {
					this.showInfo = false;
				}
				this.$track('STEP', from + ' => ' + to);
			},
			checkProducts: function (param) {
				var products = this.products;
				var count = 0;
				if (param) {
					this.showProdError = false;
				} else {
					for (var i in products) {
						var prod = products[i].product;
						if (products[i].quantity) {
							if (prod.includes('30')) {
								count = count + 1 * parseInt(products[i].quantity);
							} else {
								count = count + 3 * parseInt(products[i].quantity);
							}
						}
					}
					if (count >= 3) {
						this.showProdError = false;
					} else {
						this.showProdError = true;
					}
				}
			},
			showViever() {
				var el = this.$refs.inv;
				const viewer = el.$viewer;
				viewer.show();
			},
			timeReactive() {
				if (this.timeStore > moment(process.env.VUE_APP_FINISH)) {
					window.location.replace('/');
				}
			},
			calcTotPrice: function () {
				var prod = this.products;
				var count = 0;
				for (var x in prod) {
					if (prod[x].price && prod[x].quantity) {
						count =
							count +
							parseFloat(prod[x].price).toFixed(2) * parseInt(prod[x].quantity);
					}
				}
				if (isNaN(count)) {
					count = 0.0;
				}

				if (count > 0) {
					this.priceError = false;
				} else {
					this.priceError = true;
				}

				this.totPrice = parseFloat(count).toFixed(2);
			},

			sessionFail: function () {
				this.$swal({
					type: 'error',
					title: '',
					html: '<h4 class="smallText">La tua sessione è scaduta. Effettua nuovamente l`operazione.</h4>',
					confirmButtonText: 'CHIUDI',
					onClose: () => {
						this.$router.push('/');
						setTimeout(function () {
							window.location.reload();
						}, 500);
					},
				});
			},
			reload: function () {
				window.location.reload();
			},
			resetStatus: function () {
				//window.location.reload();
				this.progress = 'START';
				var _vue = this;
				this.invoiceFrontBucket = '';
				this.invoiceRearBucket = '';
				this.date = '';
				this.time.HH = '';
				this.time.mm = '';
				this.price = '';
				this.priceCent = '';
				this.number = '';
				this.documentType = '';
				this.xuserx = '';
				this.lastnamex = '';
				this.products = {};
				this.documentType = '';
				this.ocrData = '';
				this.award = '';
				this.invoiceStatus = '';
				this.pratica = '';
				this.xuserx = '';
				this.lastnamex = '';
				this.address = '';
				this.civic = '';
				this.province = '';
				this.city.city = '';
				this.zip = '';
				this.phone = '';
				this.near = '';
			},

			showErrorOCR(text) {
				var html =
					'<p class="smallText text-left">Nell`immagine eventualmente caricata:</p><ul>';

				var x = 0;
				var z = 0;
				for (var i in text) {
					if (text[i].status == 'KO') {
						if (text[i].description) {
							html +=
								'<li class="smallText text-left">' +
								text[i].description +
								'</li>';
						}

						x++;
					}
					//if ( text[i].error == 'price_less' ){
					//  z = 1
					//}
				}
				html += '</ul>';

				// TO MANY ERROR = IS NOT INVOICE
				if (x >= 4) {
					html =
						'<p class="smallText text-left">Purtroppo l`immagine caricata non sembra essere quella di un scontrino.</p>';
					html +=
						'<ul><li class="smallText text-left">I dati sullo scontrino devono essere ben visibili.</li>';
					html +=
						'<li class=" smallText text-left mt-2">Se lo scontrino è troppo lungo, piegalo a fisarmonica per mostrare solo i dati necessari</li> ';
					html +=
						'<li class=" smallText text-left mt-2">La foto caricata deve avere un peso compreso fra 12KB e 10MB</li></ul> ';
					html +=
						'<p class="smallText text-left mt-3">Prova a rifare la foto e ricarica lo scontrino</p> ';
					z = 4;
				} else if (x <= 3) {
					html += '<p class="smallText text-left">Attenzione!</p>';
					if (x == 1 && text.date.error == 'not_play_period') {
						// IF ONLY DATE OUT OF PERIOD
						// html += '<ul><li class="smallText text-left">Sono passati piú di 5 giorni compreso oggi dalla data di acquisto riportata sul documento di vendita.</li></ul>'
					} else {
						// IF MANY ERRORS
						html +=
							'<ul><li class="smallText text-left">I dati sullo scontrino devono essere ben visibili.</li>';
						html +=
							'<li class=" smallText text-left mt-2">Se lo scontrino è troppo lungo piegalo a fisarmonica per mostrare solo i dati necessari</li>';
						html +=
							'<li class=" smallText text-left mt-2">La foto caricata deve avere un peso compreso fra 12KB e 10MB</li></ul> ';
						html +=
							'<p class="smallText text-left mt-3">Prova a rifare la foto e ricarica lo scontrino</p> ';
					}
				}
				this.$swal({
					type: 'error',
					title: '<strong class="">Ops!</strong>',
					html: html,
					//confirmButtonText: "Esempio scontrino",
					confirmButtonColor: '#c95c1c',
					//showCancelButton: true,
					cancelButtonText: 'Chiudi',
					cancelButtonColor: 'black',
				}).then((okay) => {});
			},
			validateFirstTab: function () {
				var _vue = this;

				return this.$validator.validateAll('form-1');
			},
			validateSecondTab: function () {
				return this.$validator.validateAll('form-3');
			},
			validateThirdTab: function () {
				if (this.invoiceFront) {
					return this.$validator.validateAll('form-4');
				} else {
					return false;
				}
				//return true;
			},
			validate4Tab: function () {
				//if ( this.totPrice > 0 ){
				return this.$validator.validateAll('form-2');
				//  }else{
				//    this.priceError = true
				//    return false
				//  }
			},
			onComplate: function () {
				this.play();
			},

			showDateInfo() {
				if (this.showDateInfoVar) {
					this.showDateInfoVar = false;
				} else {
					this.showDateInfoVar = true;
				}
			},
			showError(text) {
				this.$swal({
					type: 'error',
					title: '<strong class="modalText danger">' + text + '</strong>',
					html: '',
					confirmButtonText: 'CHIUDI',
				});
			},
			showDublicateImage(img) {
				this.$swal({
					type: 'error',
					title: 'Ops!',
					html:
						'<p class="center smallText">Lo scontrino:</p> <p class="center smallText">' +
						img +
						'</p><p class="center smallText"> risulta già caricata</p>',
					confirmButtonText: 'CHIUDI',
				});
			},

			addProduct() {
				this.productIndex = this.productIndex + 1;
				this.products.push({
					index: 'product' + this.productIndex,
					indexCount: 'quantity' + this.productIndex,
					product: '',
					quantity: '',
					price: '',
				});
			},
			removeProduct(elem) {
				var _vue = this;
				var index = this.products.indexOf(elem);
				this.products.splice(elem, 1);
				_vue.calcTotPrice();
			},

			infoPopUp() {
				this.$swal({
					//input: 'text',
					confirmButtonText: 'Carica FRONTE &rarr;',
					imageUrl: require('../../assets/images/invoice-example.png'),
					imageClass: 'minImg',
					focusCancel: false,
					focusConfirm: true,
					cancelButtonText: 'Annulla',
					showCancelButton: true,
					title: '',
					html:
						'<p class="smallText text-left"><ul  class="smallText text-left"><li class="smallText">I dati sullo scontrino devono essere ben visibili. </li>' +
						'<li class="smallText">Se lo scontrino è troppo lungo piegalo a fisarmonica per mostrare solo i dati necessari</li>' +
						'<li class=" smallText text-left mt-2">La foto caricata deve avere un peso compreso fra 12KB e 10MB</li></ul></p>',
				}).then((result) => {
					if (result.value) {
						this.$refs.fileInput1.click();
					}
				});
			},

			changeSwalImage: function () {
				alert('sdfgasd');
			},
			UploadError() {
				this.$swal({
					type: 'error',
					title:
						'<strong class="modalText danger">Errore caricamento immagine</strong>',
					html: '',
					confirmButtonText: 'CHIUDI',
					confirmButtonColor: '#15267',
				});
			},
			UploadError2(type) {
				if (type == 'size') {
					var html =
						'<p class="smallText text-left" >L`immagine supera peso massimo di 10 Mega</p>';
				} else if (type == 'size2') {
					var html =
						'<p class="smallText text-left" >La foto caricata è in bassa risoluzione grafica.</p><p class="smallText text-left" >Carica una nuova foto ad alta risoluzione</p>';
				} else {
					var html =
						'<p class="smallText text-left" >Sono valide le immagine in formato .pdf, .jpg, .jpeg o .png</p>';
				}

				this.$swal({
					type: 'error',
					title: '<strong class="smallText danger">!Attenzione</strong>',
					html: html,
					confirmButtonText: 'CHIUDI',
					confirmButtonColor: '#15267',
				});
			},

			openListProducts() {
				// Use sweetalret2
				this.$swal({
					type: '',
					title: '<strong class="modalText h2">prodotti in promozione</strong>',
					html:
						'<br/><br/><h5 class="modalText ">- Birra Messina 33cl</h5>' +
						'<h5 class="modalText even ">- Birra Messina 3x33cl</h5>' +
						'<h5 class="modalText ">- Birra Messina Cristalli di Sale 50cl</h5><br/><br/>',
					width: 600,
					confirmButtonText: 'chiudi',
				});
			},

			checkDefaultShipData() {
				if (this.shipData.name) {
					this.name = this.shipData.name;
					this.lastname = this.shipData.lastname;
					this.address = this.shipData.address;
					this.civic = this.shipData.civic;
					this.near = this.shipData.near;
					this.phone = this.shipData.phone;
					this.province = this.shipData.province;
					this.city = {};
					this.city.city = this.shipData.city;
					this.city.istat = 'toset';

					this.zip = this.shipData.zip;
					//this.zips = [
					//	cap => this.shipData.zip
					//]
				}
			},
			scrollToTop: function () {
				var _vue = this;
				setTimeout(function () {
					//_vue.$scrollTo('.main', 700, { offset: -120 });
				}, 100);
			},
			visibilityHow(isVisible, entry) {
				if (isVisible && this.scrollFinished) {
					this.$route.hash != '#come_partecipare'
						? this.$router.push({ hash: '#come_partecipare' })
						: '';
				}
			},
			visibilityPlay(isVisible, entry) {
				if (isVisible && this.scrollFinished) {
					this.$route.hash != '#partecipa'
						? this.$router.push({ hash: '#partecipa' })
						: '';
				}
			},
			visibilityLogin(isVisible, entry) {
				if (isVisible && this.scrollFinished) {
					this.$route.hash != '#login'
						? this.$router.push({ hash: '#login' })
						: '';
				}
			},

			setProfileData: function () {
				if (process.env.VUE_APP_HAS_LOGIN == 'ON') {
					this.email = this.profile.email;
					this.userToken = this.profile.id;
				} else {
					this.email = this.email;
					this.userToken = makeid(10);
				}
			},
		},
		watch: {
			prod: {
				handler(val) {
					if (val) {
						this.prodSel1 = true;
						this.advisor = false;
					} else {
						this.prodSel1 = false;
						this.advisor = false;
					}
				},
			},
			prod2: {
				handler(val) {
					if (val) {
						this.prodSel2 = true;
						this.advisor = false;
					} else {
						this.prodSel2 = false;
						this.advisor = false;
					}
				},
			},
			date: function (val) {},
			time: function (val) {},
			podium1Selected: function (val) {
				if (val) {
					this.podium2Selected = false;
				}
			},
			podium2Selected: function (val) {
				if (val) {
					this.podium1Selected = false;
				}
			},
			xuserx: function (val) {
				if (val) {
					this.xuserx = val.replace(/\d+/g, '');
					this.xuserx = this.xuserx.replace(
						/[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi,
						''
					);
					this.xuserx =
						this.xuserx.charAt(0).toUpperCase() + this.xuserx.slice(1);
				}
			},
			lastnamex: function (val) {
				if (val) {
					this.lastnamex = val.replace(/\d+/g, '');
					this.lastnamex = this.lastnamex.replace(
						/[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi,
						''
					);
					this.lastnamex =
						this.lastnamex.charAt(0).toUpperCase() + this.lastnamex.slice(1);
				}
			},
			pdv_city: function (val) {
				if (val) {
					this.pdv_city = val.replace(/\d+/g, '');
					this.pdv_city = this.pdv_city.replace(
						/[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi,
						''
					);
					this.pdv_city =
						this.pdv_city.charAt(0).toUpperCase() + this.pdv_city.slice(1);
				}
			},
			number: function (val) {
				if (val) {
					this.number = val.replace(/\D+/g, '');
				}
				if (val < 0) {
					this.number = val.replace(val, 0);
				}
			},
			phone: function (val) {
				if (val) {
					this.phone = val.replace(/\D+/g, '');
				}
			},
			// IF CHANGE PROVINCE -> LOAD CITIES
			province: function (newProvince) {
				this.zips = [];
				this.cities = [];
				this.zip = '';
				this.city = '';
				this.cityError = false;
				this.zipError = false;

				if (newProvince) {
					this.citiesState = true;
					axios({
						url: process.env.VUE_APP_ROOT_API + 'api/getComuni',
						method: 'POST',
						data: {
							sigla: newProvince,
						},
						headers: {
							Authorization: 'Bearer ' + process.env.VUE_APP_KITTY,
							'Content-type': 'application/json',
						},
					})
						.then((resp) => {
							this.cities = resp.data.cities;
							this.citiesState = false;
						})
						.catch((resp) => {
							this.cities = [];
							this.citiesState = false;
						});
				}
			},
			// IF CHANGE PROVINCE -> LOAD CITIES
			pdv_prov: function (newProvince) {
				this.pdv = [];
				this.pdvsState = false;
				this.pdv = '';
				this.pdv_city = '';
				this.pdvsError = false;
				this.pdvError = false;
			},
			city: function (newCity) {
				this.zipError = false;
				this.zips = [];
				this.zip = '';

				if (newCity.cityistat) {
					this.zipsState = true;
					axios({
						url: process.env.VUE_APP_ROOT_API + 'api/getCap',
						method: 'POST',
						data: {
							istat: newCity.cityistat,
						},
						headers: {
							Authorization: 'Bearer ' + process.env.VUE_APP_KITTY,
							'Content-type': 'application/json',
						},
					})
						.then((resp) => {
							this.zips = resp.data.cap;
							this.zipsState = false;
						})
						.catch((resp) => {
							this.zips = [];
							this.zipsState = false;
						});
				}
			},
			vw: function () {
				if (this.vw > 1200) {
					this.stepSize = 'md';
				} else if (this.vw > 600) {
					this.stepSize = 'sm';
				} else {
					this.stepSize = 'xs';
				}
			},
			getProfile: function () {
				this.setProfileData();
			},
		},
		updated: function () {},
		created() {
			var now = this.timeStore;
			var then = moment(process.env.VUE_APP_OCR_END);
			var dif = then.diff(now, 'days', true);
			this.setProfileData();
			//moment.utc(moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(then,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")
			this.countdown = parseInt(dif * 14.28);
		},
		async mounted() {
			if (hasLogin) {
				await this.retrivePlays();
			} else {
				_vue.progress = 'START';
			}
			this.setProfileData();
			//await this.recaptcha();
			this.populateProvincesPdv();
			var now = this.timeStore;
			var then = moment(process.env.VUE_APP_OCR_END);
			var dif = then.diff(now, 'hours', true);

			//moment.utc(moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(then,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")
			this.countdown = parseInt(dif);

			if (this.canScroll) {
				//this.$scrollTo('.main', 300, { offset: -85 });

				this.canScroll = false;
			}
			if (
				process.env.VUE_APP_INSTANT_WIN == 'ON' &&
				process.env.VUE_APP_MORE_WIN == 'OFF'
			) {
				var xkey = localStorage.getItem(
					'$%r332d2we24#@$234' + process.env.VUE_APP_ICODE + '1SDe452@#$%'
				);
				if (xkey == '$&@(*(@Jde20)($40E#@_(_Q#($_@(*_EIS') {
					var xkeyID = localStorage.getItem(
						'$%r332d324#@$234' + process.env.VUE_APP_ICODE + '245DSDe452@#$%'
					);
					var er = this.$decryptWORMY(xkeyID);
					this.invoiceStatus = '600';
					this.justWinCheck = 'Vincita pregressa con email => <br/>' + er;
				}
			}

			if (process.env.VUE_APP_HAS_LOGIN == 'ON') {
				this.$track('Getprofile', this.getProfile);
				this.lastnamex = this.getProfile.cognome;
				this.xuserx = this.getProfile.nome;

				if (this.isAuthenticated) {
					this.$watch(
						() => {},
						(val) => {}
					);
				}
			}

			var _vue = this;

			setInterval(_vue.timeReactive, 5000);

			setTimeout(function () {
				_vue.populateProvinces(),
					(_vue.disabledDates = {
						to: _vue.calcToDate(), // Disable all dates up to specific date
						from: _vue.calcFromDate(), // Disable all dates after specific date
					});

				_vue.scrollFinished = true;
			}, 1500);
		},
		computed: {
			...mapGetters([
				'getProfile',
				'xkey',
				'isAuthenticated',
				'isProfileLoaded',
				'acceptCookies',
				'checkAge',
			]),
			...mapState({
				profile: (state) => state.user.profile,
			}),
			timeStore() {
				return this.$timestore.now;
			},
		},
	};
</script>

