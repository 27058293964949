<template>
	<div
		class="widthFull  loginBack"
		id="play"
	>
		<div
			v-if="isAuthenticated || (!hasLogin && !isAuthenticated)"
			class="container-fluid "
			id="play"
			v-observe-visibility="{
        callback: visibilityPlay,
        intersection: { rootMargin: '-30%' },
      }"
		>
			<!-- WIZARD CON OCR E CARICAMENTO IMMAGINE --->
			<div
				v-if="wizard && hasOcr && hasImg"
				class="row justify-content-center"
			>
				<WizardOcrImage />

			</div>
			<!-- INSERIMENTO DATI SCONTRINO SENZA OCR E CARCAMENTO IMMAGINI --->
			<div
				v-else-if="!wizard && !hasOcr && !hasImg"
				class="row justify-content-center"
			>
				<NoOcrNoUploadImage />
			</div>
			<!-- INSERIMENTO DATI SCONTRINO CON CARICAMENTO IMMAGINI MA SENZA OCR --->
			<div
				v-else-if="!wizard && !hasOcr && hasImg"
				class="row justify-content-center"
			>
				<NoOcrOnlyUploadImage />
			</div>
			<!-- INSERIMENTO DATI SCONTRINO CON CARICAMENTO IMMAGINI, STEP MA SENZA OCR  --->
			<div
				v-else-if="wizard && !hasOcr && hasImg"
				class="row justify-content-center"
			>
				<WizardNoOcr />
			</div>

		</div>
	</div>
</template>

<script>
	import { mapState, mapGetters } from 'vuex';
	import axios from 'axios';
	import { USER_REQUEST, USER_SET_DEFAULT_SHIP_DATA } from 'actions/user';

	import Datepicker from 'vuejs-datepicker';
	import { it } from 'vuejs-datepicker/dist/locale';
	import VueTimepicker from 'vue2-timepicker';
	import { FormWizard, TabContent } from 'vue-form-wizard';
	import 'vue-form-wizard/dist/vue-form-wizard.min.css';

	import NoOcrNoUploadImage from './no-ocr-no-upload-image.vue';
	import NoOcrOnlyUploadImage from './no-ocr-only-upload-image.vue';
	import WizardOcrImage from './wizard-ocr-image.vue';
	import WizardNoOcr from './wizard-no-ocr.vue';

	import { sharedEnvMixin } from '../../mixins/sharedEnvMixin';

	const moment = require('moment');

	export default {
		name: 'play',
		mixins: [sharedEnvMixin],
		components: {
			Datepicker,
			VueTimepicker,
			FormWizard,
			TabContent,
			NoOcrNoUploadImage,
			NoOcrOnlyUploadImage,
			WizardOcrImage,
			WizardNoOcr,
		},
		data() {
			return {
				prod: '',
				loadingState: false,
				stepPopUp: true,
				number: null,
				price: null,
				priceCent: null,
				priceFocused: false,
				hasWin: false,
				progress: '',
				vw: window.innerWidth,
			};
		},
		methods: {
			visibilityHow(isVisible, entry) {
				if (isVisible && this.scrollFinished) {
					this.$route.hash != '#come_partecipare'
						? this.$router.push({ hash: '#come_partecipare' })
						: '';
				}
			},
			visibilityPlay(isVisible, entry) {
				if (isVisible && this.scrollFinished) {
					this.$route.hash != '#partecipa'
						? this.$router.push({ hash: '#partecipa' })
						: '';
				}
			},
			visibilityLogin(isVisible, entry) {
				if (isVisible && this.scrollFinished) {
					this.$route.hash != '#login'
						? this.$router.push({ hash: '#login' })
						: '';
				}
			},

			setProfileData: function () {
				if (process.env.VUE_APP_HAS_LOGIN == 'ON') {
					this.email = this.profile.email;
					this.userToken = this.profile.id;
				} else {
					this.email = this.email;
					// this.userToken = makeid(10);
				}
			},
		},
		watch: {
			vw: function () {
				if (this.vw > 1200) {
					this.stepSize = 'md';
				} else if (this.vw > 600) {
					this.stepSize = 'sm';
				} else {
					this.stepSize = 'xs';
				}
			},
			getProfile: function () {
				this.setProfileData();
			},
		},

		created() {
			this.setProfileData();
		},
		async mounted() {
			this.setProfileData();
			var _vue = this;

			setInterval(_vue.timeReactive, 5000);
		},
		computed: {
			...mapGetters([
				'getProfile',
				'xkey',
				'isAuthenticated',
				'isProfileLoaded',
				'acceptCookies',
				'checkAge',
				'getPopup',
			]),
			...mapState({
				profile: (state) => state.user.profile,
				shipData: (state) => state.user.defaultShipData,
			}),
		},
	};
</script>
