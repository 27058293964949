<template>

  <div class=" group prodotti pl-2">

    <div class="row color-3">
      <div class="col-7 col-md-7 d-flex align-items-center">Prodotto</div>
      <div class="col-2 col-md-2 d-flex align-items-center">

        <span v-if="vw < 600">Qtà</span>
        <span v-else>Quantità</span>
      </div>
      <div class="col-2 col-md-2">Importo unitario</div>
    </div>

    <div v-for="(elem, index) in products" :key="index" class="row mt-2" @blur="handler()" @click="handler()"
      @keyup="handler()">

      <v-select :options="optionSelect" :key="index" v-model="elem.name_product" :clearable="false"
        class="form-control col-7" @blur="handler()" @input="handler()">
        <!-- :required="!elem.name_product" -->

        <template #search="{ attributes, events }">
          <input class="vs__search" v-bind="attributes" v-on="events" />

        </template>
      </v-select>
      <input maxlength="2" min="1" max="99" step="1" class="form-control col-2 mx-2 mx-lg-3" type="number"
        placeholder="Quantità" v-model="elem.quantity_product" v-validate="{ required: true, integer: true, max: 2 }">

      <input maxlength="4" class="form-control col-2" type="number" min="0" max="9.99" step="0.01"
        placeholder="Importo unitario" v-model="elem.amount_product">

      <button v-if="index != 0" @click.prevent="removeProduct(index)" class=" btn-danger rounded col-1 ml-3 mt-2">
        <span class="v-select-btn"> - </span>
      </button>

    </div>
    <div v-if="!isValid" class="white">
      <small>Indica correttamente i prodotti</small>
    </div>
    <button @click.prevent="addProduct" class=" btn-success rounded mt-2">
      <h4 class="v-select-btn"> Aggiungi un altro prodotto </h4>
    </button>

  </div>

</template>

<script>
import { axiosMixin } from '../../mixins/axiosMixin';
import { innerWidthMixin } from '../../mixins/innerWidthMixin';
import 'vue-select/dist/vue-select.css';
export default {
  name: 'ProductInsertVueSelect',
  mixins: [axiosMixin, innerWidthMixin],
  props: {},

  data() {
    return {
      isValid: true,
      productIndex: 0,
      optionSelect: [],
      products: [
        {
          flag_product: 'product' + 0,

          name_product: '',
          quantity_product: 1,
          amount_product: 0.0,
        },
      ],
    };
  },
  methods: {
    handler() {
      // FOR THE PRODUCT CAL *= EG. FALSE * FALSE * TRUE = FALSE
      this.isValid = true;

      this.products.forEach((element) => {
        for (const key in element) {
          if (
            element[key] === null ||
            element[key] === undefined ||
            element[key] === '' ||
            element[key] <= 0 ||
            element[key] === '0'
          ) {
            this.isValid *= false;
          } else {
            this.isValid *= true;
          }
        }

        if (
          element.quantity_product > 99 ||
          element.quantity_product < 1 ||
          element.quantity_product.toString().length > 2
        ) {
          this.isValid *= false;
        }

        if (
          element.amount_product > 10 ||
          element.amount_product.toString().length > 4
        ) {
          this.isValid *= false;
        }
      });

      this.isValid = !!this.isValid;

      this.$emit('check', this.products, this.isValid);
    },
    addProduct() {
      this.productIndex = this.productIndex + 1;
      this.products.push({
        flag_product: 'product' + this.productIndex,

        name_product: '',
        quantity_product: 1,
        amount_product: 0.0,
      });
      this.$emit('check', this.products, false);
    },

    removeProduct(index) {
      this.productIndex = this.productIndex - 1;
      this.products.splice(index, 1);
    },

    async getProduct() {
      let data = {
        a: 'b',
      };
      let r = await axiosMixin.methods.axiosCall(
        'api/getProduct',
        data,
        'POST'
      );
      let message = r.data.message;
      let statusCode = r.data.statusCode;
      let description = r.data.message.description;

      try {
        if (r.status < parseInt(process.env.VUE_APP_CATCH)) {
          // then
          if (message) {
            let getProduct = message.select;
            let allProduct = message.all;

            this.optionSelect = getProduct;
          }
        }
      } catch (error) { }
    },
  },
  watch: {},
  computed: {},
  mounted() {
    this.getProduct();

    this.$emit('check', this.products, false);
  },
};
</script>

<style>
</style>