<template>
  <span
    :class="statusPlay.class"
    v-if="!practice"
  >{{
    statusPlay.status
  }}</span>

  <div v-else>
    <h5 class="Regular color-1">
      Pratica: <b class="color-7"> {{ practice }} </b>
    </h5>
    <h5 class="Regular color-1">
      Stato: <b class="color-7"> {{ statusPlay.status }}</b>
    </h5>
  </div>
</template>

<script>
  export default {
    name: 'receipt-status',
    props: ['practice', 'validation', 'result'],
    components: {},
    data() {
      return {};
    },
    methods: {},
    watch: {},
    computed: {
      statusPlay() {
        const instantWinCodes = [401];
        const premioCertoCodes = [400, 402, 403, 404];
        const loseCodes = [405];

        let typeOfPlay;

        if (instantWinCodes.includes(this.result)) {
          typeOfPlay = 'Boccali Kozel';
        }

        if (premioCertoCodes.includes(this.result)) {
          typeOfPlay = 'Boccali Kozel';
        }

        if (loseCodes.includes(this.result)) {
          return { status: 'Giocata non valida', class: 'red' };
        }

        switch (this.validation) {
          case 0:
            return {
              status: typeOfPlay + ' In fase di convalida',
              class: 'text-secondary',
            };
            break;
          case 1:
            return { status: typeOfPlay + ' Convalidata', class: 'text-success' };
            break;
          case 2:
            return {
              status: typeOfPlay + ' Non convalidata',
              class: 'red',
            };
            break;
          case 3:
            return { status: typeOfPlay + ' Errore dati', class: 'red' };
            break;
          case 4:
            return { status: typeOfPlay + ' Errore immagine', class: 'red' };
            break;

          default:
            return true;
            break;
        }
      },
    },
    mounted() {},
  };
</script>

<style>
</style>