<template>
	<div
		class="widthFull pt-5 loginBack"
		id="confirmA"
	>
		<div class="container pt-5">
			<loading v-if="loading" />
			<div class="row align-items-center ">
				<div class="col-10 col-md-6 mt-5 pt-5 mx-auto">
					<div class=" center pt-5 pb-5 h-100  pt-5 ">
						<h4
							v-model="result"
							type="text"
							class=" h1 pb-3 smMob black Bold theme-1 center mobTitleS"
						>{{result}}</h4>
						<div
							v-if="status"
							class="  pt-5 mb-5 pb-5"
						>
							<router-link
								class="btn btn-primary mb-5"
								to="/login"
							>
								Accedi
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import { axiosMixin } from "../../mixins/axiosMixin";
import { AUTH_REQUEST } from "actions/auth";

export default {
  name: "confirm",
  components: {},
  mixins: [axiosMixin],
  data() {
    return {
      result: "Verifica Token",
      status: false,
      loading: false,
    };
  },
  methods: {
    async confirm() {
      const token = this.$route.query.token;
      if (token) {
        this.loading = true;
        let data = { temp_token_player: token };

        let r = await axiosMixin.methods.axiosCall(
          "api/confirmAccount",
          data,
          "POST"
        );

        let message = r.data.message;
        let statusCode = r.data.statusCode;
        let description = r.data.message.description;

        this.loading = false;
        try {
          if (r.status < parseInt(process.env.VUE_APP_CATCH)) {
            // then
            if (message) {
              this.status = true;
              this.result = "Account attivato";
              if (
                process.env.VUE_APP_HAS_DIRECT_LOGIN_AFTER_CONFIRM_ACCOUNT ==
                "ON"
              ) {
                this.$store
                  .dispatch(AUTH_REQUEST, { message, go: true })
                  .then((resp) => {
                    const path = this.$route.path;

                    path == "/" ? this.scrollToTop() : this.$router.push("/");

                    this.gtagCheck(
                      "login",
                      username_player,
                      "success",
                      "success"
                    );
                  })
                  .catch((err) => {
                    // this.popupErrorCode(err.response.data.statusCode);
                  });
              }
            } else {
              this.result = process.env.VUE_APP_MSG_CONFIRM_TOKEN_NOT_FOUND;
            }
          }
        } catch (error) {
          this.result = process.env.VUE_APP_MSG_CONFIRM_TOKEN_NOT_FOUND;
        }
      }
    },
  },
  beforeMount() {
    this.confirm();
  },
};
</script>
