<template>
	<div
		class="widthFull awardBack"
		id="award"
	>

		<div class="container pt-5">

			<div class="text-center pt-5 pb-md-5 pb-lg-0"><img
					src="../../assets/images/come_partecipare_title.png"
					class="img-fluid  pb-5 mb-5 "
				/></div>

			<div class="row pt-5 pb-md-5 pb-lg-0">
				<div class="col-md-4 mb-5 mb-md-0 text-center">
					<img
						src="../../assets/images/numero_1.png"
						class="img-fluid number"
						alt=""
					>
				</div>
				<div class="col-md-4 mb-5 mb-md-0 text-center">
					<img
						src="../../assets/images/numero_2.png"
						class="img-fluid number"
						alt=""
					>
				</div>
				<div class="col-md-4 text-center">
					<img
						src="../../assets/images/numero_3.png"
						class="img-fluid "
						alt=""
					>
				</div>

			</div>

			<div
				class="row partecipa_btn_container"
				v-if="moment() < moment(finish)"
			>

				<div class="col-md-8 offset-md-2 back  line text-center">

					<router-link
						v-if="isAuthenticated && moment() < moment(finish)"
						class="btn btn-primary partecipa_btn  Regular uppercase"
						to="/play"
					>
						Partecipa Subito
					</router-link>
					<router-link
						v-else-if="moment() < moment(finish)"
						class="btn btn-primary partecipa_btn Regular uppercase"
						to="/login"
					>
						Partecipa Subito
					</router-link>

				</div>

				<!-- <div class="col-12 text-center partecipa-btn">
					<router-link
						v-if="isAuthenticated && moment() < moment(finish)"
						class="btn btn-primary btn-lg  Bold uppercase"
						to="/play"
					>
						Partecipa
					</router-link>
					<router-link
						v-else-if="moment() < moment(finish)"
						class="btn btn-primary btn-lg Bold uppercase"
						to="/login"
					>
						Partecipa
					</router-link>
					<h5
						v-else
						class="Bold th3"
						:class="{ 'mt-5': vw < 800 }"
					>
						IL CONCORSO È TERMINATO.
					</h5>
					<h5
						v-if="moment() > moment(finish) && !isAuthenticated"
						class="Regular small"
					>
						Puoi ancora accedere alla tua area personale
						<br />per verificare le tue partecipazioni.
					</h5>
					<router-link
						v-if="isAuthenticated && moment() > moment(finish)"
						class="btn btn-primary btn-lg Bold uppercase"
						to="/receipts"
					>
						La mia area
					</router-link>
					<router-link
						v-else-if="moment() > moment(finish)"
						class="btn btn-primary btn-lg Bold uppercase"
						to="/login"
					>
						Login
					</router-link>
				</div> -->

			</div>

			<div class="d-flex flex-column text-center  w-100 align-items-center py-5">
				<h1
					v-if="moment() > moment(finish)"
					class="Bold theme concorso-ended"
				>
					IL CONCORSO È TERMINATO!
				</h1>
				<h5
					v-if="moment() > moment(finish) && !isAuthenticated"
					class="Regular  theme "
				>
					Puoi ancora accedere alla tua area personale
					per verificare le tue partecipazioni.
				</h5>
				<router-link
					v-if="isAuthenticated && moment() > moment(finish)"
					class="btn btn-primary btn-lg Bold uppercase"
					to="/receipts"
				>
					La mia area
				</router-link>
				<router-link
					v-else-if="moment() > moment(finish)"
					class="btn btn-primary btn-lg Bold uppercase"
					to="/login"
				>
					Login
				</router-link>

			</div>

		</div>

		<!-- 		<div class="container  pt-md-0 mt-md-0 ">
			<div class="justify-content-center">

				<h1 class="text-center title-special mt-0 mt-sm-5 pb-2 pb-sm-0 pt-md-5 mb-xs-4">Come partecipare</h1>

				
				<div class="row center mb-5 mb-md-2">
					<div class="col-2 big_number text-right">1</div>
					<div class="col-7 d-flex flex-column justify-content-center">
						<h1 class="sub-title text-left">Acquista</h1>
						<h4
							:class="{'small' : vw<500}"
							class="Medium text-left"
						>

							<strong>dal 1 Marzo al 31 Marzo 2021 </strong>due confezioni a scelta tra
							<span
								@click="doAlert('integratori')"
								class=" link-green Bold"
							><strong> INTEGRATORI</strong></span> o <span
								href="#"
								@click="doAlert('anticaduta')"
								class=" link-green Bold"
							><strong> FIALE ANTICADUTA </strong></span> e conserva lo scontrino
						</h4>

					</div>
					<div class="col-3 d-flex align-items-center"><img
							src="../../assets/images/come_partecipare_1.png"
							class="img-fluid img "
						/></div>

				</div>

				
				<div class="row center mb-5 mb-md-2">
					<div class="col-3 d-flex align-items-center justify-content-end"><img
							src="../../assets/images/come_partecipare_2.png"
							class="img-fluid img-lt "
						/>
					</div>
					<div class="col-6 col-sm-7 d-flex flex-column justify-content-center">
						<h1 class="sub-title text-right">Registrati</h1>
						<h4
							:class="{'small' : vw<500}"
							class="Medium text-right"
						> <strong>Entro 5 giorni</strong> dall’acquisto <strong> carica lo scontrino e l’immagine della confezione </strong> su questo sito.<br>
							Ciascun richiedente potrà effettuare 1 sola richiesta di rimborso
						</h4>

					</div>
					<div class="col-3 col-sm-2 big_number text-left">2</div>

				</div>

				
				<div class="row center mb-5 mb-md-2">
					<div class="col-2 big_number text-right">3</div>
					<div class="col-7 d-flex flex-column justify-content-center">
						<h1 class="sub-title text-left">Ricevi il rimborso</h1>
						<h4
							:class="{'small' : vw<500}"
							class="Medium text-left"
						>
							del <strong> 50%</strong> sul secondo prodotto direttamente sul tuo Iban.<br />In caso di prodotti di prezzo differente il rimborso sarà effettuato sul meno caro.

						</h4>
						<small>
							( Il rimborso sarà erogato solo con bonifico bancario IBAN italiano, ricarica Postepay dotata di IBAN o carta ricaricabile dotata
							di IBAN italiano )
						</small>

					</div>
					<div class="col-3 d-flex align-items-center"><img
							src="../../assets/images/come_partecipare_3.png"
							class="img-fluid img-rt "
						/></div>

				</div>

			</div>
		</div>
 -->

		<!-- 	<div class="justify-content-center ">
			<div class="text-center partecipa-btn">
				<router-link
					v-if="isAuthenticated && moment() < moment(finish)"
					class="btn btn-primary btn-lg  Bold uppercase"
					to="/play"
				>
					Partecipa
				</router-link>
				<router-link
					v-else-if="moment() < moment(finish)"
					class="btn btn-primary btn-lg Bold uppercase"
					to="/login"
				>
					Partecipa
				</router-link>
				<h5
					v-else
					class="Bold th3"
					:class="{ 'mt-5': vw < 800 }"
				>
					IL CONCORSO È TERMINATO.
				</h5>
				<h5
					v-if="moment() > moment(finish) && !isAuthenticated"
					class="Regular small"
				>
					Puoi ancora accedere alla tua area personale
					<br />per verificare le tue partecipazioni.
				</h5>
				<router-link
					v-if="isAuthenticated && moment() > moment(finish)"
					class="btn btn-primary btn-lg Bold uppercase"
					to="/receipts"
				>
					La mia area
				</router-link>
				<router-link
					v-else-if="moment() > moment(finish)"
					class="btn btn-primary btn-lg Bold uppercase"
					to="/login"
				>
					Login
				</router-link>
			</div>

		</div> -->
	</div>
</template>


<script>
import { mapGetters } from "vuex";
const moment = require("moment");

import { sharedEnvMixin } from "../../mixins/sharedEnvMixin";
import { innerWidthMixin } from "../../mixins/innerWidthMixin";
import { popupConfigurationMixin } from "../../mixins/popupConfigurationMixin";

export default {
  name: "award",

  mixins: [sharedEnvMixin, innerWidthMixin, popupConfigurationMixin],
  data() {
    return {
      oldPresent: false,
      moment: moment,
    };
  },
  methods: {
    showPopUp() {
      this.popupErrorText(
        "",
        "",
        "<h4 class='Regular p-4'>Con il numero di cellulare indicato in fase di registrazione potrai partecipare anche tramite <b>WhatsApp®</b> inviando la tua prova d'acquisto al numero <b>3917054053</b></h4>",
        "",
        ""
      );
    },
  },

  mounted: function () {
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "authStatus",
      "getPromotions",
      "getOldPromotions",
    ]),
    timeStore() {
      return this.$timestore.now;
    },
  },
};
</script>

<style lang="css" scoped>
	/* .concorso-ended {
																																	position: relative;
																																	top: -150px;
																																	display: none;
																																} */

	.partecipa_btn_container {
		margin-top: 220px;
	}

	.line {
		position: relative;
		height: 15px;
		bottom: 100px !important;
	}

	.partecipa_btn {
		position: relative;
		top: -28px;
		left: -16px;
	}

	.over {
		overflow: auto;
		max-height: 200px;
	}

	.sub-title {
		font-size: 30px;
	}

	.img-fluid {
		max-height: 510px !important;
	}

	.btn-group-lg > .btn,
	.btn-lg {
		padding: 0.8rem 3rem;
		font-size: 26px;
		line-height: 1;
		border-radius: 0.3rem;
	}

	@media screen and (max-width: 1200px) {
		.img-fluid {
			max-height: 400px !important;
		}
	}

	@media screen and (max-width: 767px) {
	}
	@media screen and (max-width: 499px) {
		.line {
			bottom: 100px !important;
		}

		.btn {
			left: -10px !important;
		}
	}
</style>

