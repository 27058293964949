<template>
	<div class="widthFull relative min-height-calc--footer customBg--repeat">
		<loading v-if="loadingState" />

		<div class="container-fluid">
			<div class="row pb-0 center">
				<div class="col-12 pt-2">
					<h3 class="  Bold py-2 color-2 pt-5">
						informativa cookie </h3>
				</div>
			</div>
		</div>
		<div class="container mt-5 regulationContainer color-2 pb-5">

			<h4 class="center">

			</h4>
			La società Heineken Italia S.p.A., società unipersonale, soggetta a direzione e coordinamento di Heineken N. V.
			ai sensi dell’articolo 2497 bis del codice civile, con sede legale in Pollein (AO), località Autoporto n. 11 e
			sede amministrativa in Sesto San Giovanni (MI), Viale Edison n. 110, Capitale sociale Euro 47.320.000,00 i.v.,
			Codice Fiscale e nr. iscrizione al Registro delle Imprese di Aosta 00869580159, Partita IVA 00610140071,
			(di seguito, “Heineken” o anche la “Società”), fornisce di seguito le informazioni relative al funzionamento dei cookie all’interno
			del sito www.inostribicchieri.it (di seguito anche solo il “Sito”), anche ai sensi del Provvedimento del Garante della Privacy
			“Individuazione delle modalità semplificate per l’informativa e l’acquisizione del consenso per l’uso dei cookie” di data 8 maggio 2014
			(di seguito anche solo il “Provvedimento”). L’informativa che segue è resa per il Sito della Società e non anche per altri siti web di
			soggetti terzi eventualmente consultati dall'Utente tramite link.

			</br>Per ogni altra informazione sulla politica adottata dalla Società nella gestione del Sito, si rinvia all’informativa privacy.

			<br /><br />
			A) FINALITÀ DEL TRATTAMENTO
			<br /><br />

			La Società può raccogliere e trattare, nel corso della navigazione sul Sito, alcune informazioni, anche in modo automatico come attraverso l’installazione di cookie nel client o nel dispositivo dell’Utente, in primo luogo per fornire e completare i vari servizi e funzionalità offerte dal Sito, compresa la gestione delle interazioni con l’Utente, il miglioramento della navigazione e della fruizione del Sito, l’invio di segnalazioni, notifiche e comunicazioni anche a carattere commerciale in linea con le esigenze e le aspettative dell’Utente, con l’obiettivo di migliorare la sua esperienza nella navigazione.
			<br />Allo stesso modo, la Società potrebbe trattare le suddette informazioni per misurare l’efficacia delle campagne pubblicitarie ovvero per tenere traccia delle visite dell’Utente al Sito stesso, condividendo tali informazioni con terze parti che potrebbero utilizzare i loro cookie per raccogliere informazioni sulle attività dell’Utente all’interno del Sito stesso.
			<br />Di seguito si riportano le relative modalità di trattamento.

			<br /><br />
			B) DATI DI NAVIGAZIONE
			<br /><br />

			I sistemi informatici e le procedure software preposte al funzionamento del Sito acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso di protocolli di comunicazione di Internet.
			<br />Si tratta di informazioni che non sono raccolte per essere associate a persone identificate, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti.
			<br />In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al Sito, gli indirizzi in notazione URI (Uniform Resourse Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato di risposta data dal server (buon fine, errore etc.) ed altri parametri relativi al sistema operativo e all'ambiente informatico dell’Utente.
			<br />Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del Sito e per controllarne il corretto funzionamento e vengono cancellati immediatamente dopo l’elaborazione.
			<br />I dati potrebbero essere utilizzati per l’accertamento di responsabilità in caso di ipotetici reati informatici ai danni del Sito: salvo questa eventualità, i dati stessi sono conservati per i tempi definiti dalla normativa legale di riferimento per il tempo strettamente necessario a fornire all’Utente il servizio richiesto a garantire la trasmissione della comunicazione.

			<br /><br />
			C) COOKIE
			<br /><br />

			I cookie sono brevi frammenti di testo (lettere e/o numeri) che permettono al server web di memorizzare sul client (il browser) informazioni da riutilizzare nel corso della medesima visita al sito (cookie di sessione) o in seguito, anche a distanza di giorni (cookie persistenti). I cookie vengono memorizzati, in base alle preferenze dell'Utente, dal singolo browser sullo specifico dispositivo utilizzato (computer, tablet, smartphone).

			<br />I cookie sono utili perché consentono a un sito web di riconoscere il dispositivo dell’Utente. Essi hanno diverse finalità come, per esempio, consentire di navigare efficientemente tra le pagine, ricordare i siti preferiti e, in generale, migliorare l'esperienza di navigazione. Contribuiscono anche a garantire che i contenuti pubblicitari visualizzati online siano più mirati all'Utente e ai suoi interessi.

			<br />Le informazioni registrate sul dispositivo dell’Utente possono essere riutilizzate nel corso della medesima visita sul Sito (cookie di sessione) o in seguito, anche a distanza di giorni (cookie persistenti). I cookie vengono memorizzati, in base alle preferenze dell’Utente, dal singolo browser sullo specifico dispositivo utilizzato (computer, tablet, smartphone).

			<br />Possono, inoltre, essere utilizzate anche tecnologie similari, come web beacon, GIF trasparenti o altre varie forme di storage locale, per raccogliere informazioni sul comportamento dell’Utente e sull’utilizzo dei servizi. Il termine cookie indica, ai fini di questa informativa, sia i cookie sia le altre tecnologie similari.

			<br />In base alla funzione e alla finalità di utilizzo, i cookie possono classificarsi in cookie tecnici, cookie analitici cookie di profilazione. Tutti i cookie si distinguono in cookie di prima parte, che sono installati direttamente dal gestore del Sito e in cookie di terze parti, in quanto installati, attraverso il Sito, da soggetti terzi diversi. Il presente Sito fa esclusivamente uso di cookie tecnici e di cookie di profilazione di terze parti.

			<br />In base alle caratteristiche e all'utilizzo dei cookie possiamo distinguere diverse categorie:
			<br />1. Cookie tecnici.
			<br />I cookie tecnici sono quelli utilizzati al solo fine di "effettuare la trasmissione di una comunicazione su una rete di comunicazione elettronica, o nella misura strettamente necessaria al fornitore di un servizio della società dell'informazione esplicitamente richiesto dall'abbonato o dall'Utente a erogare tale servizio"(cfr. art. 122, comma 1, del D. Lgs. 196/2003, come novellato dal D. Lgs. 101/2018, il “Codice”).

			<br />Tra detti cookie tecnici, segnaliamo i cookie essenziali, detti anche “strictly necessary” (strettamente necessari), che abilitano funzioni, senza le quali non sarebbe possibile utilizzare appieno il Sito.

			<br />Questi cookie vengono impiegati esclusivamente dalla Società e sono quindi cookie di prima parte.
			<br />Vengono salvati sul computer dell'Utente solo durante la sessione attuale del browser. Un cookie di questo tipo viene inoltre utilizzato per memorizzare la decisione dell'Utente sull'utilizzo di cookie sul Sito.
			<br />Essi non vengono utilizzati per scopi ulteriori e sono normalmente installati direttamente dal titolare o gestore del sito web. Possono essere suddivisi in cookie di navigazione o di sessione, che garantiscono la normale navigazione e fruizione del sito web; cookie analytics, assimilati ai cookie tecnici laddove utilizzati direttamente dal gestore del sito per raccogliere informazioni, in forma aggregata, sul numero degli utenti e su come questi visitano il sito stesso; cookie di funzionalità, che permettono all'utente la navigazione in funzione di una serie di criteri selezionati al fine di migliorare il servizio reso allo stesso.
			<br />Per l'installazione di tali cookie non è richiesto il preventivo consenso degli utenti.
			<br />Questi cookie possono essere disattivati e/o eliminati attraverso le impostazioni del browser. Tutti i browser moderni consentono di modificare le impostazioni dei cookies. Di solito è possibile trovare queste impostazioni nelle "opzioni" o nel menu “preferenze” del browser dell’Utente. Per comprendere queste impostazioni, i seguenti links possono essere utili. Oppure è possibile utilizzare l'opzione “Help” nel browser per maggiori informazioni.

			<br /><a
				class="link underline ml-3"
				href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-10"
				target="_blank"
			>Impostazioni dei cookie in Internet Explorer</a>
			<br /><a
				class="link underline ml-3"
				href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies"
				target="_blank"
			>Impostazioni dei cookie in Firefox</a>
			<br /><a
				class="link underline ml-3"
				href="https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666"
				target="_blank"
			>Impostazioni dei cookie in Chrome</a>
			<br /><a
				class="link underline ml-3"
				href="https://support.apple.com/kb/PH5042?locale=en_US"
				target="_blank"
			>Impostazioni dei cookie in Safari</a> e
			<a
				class="link underline"
				href="https://support.apple.com/en-us/HT201265"
				target="_blank"
			>iOS</a>
			<br />In caso di blocco dell'uso dei cookie, verrà limitato il servizio offerto all’Utente tramite il Sito, influenzando in tale modo l’esperienza di utilizzo del Sito stesso </a>

			<br />Di seguito, per ciascun cookie tecnico utilizzato riportiamo: il nome, lo scopo di utilizzo ed i tempi di conservazione.

			<table
				class="table-bordered mt-3 mb-3"
				width="100%"
			>
				<tbody>
					<tr>
						<td width="33%">
							<p><strong><em>&nbsp; </em></strong><strong>Nome cookie </strong></p>
						</td>
						<td width="33%">
							<p><strong>Scopo</strong></p>
						</td>
						<td width="33%">
							<p><strong>Durata</strong></p>
						</td>
					</tr>
					<tr>
						<td width="33%">
							<p>[*]cookiedismissed</p>
						</td>
						<td width="33%">
							<p>Memorizza in forma anonima le preferenze di navigazione del visitatore del sito (ad esempio la scelta di disabilitare il cookie di Google Analytics). Non &egrave; possibile disattivarlo. Negare il consenso a questo cookie impedisce la corretta navigazione del sito.</p>
							<p>&nbsp;</p>
						</td>
						<td width="33%">
							<p>automaticamente al termine della navigazione</p>
						</td>
					</tr>
					<tr>
						<td width="33%">
							<p>[*]ageverification</p>
						</td>
						<td width="33%">
							<p>Abilita alla navigazione il visitatore in base all&rsquo;et&agrave; dichiarata. Non &egrave; possibile disattivarlo e viene eliminato automaticamente al termine della navigazione. Negare il consenso a questo cookie impedisce la navigazione del sito.</p>
						</td>
						<td width="33%">
							<p>automaticamente al termine della navigazione</p>
						</td>
					</tr>
				</tbody>
			</table>

			<br /><br />
			2. Cookie di profilazione
			<br /><br />
			I cookie di profilazione sono volti a creare profili relativi all’utente e sono utilizzati dal titolare del sito al fine di inviare pubblicità, promozioni e offerte commerciali in linea con le preferenze manifestate dall’utente durante la navigazione in rete. Tali cookie vengono installati o attivati solo a seguito del consenso espresso dall’utente la prima volta che visita il Sito. Il consenso può essere espresso in maniera generale, cliccando sul tasto “Accetto” presente nel banner di informativa breve; oppure può essere fornito o negato in maniera selettiva, secondo le modalità di seguito indicate. Di questo consenso viene tenuta traccia in occasione delle visite successive. Tuttavia, l’utente ha sempre la possibilità di revocare in tutto o in parte il consenso già espresso.

			<br />I cookie di profilazione possono comprendere diverse categorie di cookie, tra cui i cookie di profilazione pubblicitaria, i cookie di re-targeting, i cookie di social network e i cookie analitici che non si limitano ad analisi statistiche aggregate.

			<br />(i) Cookie di profilazione pubblicitaria: creano profili relativi all’utente per fare visualizzare messaggi pubblicitari in linea con le preferenze manifestate dall’utente durante la navigazione su Internet; (ii) Cookie di re-targeting: inviano pubblicità per proporre prodotti simili a quelli sui quali l’utente ha manifestato interesse o consentono di misurare l’efficacia di una campagna pubblicitaria propria o di terzi; (iii) Cookie di social network: il sito consente ad alcuni social network (ad esempio, Facebook, Twitter) l'installazione di propri cookie utilizzando i loro plug-in. Questi cookie sono gestiti direttamente da tali terze parti e possono essere utilizzati anche al fine di fare visualizzare su altri siti messaggi pubblicitari in linea con le preferenze dell’utente; (iv) Cookie analitici: tengono traccia delle scelte operate dall’utente sul sito e della sua navigazione per effettuare analisi statistiche da parte del titolare del sito e di terzi, tenendo traccia delle preferenze e del comportamento online dell’utente al fine di proporre messaggi pubblicitari personalizzati.

			<br />Vengono inoltre categorizzati i cookie a seconda del soggetto che opera in qualità di titolare del trattamento dei dati personali raccolti dal cookie, distinguendo fra cookie di prima parte e cookie di terza parte.

			<br /><br />
			a. Cookie di prima parte
			<br /><br />

			Sono i cookie gestiti dal titolare del sito. Per questi cookie, l’obbligo dell’informativa spetta al titolare del sito.
			<br />Spetta anche a quest’ultimo l’obbligo di indicare le modalità per l’eventuale blocco del cookie seguendo i link riportati nella tabella che segue.

			</tbody>
			</table>

			<br /><br />
			b. Cookie di terze parti
			<br /><br />
			Sono i cookie gestiti da un soggetto terzo diverso dal titolare del sito. Per questi cookie, l’obbligo dell’informativa e dell’indicazione delle modalità per l’eventuale blocco del cookie spetta alla terza parte, mentre al titolare del sito è fatto obbligo di inserire nel sito il link al sito della terza parte ove tali elementi sono disponibili.

			<br />Nel corso della navigazione l’Utente potrebbe ricevere sul suo terminale anche cookie di siti o di web server diversi (c.d. cookie di "terze parti"): ciò accade perché sul Sito possono essere presenti elementi come, ad esempio, immagini, mappe, suoni, specifici link a pagine web di altri domini che risiedono su server diversi da quello sul quale si trova la pagina richiesta. In altre parole, detti cookie sono impostati direttamente da gestori di siti web o server diversi dal Sito stesso. Questi terzi potrebbero in teoria impostare cookie mentre visitate il Sito e ottenere così informazioni relative al fatto che avete visitato un sito web della Società.

			<br />Troverete maggiori informazioni sull’impiego dei cookie di terze parti accedendo al link indicato nella tabella sottostante. Se l’utente decide di non concedere l’autorizzazione per l’impiego di cookie di terze parti accedendo ai relativi siti delle terze parti, verranno installati solo cookie di prima parte.

			<br />Di seguito, per ciascun cookie di terze parti presente sul Sito, riportiamo il nome, lo scopo, il nome della terza parte che conserva le informazioni ed il relativo link al sito web della terza parte.

			<table
				class="table-bordered mt-3 mb-3"
				width="100%"
			>
				<tbody>
					<tr>
						<td width="141">
							<p><strong>Nome</strong></p>
							<p><strong><em>Cookie </em></strong></p>
						</td>
						<td width="212">
							<p><strong>Scopo</strong></p>
						</td>
						<td width="101">
							<p><strong>Terza parte che conserva le informazioni dell&rsquo;Utente</strong></p>
						</td>
						<td width="168">
							<p><strong>Link al sito ove visionare l&rsquo;informativa privacy resa dalla terza parte e il relativo modulo di consenso</strong></p>
						</td>
					</tr>
					<tr>
						<td width="141">
							<p>_ga, _gid</p>
						</td>
						<td width="212">
							<p>Raccoglie statistiche sull&rsquo;utilizzo e sulle performance del sito.</p>
						</td>
						<td width="101">
							<p><strong>Google</strong></p>
						</td>
						<td width="168">
							<p><a
									class="link underline"
									href="https://www.google.com/intl/en/policies/privacy/"
									target="_blank"
								>https://www.google.com/intl/en/policies/privacy/</a></p>
						</td>
					</tr>
					<tr>
						<td width="141">
							<p>DCM &ndash; Home Page</p>
						</td>
						<td width="212">
							<p>DoubleClick utilizza i cookie per migliorare la pubblicit&agrave;. Alcuni impieghi comuni dei cookie includono la scelta del target della pubblicit&agrave; in base a ci&ograve; che &egrave; pertinente per un utente, il miglioramento dei rapporti sul rendimento delle campagne e la possibilit&agrave; di evitare di mostrare annunci che l'utente ha gi&agrave; visto.</p>
						</td>
						<td width="101">
							<p><strong>Google</strong></p>
						</td>
						<td width="168">
							<p><a
									class="link underline"
									href="https://support.google.com/dfp_premium/answer/2839090?hl=it"
									target="_blank"
								>https://support.google.com/dfp_premium/answer/2839090?hl=it</a></p>
						</td>
					</tr>
					<tr>
						<td width="141">
							<p>FB Conversion pixel</p>
						</td>
						<td width="212">
							<p>Il pixel di Facebook &egrave; uno strumento per la raccolta di dati statistici che ti consente di misurare l'efficacia della tua pubblicit&agrave; comprendendo le azioni che le persone eseguono sul tuo sito Web.</p>
						</td>
						<td width="101">
							<p><strong>Facebook</strong></p>
						</td>
						<td width="168">
							<p><a
									class="link underline"
									href="https://www.facebook.com/about/privacy/"
									target="_blank"
								>https://www.facebook.com/about/privacy/</a></p>
						</td>
					</tr>
				</tbody>
			</table>

			<br /><br />
			C) DATI DI CONTATTO DEL TITOLARE, DIRITTI IN MATERIA DI PROTEZIONE DEI DATI E DIRITTO DI AVANZARE RECLAMO DINNANZI ALL’AUTORITÀ DI CONTROLLO. Si rimanda
			<router-link
				class="link underline"
				to="/privacy"
			>
				all’informativa privacy.
			</router-link>
		</div>
	</div>
</template>
<script>
	import { mapState } from 'vuex';
	import {
		USER_REQUEST,
		ACCEPT_RELAY_COOKIES,
		ACCEPT_SALESFORCE_COOKIES,
	} from 'actions/user';
	import { mapGetters } from 'vuex';

	export default {
		name: 'informativaPrivacy',
		data() {
			return {
				loadingState: '',
				userToken: '',
			};
		},
		methods: {
			scrollToTop: function () {
				var _vue = this;
				setTimeout(function () {
					_vue.$scrollTo('.main', 700, { offset: -220 });
				}, 100);
			},
		},
		watch: {},
		mounted: function () {
			this.scrollToTop();
		},
		computed: {
			...mapGetters([
				'getProfile',
				'isAuthenticated',
				'isProfileLoaded',
				'acceptCookies',
				'checkAge',
				'acceptSalesForce',
				'acceptRelay42',
			]),
		},
	};
</script>


