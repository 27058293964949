 <template>
  <div
		class="widthFull loginBack h-100"
		id="firstElem"
		style="min-height: 85vh;"
	>
		<div class="track"></div>
		<div class="container">
			<div class="row justify-content-center">

				<div class="container-fluid pt-5 pb-5">
					<div class="row pt-5 pb-3 center">
						<!--<h1 class="Regular white mainTitleSize widthFull center uppercase" >
     					<b>OPS!</b>
     				</h1>
          -->
					</div>
				</div>

				<h2 class="Bold uppercase uppercase center white widthFull  mt-5">

					Torna a trovarci tra:

				</h2>

				<div class="col-md-7 pb-2 mb-5 pt-5 center custForm">

					<countdown :time="countdown">

						<template slot-scope="props">

							<div class="row center justify-content-center pb-5 pt-1">

								<div class="inlineTimeElements pl-0 pr-0 ml-4 mr-4 text-center relative">

									<div class="timeElement Regular uppercaseFont"> {{ props.days }}

									</div>
									<p
										v-if="props.days == 1"
										class="classic Regular uppercase mt-2 mb-0 theme-1"
									>giorno</p>
									<p
										v-else
										class="classic Regular uppercase mt-2 mb-0 theme-1"
									>giorni</p>

								</div>

								<div class="inlineTimeElements pl-0 pr-0 ml-4 mr-4  text-center relative">

									<div class="timeElement Regular uppercaseFont "> {{ props.hours }}

									</div>
									<p
										v-if="props.hours == 1"
										class="classic Regular uppercase mt-2 mb-0 theme-1"
									>ora</p>
									<p
										v-else
										class="classic Regular uppercase mt-2 mb-0 theme-1"
									>ore</p>

								</div>

								<div class="inlineTimeElements pl-0 pr-0 ml-4 mr-4 text-center relative">

									<div class="timeElement Regular uppercaseFont"> {{ props.minutes }}
									</div>
									<p
										v-if="props.minutes == 1"
										class="classic Regular uppercase mt-2 mb-0 theme-1"
									>minuto</p>
									<p
										v-else
										class="classic Regular uppercase mt-2 mb-0 theme-1"
									>minuti</p>

								</div>

								<div class="inlineTimeElements pl-0 pr-0 ml-4 mr-4 text-center relative">

									<div class="timeElement Regular uppercaseFont"> {{ props.seconds }}

									</div>
									<p
										v-if="props.seconds == 1"
										class="classic Regular uppercase mt-2 mb-0 theme-1"
									>secondo</p>
									<p
										v-else
										class="classic Regular uppercase mt-2 mb-0 theme-1"
									>secondi</p>

								</div>

							</div>

						</template>

					</countdown>

				</div>

			</div>

		</div>
	</div>
</template>

<script>
const moment = require("moment");

import { sharedEnvMixin } from "../../mixins/sharedEnvMixin";

export default {
  mixins: [sharedEnvMixin],
  name: "NotActive",
  data() {
    return {
      time: false,
      countdown: 0,
      datenow: "",
    };
  },
  methods: {
    timeReactive() {
      let self = this;
      if (this.timeStore >= moment(process.env.VUE_APP_START)) {
        window.location.replace("/");
      }
    },
  },
  created: function () {
    setInterval(this.timeReactive, 1000);
    let now = this.timeStore;
    let then = moment(process.env.VUE_APP_START);
    let dif = then.diff(now);
    this.countdown = dif;
  },
  mounted() {},
  computed: {
    timeStore() {
      return this.$timestore.now;
    },
  },
};
</script>

<style lang="scss" scoped>
	$phone: 667px;
	$tablet: 1024px;
	$desktop: 1324px;
	$lgscreen: 1524px;

	.headerLogo {
		max-width: 115px;

		@media screen and (max-width: $phone) {
			max-width: 70px;
		}
		@media screen and (min-width: $phone) and (max-width: $tablet) {
			max-width: 100px;
		}
		@media screen and (min-width: $tablet) and (max-width: $desktop) {
		}
		@media screen and (min-width: $desktop) and (max-width: $lgscreen) {
		}
		@media screen and (min-width: $lgscreen) {
		}
	}

	.coca-logo {
		top: -5px;
		height: 40px;
		position: relative;

		@media screen and (max-width: $phone) {
			height: 20px;
		}
		@media screen and (min-width: $phone) and (max-width: $tablet) {
			height: 23px;
		}
	}

	.ce-logo {
		top: -15px;
		height: 65px;
		position: relative;

		@media screen and (max-width: $phone) {
			top: -7px;
			height: 37px;
		}
		@media screen and (min-width: $phone) and (max-width: $tablet) {
			top: -7px;
			height: 37px;
		}
	}

	.mainFont {
		font-size: 14px;
	}

	.big-red {
		font-family: 'Gotham Rounded', sans-serif;
		font-weight: Medium uppercase;
		font-size: 26px;
		color: #e30613;

		@media screen and (max-width: $phone) {
			font-size: 14px;
		}
		@media screen and (min-width: $phone) and (max-width: $tablet) {
			font-size: 16px;
		}
	}

	.main {
		width: 100%;
		height: 100%;
		position: relative;

		background-position: 0% 50% !important;
		background-size: cover;
		background-repeat: no-repeat;
	}
</style>

