<template>
	<div class="">
		<loading v-if="loadingState || !progress" />
		<div class="col-12">
			<div
				v-if="progress == 'START'"
				class="row justify-content-center pb-5"
			>
				<div class="col-12 col-md-10 col-lg-9">
					<!-- 1 spedizione -->

					<form
						class="widthFull mb-5"
						autocomplete="qwerqrt"
						@submit.prevent="onConplete"
					>
						<div class="row justify-content-center mb-3">
							<div class="col-12">
								<div class="row">
									<div class="col-3 pl-4">
										<hr />
									</div>
									<div class="col-6">
										<h2 class="text-center Bold white">COMPILA IL MODULO</h2>
									</div>
									<div class="col-3 pr-4">
										<hr />
									</div>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6 offset-md-3 center">
								<h4 class="h4 Regular mb-5 mt-3 white">
									Tutti i campi sono obbligatori
								</h4>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6 mb-5 mb-md-3">
								<div class="group">
									<label
										class="niceInputLabel Regular theme"
										for="xuserx"
									>Nome
									</label>
									<input
										type="text"
										autocomplete="randomq"
										class="form-control"
										v-model="xuserx"
										id="xuserx"
										name="xuserx"
										data-vv-as="Nome"
										v-validate="{ required: true, max: 128, min: 2 }"
										:class="{
                      'is-invalid': errors.has('xuserx'),
                      valid: !!xuserx,
                    }"
									/>
									<span class="highlight"></span>
									<span class="bar"></span>

									<div
										v-if="errors.has('xuserx')"
										class="invalid-feedback"
									>
										{{ errors.first("xuserx") }}
									</div>
								</div>
							</div>

							<div class="col-md-6 mb-5 mb-md-3">
								<div class="group">
									<label
										class="niceInputLabel Regular theme"
										for="lastnamex"
									>Cognome
									</label>
									<input
										type="text"
										autocomplete="offw"
										class="form-control"
										v-model="lastnamex"
										id="lastnamex"
										name="lastnamex"
										data-vv-as="Cognome"
										v-validate="{ required: true, max: 128, min: 2 }"
										:class="{
                      'is-invalid': errors.has('lastnamex'),
                      valid: !!lastnamex,
                    }"
									/>
									<span class="highlight"></span>
									<span class="bar"></span>

									<div
										v-if="errors.has('lastnamex')"
										class="invalid-feedback"
									>
										{{ errors.first("lastnamex") }}
									</div>
								</div>
							</div>
						</div>

						<!-- <div class="col-md-4 mb-5 mb-md-3">
										<div class="group">
											<label
												class="niceInputLabel Regular theme"
												for="birthdayR"
											>Data di nascita </label>
											<datepicker
												:language="it"
												v-model="birthdayR"
												:open-date="openDate"
												:initialView="'year'"
												:full-month-name="true"
												:format="'dd MMMM yyyy'"
												:monday-first="true"
												:disabledDates="disabledDatesStart"
												name="birthdayR"
												input-class="form-control dateInput"
											></datepicker>
											<input
												type="text"
												v-show="false"
												v-model="birthdayR"
												class="form-control"
												id="birthdayR"
												name="birthdayR"
												data-vv-as="Data di nascita"
												v-validate="{ required: true }"
												:class="{
                          'is-invalid': errors.has('birthdayR'),
                          valid: !!birthdayR,
                        }"
											/>
											<span class="highlight"></span>
											<span class="bar"></span>
											<div
												v-if="errors.has('birthdayR')"
												class="invalid-feedback"
											>
												{{ errors.first("birthdayR") }}
											</div>
										</div>
									</div>
									<div class="col-md-2 mt-md-2 pt-lg-2 mb-5 mb-md-0">
										<label
											class="niceInputLabel Regular theme mt-lg-2"
											for="gender"
										>Sesso</label>
										<select
											class="form-control-2"
											v-model="gender"
										>
											<option
												v-for="(option, key) in genderSel"
												:key="key"
												:value="option.value"
											>
												{{ option.text }}
											</option>
										</select>
										<a
											v-if="genderError"
											class="inv"
										>Il campo sesso è richiesto</a>
									</div> -->

						<div class="row">
							<div class="col-md-6 mb-5 mb-md-0">
								<div class="group">
									<label
										class="niceInputLabel Regular theme"
										for="phone"
									>Telefono
									</label>
									<input
										type="text"
										autocomplete="randome"
										class="form-control"
										v-model="phone"
										id="phone"
										name="phone"
										data-vv-as="Telefono"
										v-validate="{
                      required: true,
                      max: 11,
                      min: 8,
                      regex: /^[0,3]+/,
                    }"
										:class="{
                      'is-invalid': errors.has('phone'),
                      valid: !!phone,
                    }"
									/>
									<span class="highlight"></span>
									<span class="bar"></span>
									<div
										v-if="errors.has('phone')"
										class="invalid-feedback"
									>
										{{ errors.first("phone") }}
									</div>
								</div>
							</div>

							<div class="col-md-6 mb-5 mb-md-3">
								<div class="group">
									<label
										class="niceInputLabel Regular theme"
										for="email"
									>Email
									</label>
									<input
										type="text"
										v-model="email"
										class="form-control"
										id="email"
										name="email"
										@blur="checkIfExistEmail"
										ref="email"
										data-vv-as="Email"
										v-validate="{
                      required: true,
                      email: true,
                      temporaryEmail: true,
                      plusIssueEmail: true,
                      max: 56,
                    }"
										:class="{
                      'is-invalid': errors.has('email') || existEmail,
                      valid: !!email,
                    }"
									/>
									<span class="highlight"></span>
									<span class="bar"></span>

									<div
										v-if="existEmail"
										class="invalid-feedback"
										style="display: block"
									>
										<span v-if="existEmailSocial">Email digitato già presente. Sei registrato con:
											<strong class="text-capitalize">{{
                        existEmailSocial
                      }}</strong></span>
										<span v-else>Email giá utilizzata</span>
									</div>
									<div
										v-if="errors.has('email')"
										class="invalid-feedback"
									>
										{{ errors.first("email") }}
									</div>
								</div>
							</div>
						</div>

						<div
							class="row justify-content-center"
							:class="{ marginIban: vw < 1540 }"
						>
							<!--<div class="col-md-3 mb-3">
                        <div class="group">
                          <select
                            v-model="province"
                            autocomplete="randomy"
                            id="province"
                            name="province"
                            class="form-control"
                            data-vv-as="Provincia"
                            v-validate="{ required: true }"
                            :class="{
                              'is-invalid': errors.has('province'),
                              valid: !!province,
                            }"
                          >
                            <option
                              v-if="!shipData.province"
                              disabled
                              value=""
                              selected="selected"
                            ></option>
                            <option
                              v-else
                              :value="shipData.province"
                              selected="selected"
                            >
                              {{ shipData.province }}
                            </option>
                            <option
                              v-for="province in provinces"
                              :key="province.sigla"
                              v-bind:value="province.sigla"
                              :selected="province.sigla == shipData.province"
                            >
                              {{ province.sigla }} - {{ province.provincia }}
                            </option>
                          </select>
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label
                            class="niceInputLabel Regular theme"
                            for="province"
                            >Provincia </label
                          >
                          <div v-if="provincesState">
                            <font-awesome-icon
                              class="loadSpinner"
                              id="loadProvince"
                              icon="spinner"
                              size="1x"
                              pulse
                            />
                          </div>
                          <div
                            v-if="errors.has('province')"
                            class="invalid-feedback"
                          >
                            {{ errors.first("province") }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 mb-3">
                        <div class="group">
                          <select
                            class="form-control"
                            autocomplete="randomu"
                            v-model="city"
                            v-on:click="checkProvince"
                            id="city"
                            name="city"
                            data-vv-as="Località"
                            v-validate="{ required: true }"
                            :disabled="citiesState"
                            :class="{
                              'is-invalid': errors.has('city'),
                              valid: !!city,
                            }"
                          >
                            <option
                              v-if="!shipData.city"
                              disabled
                              value=""
                              selected="selected"
                            ></option>
                            <option
                              else
                              :value="{ city: shipData.city }"
                              selected="selected"
                            >
                              {{ shipData.city }}
                            </option>
                            <option
                              v-for="city in cities"
                              :key="city.comune"
                              v-bind:value="{
                                cityistat: city.istat,
                                city: city.comune,
                              }"
                              :selected="city.comune == shipData.city"
                            >
                              {{ city.comune }}
                            </option>
                          </select>
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label class="niceInputLabel Regular theme" for="city"
                            >Località *<span v-if="cityError" class="formError"
                              >Prima scegli Provincia</span
                            ></label
                          >
                          <div v-if="citiesState">
                            <font-awesome-icon
                              class="loadSpinner"
                              id="loadCity"
                              icon="spinner"
                              size="1x"
                              pulse
                            />
                          </div>
                          <div
                            v-if="errors.has('city')"
                            class="invalid-feedback"
                          >
                            {{ errors.first("city") }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 mb-3">
                        <div class="group">
                          <select
                            class="form-control"
                            autocomplete="randomi"
                            v-model="zip"
                            v-on:click="checkZip"
                            id="zip"
                            name="zip"
                            data-vv-as="Cap"
                            v-validate="{ required: true }"
                            :disabled="zipsState"
                            :class="{
                              'is-invalid': errors.has('zip'),
                              valid: !!zip,
                            }"
                          >
                            <option
                              v-if="!shipData.zip"
                              value=""
                              disabled
                              :selected="true"
                            ></option>
                            <option
                              v-for="zip in zips"
                              :key="zip.cap"
                              v-bind:value="zip.cap"
                              :selected="zip.cap == shipData.zip"
                            >
                              {{ zip.cap }}
                            </option>
                          </select>
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label class="niceInputLabel Regular theme" for="zip"
                            >Cap *<span v-if="zipError" class="formError"
                              >Prima scegli Località</span
                            >
                          </label>
                          <div v-if="zipsState">
                            <font-awesome-icon
                              class="loadSpinner"
                              id="loadZips"
                              icon="spinner"
                              size="1x"
                              pulse
                            />
                          </div>
                          <div
                            v-if="errors.has('zip')"
                            class="invalid-feedback"
                          >
                            {{ errors.first("zip") }}
                          </div>
                        </div>
                      </div>-->

							<div class="col-md-6 mb-5 mb-md-0">
								<label
									class="niceInputLabel Regular theme ml-4 iban"
									:class="{ ibanSmall: vw < 1540 }"
									for="iban"
								>IBAN (corrispondente ad un c/c<br v-if="vw < 1540" />
									intestato a chi richiede il rimborso)</label>
								<div class="group">
									<input
										type="text"
										class="form-control"
										v-model="iban"
										id="iban"
										name="iban"
										@blur="checkIfIban"
										data-vv-as="Iban"
										v-validate="{
                      required: true,
                      ibanItalian: true,
                      ibanControl: true,
                      min: 5,
                    }"
										:class="{
                      'is-invalid': errors.has('iban'),
                      valid: !!iban,
                    }"
									/>
									<span class="highlight"></span>
									<span class="bar"></span>
									<div
										v-if="existIban"
										class="invalid-feedback yellow uppercase Bold"
										style="display: block"
									>
										<span>Hai 2 partecipazioni in verifica o verificate con questo
											iban</span>
									</div>
									<div
										v-if="errors.has('iban')"
										class="invalid-feedback"
									>
										{{ errors.first("iban") }}
									</div>
								</div>
							</div>

							<div class="col-md-6"></div>
						</div>
						<!--<div class="row justify-content-center">
                      <div class="col-md-4 mb-3">
                        <div class="group">
                          <input
                            type="text"
                            autocomplete="randomr"
                            class="form-control"
                            v-model="address"
                            id="address"
                            name="address"
                            data-vv-as="Indirizzo"
                            v-validate="{ required: true, max: 128, min: 5 }"
                            :class="{
                              'is-invalid': errors.has('address'),
                              valid: !!address,
                            }"
                          />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label
                            class="niceInputLabel Regular theme"
                            for="address"
                            >Indirizzo *</label
                          >
                          <div
                            v-if="errors.has('address')"
                            class="invalid-feedback"
                          >
                            {{ errors.first("address") }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 mb-3">
                        <div class="group">
                          <input
                            type="text"
                            autocomplete="randomt"
                            class="form-control"
                            v-model="civic"
                            id="civic"
                            name="civic"
                            data-vv-as="Civico"
                            v-validate="{ required: true, max: 15 }"
                            :class="{
                              'is-invalid': errors.has('civic'),
                              valid: !!civic,
                            }"
                          />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label
                            class="niceInputLabel Regular theme"
                            for="civic"
                            >Civico *</label
                          >
                          <div
                            v-if="errors.has('civic')"
                            class="invalid-feedback"
                          >
                            {{ errors.first("civic") }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 mb-1">
                        <div class="group">
                          <input
                            type="text"
                            autocomplete="randomo"
                            class="form-control"
                            v-model="near"
                            id="near"
                            name="near"
                            data-vv-as="Presso"
                            v-validate="{ max: 128, min: 2 }"
                            :class="{
                              'is-invalid': errors.has('near'),
                              valid: !!near,
                            }"
                          />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label
                            class="niceInputLabel Regular theme"
                            for="lastname"
                            >Presso / Nome sul citofono
                          </label>
                          <div
                            v-if="errors.has('near')"
                            class="invalid-feedback"
                          >
                            {{ errors.first("near") }}
                          </div>
                        </div>
                      </div>
                    </div>-->

						<!-- CARICAMENTO IMMAGINE CON BOX -------------------
                <h1 class="theme Particular uppercase center mt-3">
                  Carica lo scontrino
                </h1>
                <div class="row justify-content-center">
                  <div class="white col-md-12 center">
                    <h5 class="Regular theme center pb-0 mt-4 b0 black">
                      Sono valide le partecipazioni con un documento riportante
                      acquisti (scontrino) per almeno 10 € euro
                      <b class="Bold theme">di prodotti HERO SOLO</b>
                    </h5>
                    <small class="theme Regular"
                      >Ogni Scontrino dà diritto a richiedere 1 solo
                      premio</small
                    >
                  </div>
                </div>
                <div class="row justify-content-center relative pb-5 mt-3">
                  <div
                    v-if="
                      documentType != 'Scontrino' && documentType != 'documento'
                    "
                    class="blockInputs"
                    v-on:click="shakeDocTypeTrigger"
                  ></div>
                  <div
                    v-else-if="documentType == 'Scontrino'"
                    class="blockInputs white"
                    v-on:click="infoPopUp"
                  ></div>
                  <div class="col-sm-6 col-lg-6 mb-3">
                    <div
                      class="hideFileUpload"
                      v-if="
                        documentType != 'Scontrino' &&
                        documentType != 'documento'
                      "
                    ></div>
                    <div class="front-image center mb-4">
                      <input
                        type="file"
                        v-if="documentType == 'documento'"
                        v-on:change="uploadFile"
                        ref="fileInput1"
                        name="invoiceFront"
                        id="invoiceFront"
                        class="fileInput"
                        data-vv-as="Fronte"
                        v-validate="{
                          required: true,
                          size: 10240,
                          ext: ['jpeg', 'jpg', 'png', 'pdf'],
                          mimes: [
                            'image/jpeg',
                            'image/jpg',
                            'image/png',
                            'application/pdf',
                          ],
                        }"
                        :class="{
                          'is-invalid': errors.has('invoiceFront'),
                          valid: !!invoiceFront,
                          helper: !!invoiceFrontBucket,
                        }"
                      />
                      <input
                        type="file"
                        v-else
                        ref="fileInput1"
                        v-on:change="uploadStep2"
                        name="invoiceFront"
                        id="invoiceFront"
                        class="fileInput"
                        data-vv-as="Fronte"
                        v-validate="{
                          required: true,
                          size: 10240,
                          ext: ['jpeg', 'jpg', 'png', 'pdf'],
                          mimes: [
                            'image/jpeg',
                            'image/jpg',
                            'image/png',
                            'application/pdf',
                          ],
                        }"
                        :class="{
                          'is-invalid': errors.has('invoiceFront'),
                          valid: !!invoiceFront,
                          helper: !!invoiceFrontBucket,
                        }"
                      />
                      <div
                        v-if="errors.has('invoiceFront')"
                        class="invalid-feedback"
                      >
                        {{ errors.first("invoiceFront") }}
                      </div>
                      <label
                        v-show="documentType"
                        class="niceInputLabel Regular theme labelFileInput"
                        for="invoiceFront"
                      >
                        <span
                          v-if="!filename1"
                          class="smallText center red"
                          :style="{
                            backgroundImage: 'url(' + invoiceFrontBucket + ')',
                          }"
                        >
                          <p
                            v-if="!filename1 && documentType != 'documento'"
                            class="smallText sizeX center theme mt-3 mb-0 pb-0"
                          >
                            Carica fronte * <br /><span class="smallText2"
                              >(JPG, PNG o PDF - MIN 0.1MB, MAX 10MB)</span
                            >
                          </p>
                          <p
                            v-if="!filename1 && documentType == 'documento'"
                            class="smallText sizeX center theme mt-3 mb-0 pb-0"
                          >
                            Carica l’immagine*
                          </p>
                        </span>
                        <span
                          v-else
                          class="smallText center red"
                          :style="{
                            backgroundImage: 'url(' + invoiceFrontBucket + ')',
                          }"
                        >
                          <p class="smallText center red mt-3 mb-0 pb-0">
                            {{ filename1 }}
                          </p>
                        </span>
                      </label>
                      <div
                        v-if="uploadFrontPercentage"
                        class="progress"
                        id="progFront"
                      >
                        <div
                          class="progress-bar"
                          id="progress-bar-front"
                          role="progressbar"
                          v-bind:style="{
                            width: uploadFrontPercentage + '%',
                          }"
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {{ uploadFrontPercentage }} %
                        </div>
                      </div>
                    </div>
                    <p class="smallText center red mt-3"></p>
                  </div>
                  <div
                    v-if="!UFO && documentType != 'documento'"
                    class="col-sm-6 col-lg-6 mb-3"
                  >
                    <div
                      class="hideFileUpload"
                      v-if="
                        documentType != 'Scontrino' &&
                        documentType != 'documento'
                      "
                      v-on:click="shakeDocTypeTrigger"
                    ></div>
                    <div class="front-image center mb-4">
                      <input
                        type="file"
                        v-if="documentType == 'documento'"
                        v-on:change="uploadFileRear"
                        ref="fileInput2"
                        name="invoiceRear"
                        id="invoiceRear"
                        class="fileInput"
                        data-vv-as="Retro"
                        v-validate="{
                          size: 10240,
                          ext: ['jpeg', 'jpg', 'png', 'pdf'],
                          mimes: [
                            'image/jpeg',
                            'image/jpg',
                            'image/png',
                            'application/pdf',
                          ],
                        }"
                        :class="{
                          'is-invalid': errors.has('invoiceRear'),
                          valid: !!invoiceRear,
                          helper: !!invoiceRearBucket,
                        }"
                      />
                      <input
                        type="file"
                        v-else
                        ref="fileInput2"
                        v-on:change="uploadDouble"
                        name="invoiceRear"
                        id="invoiceRear"
                        class="fileInput"
                        data-vv-as="Retro"
                        v-validate="{
                          size: 10240,
                          ext: ['jpeg', 'jpg', 'png', 'pdf'],
                          mimes: [
                            'image/jpeg',
                            'image/jpg',
                            'image/png',
                            'application/pdf',
                          ],
                        }"
                        :class="{
                          'is-invalid': errors.has('invoiceRear'),
                          valid: !!invoiceRear,
                          helper: !!invoiceRearBucket,
                        }"
                      />
                      <div
                        v-if="errors.has('invoiceRear')"
                        class="invalid-feedback"
                      >
                        {{ errors.first("invoiceRear") }}
                      </div>
                      <label
                        v-show="documentType"
                        class="niceInputLabel Regular theme labelFileInput"
                        for="invoiceRear"
                      >
                        <span
                          v-if="!filename1"
                          class="smallText center red"
                          :style="{
                            backgroundImage: 'url(' + invoiceRearBucket + ')',
                          }"
                        >
                          <p
                            v-if="!filename2"
                            class="smallText sizeX center theme mb-0 pb-0"
                          >
                            Carica retro<br /><span class="smallText2"
                              >(JPG, PNG o PDF - MIN 0.1MB, MAX 10MB)</span
                            >
                          </p>
                        </span>
                        <span
                          v-else
                          class="smallText center red"
                          :style="{
                            backgroundImage: 'url(' + invoiceRearBucket + ')',
                          }"
                        >
                          <p class="smallText center red mt-3 mb-0 pb-0">
                            {{ filename2 }}
                          </p>
                        </span>
                      </label>
                      <div
                        v-if="uploadRearPercentage"
                        class="progress"
                        id="progFront"
                      >
                        <div
                          class="progress-bar"
                          id="progress-bar-front"
                          role="progressbar"
                          v-bind:style="{
                            width: uploadRearPercentage + '%',
                          }"
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {{ uploadRearPercentage }} %
                        </div>
                      </div>
                    </div>
                  </div>
                </div>-->

						<div class="row relative mt-3">
							<!--ANTEPRIMA FOTO CARICATA ( ANCHE PDF )<div
                    v-viewer
                    v-if="
                      vw > 845 &&
                      !invoiceFrontBucket.toUpperCase().includes('.PDF')
                    "
                    class="col-md-4 col-lg-5 backImageInvoice mb-5"
                    ref="inv"
                    style="max-height: 400px !important"
                  >
                    <font-awesome-icon
                      @click="showViever"
                      class="zoomImage fa-3x"
                      icon="search-plus"
                      size="lg"
                      style="z-index: 111; top: 30%; left: 30%"
                    />
                    <img
                      :src="invoiceFrontBucket"
                      class="image img-fluid absolute"
                      id="inv"
                      style="left: 0"
                    />
                    <img
                      v-if="invoiceRearBucket"
                      :src="invoiceRearBucket"
                      class="image img-fluid"
                      id="inv"
                    />
                  </div>
                  <div
                    v-else
                    class="col-md-4 col-lg-5 backImageInvoice mb-5"
                    ref="inv"
                    style="max-height: 400px !important"
                  >
                    <a :href="invoiceFrontBucket" target="_blank"
                      >Clicca qui per vedere il pdf</a
                    >
                  </div>-->

							<div
								class="col-md-6 col-lg-3 mb-5 mb-md-0"
								:class="{ closedBlock: !!ocrData.data }"
							>
								<!---->
								<div class="group">
									<label
										class="niceInputLabel Regular theme"
										for="date"
									>Data scontrino</label>
									<datepicker
										:language="it"
										v-model="date"
										:disabled="!!ocrData.data"
										:full-month-name="true"
										:initialView="'month'"
										:format="'dd MMMM yyyy'"
										:monday-first="true"
										:disabledDates="disabledDates"
										id="date"
										name="date"
										input-class="form-control dateInput"
										class="data-scontrino"
									></datepicker>

									<input
										type="text"
										v-show="false"
										v-model="date"
										class="form-control"
										id="date"
										name="date"
										data-vv-as="Data scontrino"
										v-validate="{ required: true }"
										:class="{
                      'is-invalid': errors.has('date'),
                      valid: !!date,
                    }"
									/>

									<span class="highlight"></span>
									<span class="bar"></span>

									<div
										v-if="errors.has('date')"
										class="invalid-feedback"
									>
										{{ errors.first("date") }}
									</div>
								</div>
							</div>

							<div
								v-if="documentType == 'Scontrino'"
								class="col-md-6 col-lg-3 mb-5 mb-md-0"
								:class="{ closedBlock: !!ocrData.ora }"
							>
								<!---->
								<div
									class="group"
									id="ora-scontrino"
								>
									<vue-timepicker
										v-model="time"
										class="form-control w-100"
										:disabled="!!ocrData.ora"
										:hideClearButton="true"
										data-vv-validate-on="none"
										id="time"
										name="time"
										data-vv-as="Ora Scontrino"
										v-validate="{ emptyTime: true }"
										:class="{
                      disabledTime: !!time.HH && !!time.mm,
                      'is-invalid': errors.has('time'),
                      valid: !!time.HH != '',
                    }"
									></vue-timepicker>

									<span class="highlight"></span>
									<span class="bar"></span>
									<label
										class="niceInputLabel Regular theme labelTime"
										for="time"
									>Ora scontrino<br />
									</label>

									<div
										v-if="errors.has('time')"
										class="invalid-feedback"
									>
										Il campo Ora scontrino è richiesto
									</div>
									<small class="white">per la conferma d’ordine digitare 0000 se non presente
										l’ora</small>
								</div>
							</div>
							<div
								class="col-md-5 col-lg-3 mt-md-3 mt-lg-0 mb-5 mb-md-0"
								:class="{
                  closedBlock: !!ocrData.numero,
                }"
							>
								<!---->
								<div class="group">
									<input
										type="text"
										v-model="number"
										:disabled="!!ocrData.numero"
										class="form-control"
										id="number"
										name="number"
										data-vv-as="Numero scontrino"
										v-validate="{
                      required: true,
                      integer: false,
                    }"
										:class="{
                      'is-invalid': errors.has('number'),
                      valid: !!number,
                    }"
									/>
									<span class="highlight"></span>
									<span class="bar"></span>
									<label
										class="niceInputLabel Regular theme"
										for="number"
									>Numero scontrino</label>

									<div
										v-if="errors.has('number')"
										class="invalid-feedback"
									>
										{{ errors.first("number") }}
									</div>
									<small class="white">* se ad esempio il numero presente sullo scontrino è
										1234-5678 il numero da indicare è 5678</small>
								</div>
							</div>
							<div
								class="col-md-7 mb-5 mb-md-0"
								:class="{
                  'col-lg-3': vw > 1100,
                  'mt-3 col-lg-5 ': vw < 1100,
                }"
							>
								<!--:class="{ closedBlock: !!price && !!priceCent }"-->
								<div class="group priceVal pr-0 mr-0">
									<input
										type="text"
										v-model="price"
										@focus="priceFocused = true"
										:disabled="!!ocrData.importo"
										class="form-control text-right euroInput"
										id="price"
										name="price"
										data-vv-as="Importo scontrino"
										v-validate="{
                      required: true,
                      regex: /^([0-9][0-9]*)$/,
                      max: 4,
                    }"
										:class="{
                      'is-invalid': errors.has('price'),
                      valid: !!price,
                    }"
									/>
									<span
										v-if="priceFocused || priceCent || price"
										class="pseudoEuro"
									>&euro;:</span>
									<span class="highlight"></span>
									<span class="bar"></span>
									<label
										v-if="!priceCent || (priceCent && price)"
										class="niceInputLabel Regular theme newPriceLabel doubleRow"
										for="price"
									>Importo scontrino</label>

									<div
										v-if="errors.has('price')"
										class="invalid-feedback"
										style="width: 200%; display: block; position: absolute"
									>
										{{ errors.first("price") }}
									</div>
								</div>
								<span
									v-if="vw > 800"
									class="white"
								>,</span>
								<div class="group priceCent pl-0 ml-0">
									<input
										type="text"
										v-model="priceCent"
										@focus="priceFocused = true"
										:disabled="!!ocrData.importo"
										class="form-control text-left centInput"
										id="priceCent"
										name="priceCent"
										data-vv-as="Centesimi"
										v-validate="{
                      required: true,
                      min: 2,
                      max: 2,
                      regex: /^([0-9]+)$/,
                    }"
										:class="{
                      'is-invalid': errors.has('priceCent'),
                      valid: !!priceCent,
                    }"
									/>
									<span
										v-if="priceFocused || priceCent || price"
										class="pseudoCent"
									>Cent.</span>
									<span class="highlight"></span>
									<span class="bar"></span>
									<label
										v-if="!price && priceCent"
										class="niceInputLabel Regular theme newPriceLabel"
										for="price"
										:style="{ 'margin-left: -115%': vw > 1100 }"
									>Importo</label>

									<div
										v-if="errors.has('priceCent') && !errors.has('price')"
										class="invalid-feedback"
										style="
                      width: 200%;
                      display: block;
                      position: absolute;
                      margin-left: -100%;
                    "
									>
										{{ errors.first("priceCent") }}
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="group">
									<label
										class="lable-style-custom fa-thermometer-1 mb-3"
										for="tappa"
									>Allega: in un’unica immagine in formato
										<br v-if="vw < 800" />jpeg la fotografia dello scontrino
										<br v-if="vw < 1100" />e dei prodotti in promozione
										acquistati

										<!-- 	Allega: in un’unica immagine in formato <br v-if="vw<800" />jpeg la
												fotografia dello scontrino <br v-if="vw<1100" />e dei prodotti in promozione
												acquistati<br />
												o, in caso di acquisto online, <br v-if="vw<800" />il messaggio di conferma<br v-if="vw<800" />
												dell’ordine effettuato -->
									</label>
									<font-awesome-icon
										v-if="invoiceFrontBucket"
										class="uploadDone mt-5"
										size="1x"
									/>
									<input
										type="file"
										v-on:change="uploadFile"
										ref="fileInput1"
										name="invoiceFront"
										id="invoiceFront"
										class="form-control"
										data-vv-as="Fronte"
										v-validate="{
                      required: true,
                      size: 10240,
                      ext: ['jpeg', 'jpg', 'png', 'pdf'],
                      mimes: [
                        'image/jpeg',
                        'image/jpg',
                        'image/png',
                        'application/pdf',
                      ],
                    }"
										:class="{
                      'is-invalid': errors.has('invoiceFront'),
                      valid: !!invoiceFront,
                      helper: !!invoiceFrontBucket,
                    }"
									/>

									<span class="highlight"></span>
									<span class="bar"></span>

									<div
										v-if="errors.has('invoiceFront')"
										class="invalid-feedback"
									>
										Il campo Immagine è richiesto
									</div>

									<div
										v-if="uploadFrontPercentage"
										class="progress"
										id="progFront"
									>
										<div
											class="progress-bar"
											id="progress-bar-front"
											role="progressbar"
											v-bind:style="{ width: uploadFrontPercentage + '%' }"
											aria-valuenow="50"
											aria-valuemin="0"
											aria-valuemax="100"
										>
											{{ uploadFrontPercentage }} %
										</div>
									</div>
								</div>
								<a
									v-if="invoiceFrontBucket"
									v-bind:href="invoiceFrontBucket"
									target="_blank"
								>
									<a
										v-if="
                      invoiceFrontBucket.substring(
                        invoiceFrontBucket.lastIndexOf('.') + 1,
                        invoiceFrontBucket.length
                      ) != 'pdf'
                    "
										class="success"
									>Scontrino caricato correttamente
									</a>
									<a
										v-else
										class="center widthFull"
										v-bind:href="invoiceFrontBucket"
										target="_blank"
									>APRI <br />
										PDF</a>
								</a>
							</div>
							<div class="col-md-6"></div>
						</div>
						<div class="row justify-content-center">
							<div class="col-12 ml-4 mt-4 mb-3 white">
								Per maggiori informazioni sulle modalità di trattamento dei tuoi
								dati, ti invitiamo a prendere visione della nostra
								<a
									:href="privacyPolicy"
									target="_blank"
									class="Bold"
								>Informativa Privacy.</a>

								<!-- <div class="form-check">
											<div class="group">
												<div class="row justify-content-center">
													<div
														class="col-md-3"
														:class="{'col-lg-2':vw>1100}"
													>
														<div class="form-check form-check-inline mt-2">
															<label class="form-check-label">
																<input
																	type="radio"
																	class="form-check-input inline mr-3"
																	v-model="privacy1"
																	v-validate="'required|included:1,0'"
																	value="1"
																	name="privacy1"
																	:class="{
                                    'is-invalid': errors.has('privacy1'),
                                  }"
																/>Si
															</label>
														</div>
														<div class="form-check form-check-inline">
															<label class="form-check-label">
																<input
																	type="radio"
																	class="form-check-input inline mr-3"
																	v-model="privacy1"
																	value="0"
																	name="privacy1"
																	:class="{
                                    'is-invalid': errors.has('privacy1'),
                                  }"
																/>No
															</label>
														</div>
													</div>
													<div
														class="col-md-9"
														:class="{'col-lg-10':vw>1100}"
													>
														<label
															class="form-check-label widthFull glade"
															for="privacy1"
														>
															Ai sensi del D.Lgs. 196/2003, dichiaro di aver
															preso visione <a
																:href="privacyPolicy"
																target="_blank"
																class="Bold theme underline"
															>dell’informativa </a>e del <a
																:href="regolamentoPolicy"
																target="_blank"
																class="Bold theme underline"
															>Termini e
																Condizioni</a> e dichiaro di essere consapevole che i
															miei dati personali saranno trattati da Slang
															Comunicazione S.r.l. ai fini della mia
															partecipazione a “PHYTO CASHBACK 2021”
														</label>
														<label
															class="inv widthFull"
															v-show="errors.has('privacy1')"
														>
															Il consenso deve essere accettato oppure rifiutato
														</label>
													</div>
												</div>
											</div>
										</div>
										<div class="form-check">
											<div class="group">
												<div class="row justify-content-center">
													<div
														class="col-md-3"
														:class="{'col-lg-2':vw>1100}"
													>
														<div class="form-check form-check-inline mt-2">
															<label class="form-check-label">
																<input
																	type="radio"
																	class="form-check-input inline mr-3"
																	v-model="privacy2"
																	v-validate="'required|included:1,0'"
																	value="1"
																	name="privacy2"
																	:class="{
                                    'is-invalid': errors.has('privacy2'),
                                  }"
																/>Si
															</label>
														</div>
														<div class="form-check form-check-inline">
															<label class="form-check-label">
																<input
																	type="radio"
																	class="form-check-input inline mr-3"
																	v-model="privacy2"
																	value="0"
																	name="privacy2"
																	:class="{
                                    'is-invalid': errors.has('privacy2'),
                                  }"
																/>No
															</label>
														</div>
													</div>
													<div
														class="col-md-9"
														:class="{'col-lg-10':vw>1100}"
													>
														<label
															class="form-check-label widthFull glade"
															for="privacy2"
														>
															Acconsento a quanto sopra per le finalità di
															trattamento di cui all’art. 2 (B) Invio di
															newsletter tramite email o SMS di comunicazioni
															commerciali e/o materiale pubblicitario relativi a
															prodotti o servizi offerti dal titolare.
														</label>
														<label
															class="inv widthFull"
															v-show="errors.has('privacy2')"
														>
															Il consenso deve essere accettato oppure rifiutato
														</label>
													</div>
												</div>
											</div>
										</div> -->
							</div>
						</div>
						<div class="row justify-content-center">
							<div class="col-12 center">
								<button class="btn btn-primary-1 Bold">INVIA</button>
							</div>
						</div>
					</form>
				</div>
			</div>

			<div
				v-else-if="progress == 'SUCCESS_WIN'"
				class="row justify-content-center mt-5 pt-5"
			>
				<!--ALREADY_WIN
              SUCCESS_WIN-->
				<div class="col-10 col-md-7 pb-3 mt-4 mb-3 pt-5 mb-0 center">
					<h1 class="
              Particular
              h1
              widthFull
              center
              pb-0
              pt-2
              mb-0
              zindex1
              uppercase
              widthFull
              red
            ">
						<b>COMPLIMENTI,</b>
					</h1>

					<h1 class="
              Particular
              h2
              widthFull
              center
              pb-0
              pt-2
              mb-0
              zindex1
              uppercase
              widthFull
              red
            ">
						<b>Hai correttamente registrato la tua partecipazione</b>
					</h1>

					<h4 class="mt-3 h5 mb-0 theme">
						Il documento commerciale di vendita verrà moderato.
					</h4>
					<button
						class="btn btn-primary mt-4"
						v-on:click="reload()"
					>
						Home
					</button>
				</div>

				<h5
					v-if="haslogin"
					class="center pt-4 theme mobSize2 widthFull"
				>
					Puoi sempre verificare le tue participazioni nell'area dedicata
				</h5>
				<router-link
					v-if="haslogin"
					to="/receipts"
					class="btn btn-primary mb-5 mt-4"
				>
					<span class="multiplelines">Le tue partecipazioni</span>
				</router-link>
			</div>
			<div
				v-else-if="progress == 'SUCCESS_LOSE'"
				class="row justify-content-center pt-5 pTM"
			>
				<div class="col-10 col-md-7 pb-5 pt-5 mb-5 center pTM">
					<h1
						style="color: #b70c22"
						class="
              bigTitle
              widthFull
              center
              pb-0
              pt-0
              zindex1
              uppercase
              widthFull
              FCB
            "
					>
						<b>PECCATO, NON HAI VINTO.</b>
					</h1>
				</div>

				<div class="col-10 col-md-7 pb-5 center pTM custForm">
					<h4 class="center pt-2 white mobSize2 mobSize3 mt-4 pt-3 mb-0 pb-0">
						<p class="mobSize2 mobSize3 mt-0 pt-3 mb-0 pb-0 uppercase Bold">
							Conserva comunque lo scontrino per partecipare
						</p>
					</h4>
					<h4 class="center pt-2 white mobSize2 mt-0 pt-2 mb-0 pb-0">
						<p class="mobSize2 mobSize3 mt-0 pt-0 mb-0 pb-0 uppercase Bold">
							all'eventuale estrazione a recupero, che sarà effettuata entro il
							31 GENNAIO 2021.
						</p>
					</h4>

					<h5 class="
              center
              pt-2
              white
              mobSize2
              mobSize3
              mt-0
              pt-4
              mb-0
              pb-4
              uppercase
              Bold
            ">
						Puoi sempre verificare le tue participazioni nell'area dedicata
					</h5>

					<router-link
						to="/receipts"
						class="btn btn-light mb-5 mt-5"
					>
						Le tue partecipazioni
					</router-link>
				</div>
			</div>

			<div
				v-else-if="progress == 'ALREADY_WIN'"
				class="row justify-content-center pt-md-5"
			>
				<div class="col-10 col-md-10 pt-md-5 pt-5 mt-4 center">
					<h1 class="
              bigTitle
              mt-md-5
              widthFull
              center
              pb-0
              pt-0
              mb-0
              zindex1
              uppercase
              widthFull
              FCB
            ">
						<b>ATTENZIONE!</b>
					</h1>
				</div>
				<div class="col-10 col-md-10 pb-5 mb-5 center custForm">
					<h4 class="center mt-md-5 mt-2 mb-0 theme mobSize2">
						<p
							v-if="
                oldWinPlay.status == 'approved' ||
                oldWinPlay.status == 'confirmed'
              "
							class="mobSize2 mt-0 mb-0 pb-4"
						>
							Hai già una partecipazione valida.
						</p>
						<p
							v-if="oldWinPlay.status == 'pending'"
							class="mobSize2 mt-0 mb-0 pb-4 uppercase"
						>
							Hai già una partecipazione in attesa di validazione.
						</p>
					</h4>
					<h4 class="center pt-2 theme mobSize2 mt-0 mb-0">
						<p class="mobSize2 mt-0 mb-0 pb-4 uppercase">
							Ti ricordiamo che ciascun consumatore potrá richiedere al massimo
							un rimborso
						</p>
					</h4>
					<h5 class="center pt-2 theme mobSize2 uppercase mt-0 mb-0 pb-5">
						Puoi sempre verificare lo stato delle tue participazioni nell'area
						dedicata
					</h5>
					<router-link
						to="/receipts"
						class="btn btn-primary mb-5 mt-5"
					>
						Le tue partecipazioni
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapState, mapGetters } from 'vuex';
	import axios from 'axios';
	import { USER_REQUEST, USER_SET_DEFAULT_SHIP_DATA } from 'actions/user';
	import Datepicker from 'vuejs-datepicker';
	import { it } from 'vuejs-datepicker/dist/locale';
	import VueTimepicker from 'vue2-timepicker';
	// import VideoBackground from 'vue-responsive-video-background-player';

	import { FormWizard, TabContent } from 'vue-form-wizard';
	import 'vue-form-wizard/dist/vue-form-wizard.min.css';

	import { checkUniqueMixin } from '../../mixins/checkUniqueMixin';
	import { sharedEnvMixin } from '../../mixins/sharedEnvMixin';
	import { locationMixin } from '../../mixins/locationMixin';
	import { toDateMixin } from '../../mixins/toDateMixin';
	import { innerWidthMixin } from '../../mixins/innerWidthMixin';
	import { playFunctionMixin } from '../../mixins/playFunctionMixin';

	const moment = require('moment');

	export default {
		name: 'play',
		components: {
			Datepicker,
			VueTimepicker,
			// VideoBackground,
			FormWizard,
			TabContent,
		},
		mixins: [
			checkUniqueMixin,
			sharedEnvMixin,
			locationMixin,
			toDateMixin,
			innerWidthMixin,
			playFunctionMixin,
		],
		data() {
			return {
				prod: '',
				videoLoaded: false,
				videoOpacity: 1,
				showDateInfoVar: false,
				it: it,
				timeVal: '',
				prod1: '',
				prod2: '',
				stepPopUp: true,
				priceFocused: false,
				colorType: '',
				product: '',
				advisor: false,
				productIndex: 1,
				showProdError: false,
				quantity: '',
				disabledDates: {
					to: '',
					from: '',
				},

				pdvs: [],
				pdvsState: false,
				pdvState: false,
				pdvsError: false,
				pdv_prov: '',
				prodSel1: false,
				prodSel2: false,
				pdv: '',
				pdv_city: '',
				pdv_cities: [],
				items: {},
				provincesState: true,
				cities: [],
				citiesState: false,
				zips: [],
				zipsState: false,
				allInvoiceInfo: false,
				question: '',
				canScroll: true,
				scrollFinished: false,
				stepSize: 'md',
				rebate: '',
				totPrice: '',
				priceError: false,
				timeC: false,
				countdown: 0,
				datenow: '',
				showInfo: false,
				token: '',
				id_image: '',
				zip: '',
				city: '',
				province: '',
				endedVideo: true,
				taglia: '',
				taglie: [],
			};
		},
		methods: {
			showSuccessUpdateSHIP() {
				// Use sweetalret2
				this.$swal({
					type: 'success',
					title: 'I dati per la spedizione sono stati correttamente aggiornati',
					timer: 4500,
				});
			},
			ended: function () {
				this.endedVideo = true;
			},
			videoReady: function () {
				this.videoLoaded = true;
			},
			async recaptcha() {
				var _vue = this;
				const recaptcha = _vue.$recaptchaInstance;

				// (optional) Wait until recaptcha has been loaded.
				await _vue.$recaptchaLoaded();

				// Execute reCAPTCHA with action "login".
				this.token = await _vue.$recaptcha('login');

				// Do stuff with the received token.
			},
			stepIndex(from, to) {
				if (this.scrActiv) {
					//this.$scrollTo('.main', 700, { offset: -170 });
					if (to == 0) {
						this.$route.hash != '#step1'
							? this.$router.push({ hash: '#step1' })
							: '';
					}
					if (to == 1) {
						this.$route.hash != '#step2'
							? this.$router.push({ hash: '#step2' })
							: '';
					}
					if (to == 2) {
						this.$route.hash != '#step3'
							? this.$router.push({ hash: '#step3' })
							: '';
					}
				}

				if (to == 1) {
					this.showInfo = true;
				} else {
					this.showInfo = false;
				}
				this.$track('STEP', from + ' => ' + to);
			},
			checkProducts: function (param) {
				var products = this.products;
				var count = 0;
				if (param) {
					this.showProdError = false;
				} else {
					for (var i in products) {
						var prod = products[i].product;
						if (products[i].quantity) {
							if (prod.includes('30')) {
								count = count + 1 * parseInt(products[i].quantity);
							} else {
								count = count + 3 * parseInt(products[i].quantity);
							}
						}
					}
					if (count >= 3) {
						this.showProdError = false;
					} else {
						this.showProdError = true;
					}
				}
			},
			showViever() {
				var el = this.$refs.inv;
				const viewer = el.$viewer;
				viewer.show();
			},
			timeReactive() {
				if (this.timeStore > moment(process.env.VUE_APP_FINISH)) {
					window.location.replace('/');
				}
			},
			calcTotPrice: function () {
				var prod = this.products;
				var count = 0;
				for (var x in prod) {
					if (prod[x].price && prod[x].quantity) {
						count =
							count +
							parseFloat(prod[x].price).toFixed(2) * parseInt(prod[x].quantity);
					}
				}
				if (isNaN(count)) {
					count = 0.0;
				}

				if (count > 0) {
					this.priceError = false;
				} else {
					this.priceError = true;
				}

				this.totPrice = parseFloat(count).toFixed(2);
			},

			sessionFail: function () {
				this.$swal({
					type: 'error',
					title: '',
					html: '<h4 class="smallText">La tua sessione è scaduta. Effettua nuovamente l`operazione.</h4>',
					confirmButtonText: 'CHIUDI',
					onClose: () => {
						this.$router.push('/');
						setTimeout(function () {
							window.location.reload();
						}, 500);
					},
				});
			},
			reload: function () {
				window.location.reload();
			},
			calcInvoiceStatusOcr() {
				var errors = this.ocrErrors;

				if (errors.count) {
					if (errors.count == 4) {
						this.invoiceStatusOcr = '606';
					} else if (errors.count == 3) {
						// OK DATA
						if (errors.date.status == 'OK') {
							this.invoiceStatusOcr = '611';
						}
						// OK ORA
						if (errors.time.status == 'OK') {
							this.invoiceStatusOcr = '612';
						}
						// OK IMPORTO
						if (errors.amount.status == 'OK') {
							this.invoiceStatusOcr = '608';
						}
						// OK NUMERO
						if (errors.number.status == 'OK') {
							this.invoiceStatusOcr = '607';
						}
					} else if (errors.count == 2) {
						// IF I AM HERE => ONLY DATA ORA MISSING
						this.invoiceStatusOcr = '609';
					}

					if (errors.date.error == 'not_play_period') {
						this.invoiceStatusOcr = '610';
					}
				}
			},
			showErrorOCR(text) {
				var html =
					'<p class="smallText text-left">Nell`immagine eventualmente caricata:</p><ul>';

				var x = 0;
				var z = 0;
				for (var i in text) {
					if (text[i].status == 'KO') {
						if (text[i].description) {
							html +=
								'<li class="smallText text-left">' +
								text[i].description +
								'</li>';
						}

						x++;
					}
					//if ( text[i].error == 'price_less' ){
					//  z = 1
					//}
				}
				html += '</ul>';

				// TO MANY ERROR = IS NOT INVOICE
				if (x >= 4) {
					html =
						'<p class="smallText text-left">Purtroppo l`immagine caricata non sembra essere quella di un scontrino.</p>';
					html +=
						'<ul><li class="smallText text-left">I dati sullo scontrino devono essere ben visibili.</li>';
					html +=
						'<li class=" smallText text-left mt-2">Se lo scontrino è troppo lungo, piegalo a fisarmonica per mostrare solo i dati necessari</li> ';
					html +=
						'<li class=" smallText text-left mt-2">La foto caricata deve avere un peso compreso fra 12KB e 10MB</li></ul> ';
					html +=
						'<p class="smallText text-left mt-3">Prova a rifare la foto e ricarica lo scontrino</p> ';
					z = 4;
				} else if (x <= 3) {
					html += '<p class="smallText text-left">Attenzione!</p>';
					if (x == 1 && text.date.error == 'not_play_period') {
						// IF ONLY DATE OUT OF PERIOD
						// html += '<ul><li class="smallText text-left">Sono passati piú di 5 giorni compreso oggi dalla data di acquisto riportata sul documento di vendita.</li></ul>'
					} else {
						// IF MANY ERRORS
						html +=
							'<ul><li class="smallText text-left">I dati sullo scontrino devono essere ben visibili.</li>';
						html +=
							'<li class=" smallText text-left mt-2">Se lo scontrino è troppo lungo piegalo a fisarmonica per mostrare solo i dati necessari</li>';
						html +=
							'<li class=" smallText text-left mt-2">La foto caricata deve avere un peso compreso fra 12KB e 10MB</li></ul> ';
						html +=
							'<p class="smallText text-left mt-3">Prova a rifare la foto e ricarica lo scontrino</p> ';
					}
				}
				this.$swal({
					type: 'error',
					title: '<strong class="">Ops!</strong>',
					html: html,
					//confirmButtonText: "Esempio scontrino",
					confirmButtonColor: '#c95c1c',
					//showCancelButton: true,
					cancelButtonText: 'Chiudi',
					cancelButtonColor: 'black',
				}).then((okay) => {});
			},
			validateFirstTab: function () {
				var _vue = this;

				return this.$validator.validateAll('form-1');
			},
			validateSecondTab: function () {
				return this.$validator.validateAll('form-3');
			},
			validateThirdTab: function () {
				if (this.invoiceFront) {
					return this.$validator.validateAll('form-4');
				} else {
					return false;
				}
				//return true;
			},
			validate4Tab: function () {
				//if ( this.totPrice > 0 ){
				return this.$validator.validateAll('form-2');
				//  }else{
				//    this.priceError = true
				//    return false
				//  }
			},
			onComplate: function () {
				this.play();
			},

			showDateInfo() {
				if (this.showDateInfoVar) {
					this.showDateInfoVar = false;
				} else {
					this.showDateInfoVar = true;
				}
			},
			showError(text) {
				this.$swal({
					type: 'error',
					title: '<strong class="modalText danger">' + text + '</strong>',
					html: '',
					confirmButtonText: 'CHIUDI',
				});
			},
			showDublicateImage(img) {
				this.$swal({
					type: 'error',
					title: 'Ops!',
					html:
						'<p class="center smallText">Lo scontrino:</p> <p class="center smallText">' +
						img +
						'</p><p class="center smallText"> risulta già caricata</p>',
					confirmButtonText: 'CHIUDI',
				});
			},

			shakeDocTypeTrigger: function () {
				this.shakeDocType = true;
				var _vue = this;
				setTimeout(function () {
					_vue.shakeDocType = false;
				}, 1500);
			},

			addProduct() {
				this.productIndex = this.productIndex + 1;
				this.products.push({
					index: 'product' + this.productIndex,
					indexCount: 'quantity' + this.productIndex,
					product: '',
					quantity: '',
					price: '',
				});
			},
			removeProduct(elem) {
				var _vue = this;
				var index = this.products.indexOf(elem);
				this.products.splice(elem, 1);
				_vue.calcTotPrice();
			},

			infoPopUp() {
				this.$swal({
					//input: 'text',
					confirmButtonText: 'Carica FRONTE &rarr;',
					imageUrl: require('../../assets/images/invoice-example.png'),
					imageClass: 'minImg',
					focusCancel: false,
					focusConfirm: true,
					cancelButtonText: 'Annulla',
					showCancelButton: true,
					title: '',
					html:
						'<p class="smallText text-left"><ul  class="smallText text-left"><li class="smallText">I dati sullo scontrino devono essere ben visibili. </li>' +
						'<li class="smallText">Se lo scontrino è troppo lungo piegalo a fisarmonica per mostrare solo i dati necessari</li>' +
						'<li class=" smallText text-left mt-2">La foto caricata deve avere un peso compreso fra 12KB e 10MB</li></ul></p>',
				}).then((result) => {
					if (result.value) {
						this.$refs.fileInput1.click();
					}
				});
			},

			changeSwalImage: function () {
				alert('sdfgasd');
			},
			UploadError() {
				this.$swal({
					type: 'error',
					title:
						'<strong class="modalText danger">Errore caricamento immagine</strong>',
					html: '',
					confirmButtonText: 'CHIUDI',
					confirmButtonColor: '#15267',
				});
			},
			UploadError2(type) {
				if (type == 'size') {
					var html =
						'<p class="smallText text-left" >L`immagine supera peso massimo di 10 Mega</p>';
				} else if (type == 'size2') {
					var html =
						'<p class="smallText text-left" >La foto caricata è in bassa risoluzione grafica.</p><p class="smallText text-left" >Carica una nuova foto ad alta risoluzione</p>';
				} else {
					var html =
						'<p class="smallText text-left" >Sono valide le immagine in formato .pdf, .jpg, .jpeg o .png</p>';
				}

				this.$swal({
					type: 'error',
					title: '<strong class="smallText danger">!Attenzione</strong>',
					html: html,
					confirmButtonText: 'CHIUDI',
					confirmButtonColor: '#15267',
				});
			},

			openListProducts() {
				// Use sweetalret2
				this.$swal({
					type: '',
					title: '<strong class="modalText h2">prodotti in promozione</strong>',
					html:
						'<br/><br/><h5 class="modalText ">- Birra Messina 33cl</h5>' +
						'<h5 class="modalText even ">- Birra Messina 3x33cl</h5>' +
						'<h5 class="modalText ">- Birra Messina Cristalli di Sale 50cl</h5><br/><br/>',
					width: 600,
					confirmButtonText: 'chiudi',
				});
			},

			checkDefaultShipData() {
				if (this.shipData.name) {
					this.name = this.shipData.name;
					this.lastname = this.shipData.lastname;
					this.address = this.shipData.address;
					this.civic = this.shipData.civic;
					this.near = this.shipData.near;
					this.phone = this.shipData.phone;
					this.province = this.shipData.province;
					this.city = {};
					this.city.city = this.shipData.city;
					this.city.istat = 'toset';

					this.zip = this.shipData.zip;
					//this.zips = [
					//	cap => this.shipData.zip
					//]
				}
			},
			scrollToTop: function () {
				var _vue = this;
				setTimeout(function () {
					//_vue.$scrollTo('.main', 700, { offset: -120 });
				}, 100);
			},
			visibilityHow(isVisible, entry) {
				if (isVisible && this.scrollFinished) {
					this.$route.hash != '#come_partecipare'
						? this.$router.push({ hash: '#come_partecipare' })
						: '';
				}
			},
			visibilityPlay(isVisible, entry) {
				if (isVisible && this.scrollFinished) {
					this.$route.hash != '#partecipa'
						? this.$router.push({ hash: '#partecipa' })
						: '';
				}
			},
			visibilityLogin(isVisible, entry) {
				if (isVisible && this.scrollFinished) {
					this.$route.hash != '#login'
						? this.$router.push({ hash: '#login' })
						: '';
				}
			},

			setProfileData: function () {
				if (process.env.VUE_APP_HAS_LOGIN == 'ON') {
					this.email = this.profile.email;
					this.userToken = this.profile.id;
				} else {
					this.email = this.email;
					this.userToken = makeid(10);
				}
			},
		},
		watch: {
			prod: {
				handler(val) {
					if (val) {
						this.prodSel1 = true;
						this.advisor = false;
					} else {
						this.prodSel1 = false;
						this.advisor = false;
					}
				},
			},
			prod2: {
				handler(val) {
					if (val) {
						this.prodSel2 = true;
						this.advisor = false;
					} else {
						this.prodSel2 = false;
						this.advisor = false;
					}
				},
			},
			date: function (val) {},
			time: function (val) {},

			xuserx: function (val) {
				if (val) {
					this.xuserx = val.replace(/\d+/g, '');
					this.xuserx = this.xuserx.replace(
						/[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi,
						''
					);
					this.xuserx =
						this.xuserx.charAt(0).toUpperCase() + this.xuserx.slice(1);
				}
			},
			lastnamex: function (val) {
				if (val) {
					this.lastnamex = val.replace(/\d+/g, '');
					this.lastnamex = this.lastnamex.replace(
						/[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi,
						''
					);
					this.lastnamex =
						this.lastnamex.charAt(0).toUpperCase() + this.lastnamex.slice(1);
				}
			},
			pdv_city: function (val) {
				if (val) {
					this.pdv_city = val.replace(/\d+/g, '');
					this.pdv_city = this.pdv_city.replace(
						/[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi,
						''
					);
					this.pdv_city =
						this.pdv_city.charAt(0).toUpperCase() + this.pdv_city.slice(1);
				}
			},
			number: function (val) {
				if (val) {
					this.number = val.replace(/\D+/g, '');
				}
				if (val < 0) {
					this.number = val.replace(val, 0);
				}
			},
			phone: function (val) {
				if (val) {
					this.phone = val.replace(/\D+/g, '');
				}
			},
			// IF CHANGE PROVINCE -> LOAD CITIES
			province: function (newProvince) {
				this.zips = [];
				this.cities = [];
				this.zip = '';
				this.city = '';
				this.cityError = false;
				this.zipError = false;

				if (newProvince) {
					this.citiesState = true;
					axios({
						url: process.env.VUE_APP_ROOT_API + 'api/getComuni',
						method: 'POST',
						data: {
							sigla: newProvince,
						},
						headers: {
							Authorization: 'Bearer ' + process.env.VUE_APP_KITTY,
							'Content-type': 'application/json',
						},
					})
						.then((resp) => {
							this.cities = resp.data.cities;
							this.citiesState = false;
						})
						.catch((resp) => {
							this.cities = [];
							this.citiesState = false;
						});
				}
			},
			// IF CHANGE PROVINCE -> LOAD CITIES
			pdv_prov: function (newProvince) {
				this.pdv = [];
				this.pdvsState = false;
				this.pdv = '';
				this.pdv_city = '';
				this.pdvsError = false;
				this.pdvError = false;
			},
			city: function (newCity) {
				this.zipError = false;
				this.zips = [];
				this.zip = '';

				if (newCity.cityistat) {
					this.zipsState = true;
					axios({
						url: process.env.VUE_APP_ROOT_API + 'api/getCap',
						method: 'POST',
						data: {
							istat: newCity.cityistat,
						},
						headers: {
							Authorization: 'Bearer ' + process.env.VUE_APP_KITTY,
							'Content-type': 'application/json',
						},
					})
						.then((resp) => {
							this.zips = resp.data.cap;
							this.zipsState = false;
						})
						.catch((resp) => {
							this.zips = [];
							this.zipsState = false;
						});
				}
			},
			vw: function () {
				if (this.vw > 1200) {
					this.stepSize = 'md';
				} else if (this.vw > 600) {
					this.stepSize = 'sm';
				} else {
					this.stepSize = 'xs';
				}
			},
			getProfile: function () {
				this.setProfileData();
			},
		},
		updated: function () {},
		created() {
			var now = this.timeStore;
			var then = moment(process.env.VUE_APP_OCR_END);
			var dif = then.diff(now, 'days', true);
			this.setProfileData();
			//moment.utc(moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(then,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")
			this.countdown = parseInt(dif * 14.28);
		},
		async mounted() {
			if (hasLogin) {
				await this.retrivePlays();
			} else {
				_vue.progress = 'START';
			}
			this.setProfileData();
			//await this.recaptcha();
			this.populateProvincesPdv();
			var now = this.timeStore;
			var then = moment(process.env.VUE_APP_OCR_END);
			var dif = then.diff(now, 'hours', true);

			//moment.utc(moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(then,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")
			this.countdown = parseInt(dif);

			if (
				process.env.VUE_APP_INSTANT_WIN == 'ON' &&
				process.env.VUE_APP_MORE_WIN == 'OFF'
			) {
				var xkey = localStorage.getItem(
					'$%r332d2we24#@$234' + process.env.VUE_APP_ICODE + '1SDe452@#$%'
				);
				if (xkey == '$&@(*(@Jde20)($40E#@_(_Q#($_@(*_EIS') {
					var xkeyID = localStorage.getItem(
						'$%r332d324#@$234' + process.env.VUE_APP_ICODE + '245DSDe452@#$%'
					);
					var er = this.$decryptWORMY(xkeyID);
					this.invoiceStatus = '600';
					this.justWinCheck = 'Vincita pregressa con email => <br/>' + er;
				}
			}

			if (this.canScroll) {
				//this.$scrollTo('.main', 300, { offset: -85 });

				this.canScroll = false;
			}
			if (process.env.VUE_APP_HAS_LOGIN == 'ON') {
				this.$track('Getprofile', this.getProfile);
				this.lastnamex = this.getProfile.cognome;
				this.xuserx = this.getProfile.nome;

				if (this.isAuthenticated) {
					this.$watch(
						() => {},
						(val) => {}
					);
				}
			}

			var _vue = this;

			setInterval(_vue.timeReactive, 5000);

			setTimeout(function () {
				_vue.populateProvinces(),
					(_vue.disabledDates = {
						to: _vue.calcToDate(), // Disable all dates up to specific date
						from: _vue.calcFromDate(), // Disable all dates after specific date
					});

				_vue.scrollFinished = true;
			}, 1500);
		},
		computed: {
			...mapGetters([
				'getProfile',
				'xkey',
				'isAuthenticated',
				'isProfileLoaded',
				'acceptCookies',
				'checkAge',
			]),
			...mapState({
				profile: (state) => state.user.profile,
				shipData: (state) => state.user.defaultShipData,
			}),
			timeStore() {
				return this.$timestore.now;
			},
		},
	};
</script>

