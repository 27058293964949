var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widthFull back-3"},[(!_vm.popUpCheckAge)?_c('div',{staticClass:"container h-100 pb-5 pb-md-0"},[(_vm.loadingState)?_c('loading'):_vm._e(),_c('div',{staticClass:"center"},[_vm._m(0),(!_vm.block)?_c('div',{staticClass:"mt-0 pb-2"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"row justify-content-md-center pb-2"},[_c('div',{staticClass:"col-md-4 col-12 mt-5 mt-md-0 mb-4 mb-md-0 text-left"},[(_vm.vw > 600)?_c('small',{staticClass:"vertical uppercase theme Bold"},[_vm._v("giorno")]):_vm._e(),_c('div',{staticClass:"group mt-2 ml-0 mr-0 pl-0 pr-0 center mt-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.day),expression:"day"},{name:"validate",rawName:"v-validate",value:({
                    required: true,
                    integer: true,
                    regex: /^([0-9]+)$/,
                    min: 1,
                    max: 2,
                    min_value: 1,
                    max_value: 31,
                  }),expression:"{\n                    required: true,\n                    integer: true,\n                    regex: /^([0-9]+)$/,\n                    min: 1,\n                    max: 2,\n                    min_value: 1,\n                    max_value: 31,\n                  }"}],ref:"day",staticClass:"grey transparentBackground",class:{ 'is-invalid': _vm.errors.has('day'), valid: !!_vm.day },attrs:{"type":"text","id":"day","name":"day","placeholder":"GG","data-vv-as":"Giorno"},domProps:{"value":(_vm.day)},on:{"blur":_vm.handleBlur,"input":function($event){if($event.target.composing){ return; }_vm.day=$event.target.value}}}),_c('span',{staticClass:"highlight bbaRed"}),_c('span',{staticClass:"bar bbaRed"}),_c('label',{staticClass:"niceInputLabel grey",attrs:{"for":"day"}}),(_vm.errors.has('day'))?_c('div',{staticClass:"text-left invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("day"))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-md-7 text-left"}),_c('div',{staticClass:"col-md-4 col-12 mb-4 mb-md-0 text-left"},[(_vm.vw > 600)?_c('small',{staticClass:"vertical uppercase theme Bold"},[_vm._v("mese")]):_vm._e(),_c('div',{staticClass:"group mt-2 ml-0 mr-0 pl-0 pr-0 mt-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.month),expression:"month"},{name:"validate",rawName:"v-validate",value:({
                    required: true,
                    integer: true,
                    regex: /^([0-9]+)$/,
                    min: 1,
                    max: 2,
                    min_value: 1,
                    max_value: 12,
                  }),expression:"{\n                    required: true,\n                    integer: true,\n                    regex: /^([0-9]+)$/,\n                    min: 1,\n                    max: 2,\n                    min_value: 1,\n                    max_value: 12,\n                  }"}],ref:"month",staticClass:"grey transparentBackground borderBoth",class:{
                    'is-invalid': _vm.errors.has('month'),
                    valid: !!_vm.month,
                  },attrs:{"type":"text","id":"month","name":"month","placeholder":"MM","data-vv-as":"mese"},domProps:{"value":(_vm.month)},on:{"input":function($event){if($event.target.composing){ return; }_vm.month=$event.target.value}}}),_c('span',{staticClass:"highlight bbaRed"}),_c('span',{staticClass:"bar bbaRed"}),_c('label',{staticClass:"niceInputLabel grey",attrs:{"for":"month"}}),(_vm.errors.has('month'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("month"))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-md-7 text-left"}),_c('div',{staticClass:"col-md-4 col-12 mb-4 mb-md-0 text-left"},[(_vm.vw > 600)?_c('small',{staticClass:"vertical uppercase theme Bold"},[_vm._v("anno")]):_vm._e(),_c('div',{staticClass:"group mt-2 ml-0 mr-0 pl-0 pr-0 mt-0 pr-md-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.year),expression:"year"},{name:"validate",rawName:"v-validate",value:({
                    required: true,
                    integer: true,
                    regex: /^([0-9]+)$/,
                    min: 4,
                    max: 4,
                    min_value: 1900,
                    max_value: 2019,
                  }),expression:"{\n                    required: true,\n                    integer: true,\n                    regex: /^([0-9]+)$/,\n                    min: 4,\n                    max: 4,\n                    min_value: 1900,\n                    max_value: 2019,\n                  }"}],ref:"year",staticClass:"grey transparentBackground",class:{ 'is-invalid': _vm.errors.has('year'), valid: !!_vm.year },attrs:{"type":"text","id":"year","name":"year","placeholder":"AAAA","data-vv-as":"Anno"},domProps:{"value":(_vm.year)},on:{"input":function($event){if($event.target.composing){ return; }_vm.year=$event.target.value}}}),_c('span',{staticClass:"highlight bbaRed"}),_c('span',{staticClass:"bar bbaRed"}),_c('label',{staticClass:"niceInputLabel grey",attrs:{"for":"year"}}),(_vm.errors.has('year'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("year"))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-7 text-left"}),(
                !_vm.errors.has('year') &&
                !_vm.errors.has('month') &&
                !_vm.errors.has('day') &&
                _vm.year &&
                _vm.month &&
                _vm.day &&
                _vm.adult()
              )?_c('div',{staticClass:"col-12 col-md-12 mb-3 mr-0 pr-0"}):_vm._e(),(_vm.year && _vm.month && _vm.day && !_vm.adult())?_c('div',{staticClass:"col-12 col-md-12 mb-3"},[_c('h3',{staticClass:"theme back-color-2 mt-3 Bold uppercase"},[_vm._v(" Devi essere maggiorenne per partecipare! ")])]):_vm._e()])])]):_c('div',{staticClass:"maxSm"},[_vm._m(1)])])],1):_c('div',[_c('Home')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center center"},[_c('div',{staticClass:"col-12 center mt-5 pt-5"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/images/logo.png")}})]),_c('div',{staticClass:"col-12 center"},[_c('h5',{staticClass:"theme Bold mt-5",staticStyle:{"font-size":"20px"}},[_vm._v(" INSERISCI LA TUA DATA DI NASCITA: ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"white center"},[_c('b',[_vm._v(" UNFORTUNATELY THIS WEBSITE CANNOT BE VIEWED FROM YOUR CURRENT LOCATION ")])])}]

export { render, staticRenderFns }