var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widthFull relative customBg  padding-top-calc  min-height-calc loginBack",attrs:{"id":"forgotPasswordH"}},[_c('div',{staticClass:"container-fluid pt-5"},[_vm._m(0),(_vm.loadingState)?_c('loading'):_vm._e(),_vm._m(1),_c('form',{staticClass:" container ",attrs:{"id":"forgot-form"},on:{"submit":function($event){$event.preventDefault();return _vm.forgotValidation.apply(null, arguments)}}},[(!_vm.state)?_c('div',{staticClass:"pb-5 "},[_c('div',{staticClass:"form-row justify-content-center"},[_c('div',{staticClass:"col-md-8 col-lg-4 mb-3"},[_c('div',{staticClass:"group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username_player),expression:"username_player"},{name:"validate",rawName:"v-validate",value:({
                  required: true,
                  email: true,
                  temporaryEmail: true,
                  plusIssueEmail: true,
                  max: 56,
                }),expression:"{\n                  required: true,\n                  email: true,\n                  temporaryEmail: true,\n                  plusIssueEmail: true,\n                  max: 56,\n                }"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.errors.has('username_player'),
                  valid: !!_vm.username_player,
                },attrs:{"type":"text","id":"username_player","name":"username_player","data-vv-as":"Email"},domProps:{"value":(_vm.username_player)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username_player=$event.target.value}}}),_c('span',{staticClass:"highlight"}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"username_player"}},[_vm._v("La tua mail")]),(_vm.errors.has('username_player'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("username_player"))+" ")]):_vm._e(),(_vm.error && _vm.oksee)?_c('div',{staticClass:"mb-3"},[_c('span',{staticClass:"formError ml-1"},[_vm._v(_vm._s(_vm.error))])]):_vm._e()])])]),_vm._m(2)]):(_vm.state)?_c('div',[_vm._m(3),_c('div',{staticClass:"form-row justify-content-center"},[_c('div',{staticClass:"col-md-6 mb-3 text-center"},[_c('router-link',{staticClass:"btn btn-primary mb-4 px-5",attrs:{"to":"/"}},[_vm._v("CONTINUA")])],1)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row pt-5  pb-5 justify-content-center"},[_c('div',{staticClass:"text-center  pb-3"},[_c('img',{staticClass:"img-fluid  title-img",attrs:{"src":require("../../assets/images/recupero_password.png")}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center  pt-4"},[_c('div',{staticClass:"col-8 text-center"},[_c('h1',{staticClass:"col-12 center Regular pb-2 color-1 uppercase"},[_vm._v(" Ripristina Password ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-row justify-content-center"},[_c('div',{staticClass:"col-md-6 mb-3 mt-3 text-center"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v(" RECUPERA ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-row justify-content-center"},[_c('div',{staticClass:"col-md-6 mb-3 mt-3"},[_c('h4',{staticClass:"center Regular",attrs:{"for":"email","role":"alert"}},[_vm._v(" Ti è stata inviata una mail con il link per reimpostare la tua password di accesso, controlla nella tua posta in arrivo. "),_c('br'),_c('br'),_vm._v(" Se non dovessi trovarla controlla anche nella casella spam. ")])])])}]

export { render, staticRenderFns }