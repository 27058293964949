 <template>
	<div
		class="widthFull relative h-100 loginBack"
		id="loginH"
	>
		<!--
	  <div class="loginLine">
	  </div>
	  <div class="row  pb-4 pt-3 center divider">
		</div>
	-->

		<div class="container-fluid pt-5  ">
			<loading v-if="loadingState" />
			<div class="row justify-content-center mt-2 pt-5 pb-5 ">
				<!-- <div :class="{'back-white-2':vw<1024, 'col-lg-6 center':vw>1024 ,'col-md-10 center':vw<1025}"> -->
				<div :class="{ 'col-lg-6 center':vw>1024 ,'col-md-10 center':vw<1025}">
					<form
						class="login mb-5 custForm "
						id="login-form"
						@submit.prevent="login"
					>
						<div
							v-if="confirmed"
							class=""
						>

							<div class="form-row justify-content-center mb-5">
								<div class="text-center  pb-3"><img
										src="../../assets/images/accedi.png"
										class="img-fluid  "
									/></div>
							</div>

							<div class="form-row justify-content-center ">
								<div class="col-10 text-left col-md-8 col-lg-8 mb-3">
									<div class="group">

										<input
											type="text"
											v-model="username_player"
											@blur="checkIfExistEmail"
											v-validate="{required:true,email: true, temporaryEmail:true, plusIssueEmail: true, max: 56}"
											class="form-control  inputBold"
											id="username_player"
											name="username_player"
											data-vv-as="Email"
											:class="{ 'is-invalid': errors.has('username_player'), valid: !!username_player }"
										>
										<span class="highlight"></span>
										<span class="bar"></span>
										<label
											class="niceInputLabel Medium text-left "
											for="username_player"
										>Email</label>

										<div
											v-if="errors.has('username_player')"
											class="invalid-feedback"
										>
											{{ errors.first("username_player") }}

										</div>
										<div
											v-if="socialEmail && socialEmail != 'Manual'"
											class="text-danger mt-3"
										>
											Sei registrato con: <strong class="text-capitalize">{{ socialEmail }}</strong>. Per effetuare accesso clicca sulla apposita icona
										</div>
										<div
											v-if="socialEmail == 'Manual'"
											class="text-danger mt-3"
										>
											<b>Siamo spiacenti ma non è possibile collegare il tuo account social,
												hai già effettuato una registrazione utilizzando questo indirizzo e-mail.
												Puoi accedere utilizzando password scelto in fase di iscrizione.
											</b>
										</div>
									</div>
								</div>
							</div>

							<div class="form-row justify-content-center">
								<div class="col-10 text-left col-md-8 col-lg-8 mb-3">
									<div class="group">

										<input
											type="password"
											v-model="password_player"
											v-validate="{required:true, passwordCheck: true, min: 8, max: 56}"
											class="form-control inputBold"
											id="password_player"
											name="password_player"
											data-vv-as="Password"
											:class="{ 'is-invalid': errors.has('password_player'), valid: !!password_player }"
										>
										<span class="highlight"></span>
										<span class="bar"></span>
										<label
											class="niceInputLabel Medium theme text-left"
											for="password_player"
										>Password</label>

										<div
											v-if="errors.has('password_player')"
											class="invalid-feedback"
										>
											{{ errors.first("password_player") }}
										</div>

										<div
											v-if="loginerror"
											class="mb-3"
										>
											<span class="formError ml-1">Email o password errati.</span>
										</div>
									</div>
								</div>
							</div>
							<div class="form-row justify-content-center">
								<div class="col-12  col-md-8 col-lg-8 mb-3 ">
									<h3 class="Regular pl-3 text-right-md text-center theme mr-lg-3">
										<router-link
											class="underline theme-1"
											to="/forgot-password"
										>Password dimenticata?</router-link>
									</h3>
								</div>
							</div>
							<div class="col-12  center ">
								<button
									class="btn btn-primary  Regular uppercase mt-5"
									type="submit"
								>
									ACCEDI
								</button>
							</div>
							<div class="form-row justify-content-center Regular">

								<div
									v-if="social && social != 'Manual'"
									class="text-danger mb-5 "
								>
									Sei registrato con altra social network: <strong class="text-capitalize">{{ social }}</strong> Per effetuare accesso clicca sulla apposita icona
								</div>
								<div
									v-else-if="social == 'Manual'"
									class="text-danger mt-3"
								>
									<b>Siamo spiacenti ma non è possibile collegare il tuo account social,
										hai già effettuato una registrazione utilizzando questo indirizzo e-mail.
										Puoi accedere utilizzando password scelto in fase di iscrizione.
									</b>
								</div>

							</div>
							<!--
        <SocialLogin type="accedere" class="col-md-6 mt-3 mb-1" />
      -->

						</div>

						<div v-else-if="resend_success">
							<div class="form-row justify-content-center mt-5">
								<div class="col-md-10 mb-3 center">
									<p class="h5 center Regular uppercase pt-5 mt-5">L'e-mail di conferma della registrazione è stata inviata alla tua casella di posta elettronica</p>
									<router-link
										class="btn btn-primary  Regular uppercase mt-5"
										to="/login"
										@click.native="resetState"
									>
										Accedi
									</router-link>
								</div>
							</div>
						</div>
						<div v-else>
							<div class="form-row justify-content-center mt-5 Regular">

								<div
									style="font-size:1.5em;"
									class="col-md-10 mb-3 theme center "
								>
									<p
										style="font-weight:bold;"
										class="h4 black center mt-md-5 "
									>Non hai ricevuto l'e-mail di conferma della registrazione?</p>
									<p class="small white">Controlla che il tuo gestore di posta elettronica non abbia inserito il messaggio nella cartella di posta indesiderata (spam) o con destinatario sconosciuto.</p>
									<p class="small white">Se sono passati diversi minuti da quando hai fatto la richiesta e il messaggio non è ancora arrivato clicca sul bottone qui sotto per chiederne il reinivio.</p>

								</div>

								<div class="col-md-12 center ">
									<button
										class="btn btn-primary  Regular uppercase mt-5"
										v-on:click.prevent="resendConfirmEmail"
									>
										Invia
									</button>
								</div>

							</div>
						</div>

					</form>

					<h3
						v-if="moment(finish) > moment()"
						class="pb-4 white Regular"
					>Non sei registrato?</h3>
					<h5
						v-if="moment(finish) > moment()"
						class="uppercase loginText mt-3"
					>
						<!--Se non sei registrato-->
						<router-link
							class="btn btn-primary  Regular uppercase"
							to="/registration"
						>
							Registrati
						</router-link>

					</h5>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
import { AUTH_REQUEST, AUTH_SOCIAL } from "actions/auth";
import { mapGetters, mapState } from "vuex";

const moment = require("moment");

import { checkUniqueMixin } from "../../mixins/checkUniqueMixin";
import { sharedEnvMixin } from "../../mixins/sharedEnvMixin";
import { popupConfigurationMixin } from "../../mixins/popupConfigurationMixin";
import { gtagMixin } from "../../mixins/gtagMixin";
import { axiosMixin } from "../../mixins/axiosMixin";

export default {
  name: "login",
  components: {},
  mixins: [
    checkUniqueMixin,
    sharedEnvMixin,
    popupConfigurationMixin,
    gtagMixin,
    axiosMixin,
  ],
  data() {
    return {
      username_player: "",
      password_player: "",
      token: "",
      loading: false,
      vw:window.innerWidth,
      socialEmail: false,
      social: "",
      confirmed: true,
      resend_success: false,
      loadingState: false,
      passwordFieldType: "password",
      moment: moment,
    };
  },
  methods: {
    scrollToTop: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo(".main", 700, { offset: -2000 });
      }, 100);
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    showAlert() {
      // Use sweetalret2
      this.$swal({
        type: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
    login: function () {
      const { username_player, password_player } = this;

      this.$validator.validate().then((valid) => {
        if (valid) {
          var data = this.$encryptByAES({
            username_player,
            password_player,
          });

          this.$store
            .dispatch(AUTH_REQUEST, { data })
            .then((resp) => {
              const path = this.$route.path;

              path == "/" ? this.scrollToTop() : this.$router.push("/");

              this.gtagCheck("login", username_player, "success", "success");
            })
            .catch((err) => {
              // this.popupErrorCode(err.response.data.statusCode);
            });
        }
      });
    },

    resetState: function () {
      this.resend_success = false;
      this.confirmed = true;
    },

    async resendConfirmEmail() {
      const { username_player } = this;

      if (username_player) {
        let data = {
          username_player: username_player,
          HBGRF: process.env.VUE_APP_HBGRF,
          vector_HBGRF: process.env.VUE_APP_ICODE,
          from: "new_registration_email",
        };

        this.loadingState = true;
        let r = await axiosMixin.methods.axiosCall(
          "api/resendEmailForUser",
          data,
          "POST"
        );

        this.loadingState = false;

        let message = r.data.message;
        let statusCode = r.data.statusCode;
        let description = r.data.message.description;

        try {
          if (r.status < parseInt(process.env.VUE_APP_CATCH)) {
            // then
            if (message) {
              this.resend_success = true;
            }
          } else {
            this.$swal({
              type: "error",
              title: "Oops...",
              text: message,
            });
          }
        } catch (error) {
          this.$swal({
            type: "error",
            title: "Oops...",
            text: message,
          });
        }
      } else {
        this.confirmed = true;
      }
    },
  },

  watch: {
    authStatus(value) {
      if (value == "player_not_confirmed") {
        this.confirmed = false;
      }
    },
  },

  computed: {
    ...mapGetters([
      "getProfile",
      "loginerror",
      "confirmerror",
      "isAuthenticated",
      "isProfileLoaded",
      "authStatus",
    ]),
    timeStore() {
      return this.$timestore.now;
    },
    ...mapState({
      //response: state => `${state.status.response.data}`,
    }),
  },
  created() {},
  mounted: function () {
    this.$route.path != "/" ? this.scrollToTop() : "";
  },
};
</script>


<style lang="css" scoped>
	.btn.btn-primary {
		padding: 0.2rem !important;
		min-width: 250px !important;
	}

	.red {
		background-color: #b70c23;
		border-radius: 30px;
	}

	.loginLine {
		-webkit-transform: skewX(11deg);
		transform: skewX(-13deg);
		min-height: 100vh;
		width: 488px;
		top: 40px;
		height: 99.6%;
		background: #e7201d;
		position: absolute;
		left: calc(50vw - 431px);
		z-index: 0;
		-webkit-box-shadow: inset 0px 7px 20px -20px rgba(0, 0, 0, 0.75);
		box-shadow: inset 0px 7px 20px -20px rgba(0, 0, 0, 0.75);
		opacity: 0.2;
	}

	.sectionTitle {
		font-size: 99px;
		/*margin-left: calc(50vw + 20px );*/
		z-index: 3;
	}

	.divider {
		background: #000000c2;
	}

	@media (min-width: 20px) and (max-width: 533px) {
		h3 {
			font-size: 1.4rem;
		}

		.sectionTitle {
			font-size: 59px;
		}

		.loginText {
			font-size: 21px;
			/*margin-left: calc(50vw - 40px );*/
			z-index: 3;
			font-weight: 900;
			text-align: center;
		}

		.normalText {
			font-size: 21px;
			z-index: 3;
			font-weight: 900;
			text-align: center;
		}

		.mobStyles {
			font-size: 16px;
		}

		.loginLine {
			-webkit-transform: skewX(15deg);
			transform: skewX(-15deg);
			min-height: 100vh;
			width: 488px;
			top: 40px;
			height: 99.6%;
			background: #e7201d;
			position: absolute;

			left: calc((10vw * 9.1) - 899px) !important;

			z-index: 0;
			-webkit-box-shadow: inset 0px 7px 20px -20px rgba(0, 0, 0, 0.75);
			box-shadow: inset 0px 7px 20px -20px rgba(0, 0, 0, 0.75);
			opacity: 0.2;
		}
	}

	@media (min-width: 533px) and (max-width: 767px) {
		.sectionTitle {
			font-size: 59px;
		}

		.loginText {
			font-size: 21px;
			/*margin-left: calc(50vw - 40px );*/
			z-index: 3;
			font-weight: 900;
			text-align: center;
		}

		.normalText {
			font-size: 21px;
			z-index: 3;
			font-weight: 900;
			text-align: center;
		}

		.mobStyles {
			font-size: 16px;
		}

		.loginLine {
			-webkit-transform: skewX(15deg);
			transform: skewX(-15deg);
			min-height: 100vh;
			width: 488px;
			top: 40px;
			height: 99.6%;
			background: #e7201d;
			position: absolute;
			left: calc((50vw * 1.6) - 933px);
			z-index: 0;
			-webkit-box-shadow: inset 0px 7px 20px -20px rgba(0, 0, 0, 0.75);
			box-shadow: inset 0px 7px 20px -20px rgba(0, 0, 0, 0.75);
			opacity: 0.2;
		}
	}

	@media (min-width: 768px) and (max-width: 1100px) {
		.sectionTitle {
			font-size: 59px;
		}

		.loginText {
			font-size: 27px;
			/*margin-left: calc(50vw - 40px );*/
			z-index: 3;
			font-weight: 900;
			text-align: center;
		}

		.normalText {
			font-size: 29px;
			z-index: 3;
			font-weight: 900;
			text-align: center;
		}

		.loginLine {
			width: 38vw;
			top: 40px;
			height: 100%;
			background: #e7201d;
			position: absolute;
			left: calc((48vw * 0.9) - 307px);
			z-index: 0;
			box-shadow: inset 0px 7px 20px -20px rgba(0, 0, 0, 0.75);
		}
	}

	@media (min-width: 1101px) and (max-width: 1476px) {
		.sectionTitle {
			font-size: 59px;
		}

		.loginText {
			font-size: 27px;
			/*margin-left: calc(50vw - 40px );*/
			z-index: 3;
			font-weight: 900;
			text-align: center;
		}

		.normalText {
			font-size: 29px;
			z-index: 3;
			font-weight: 900;
			text-align: center;
		}
		.loginLine {
			width: 38vw;
			top: 40px;
			height: 100%;
			background: #e7201d;
			position: absolute;
			left: calc((48vw * 0.9) - 330px);
			z-index: 0;
			box-shadow: inset 0px 7px 20px -20px rgba(0, 0, 0, 0.75);
		}
	}
</style>
