<template>
	<div class=" widthFull  loginBack h-100 pb-3 pb-md-0">

		<div class="container-fluid pt-5 ">

			<div class="row justify-content-center mt-2 pt-5 pb-2 pb-md-5">
				<div class="text-center  pb-3 col-md-10"><img
						src="../../assets/images/dati.png"
						class="img-fluid  "
					/></div>
			</div>

		</div>

		<div class="container mt-2 ">
			<loading v-if="loadingState" />

			<form
				class="mt-3  pt-4 custForm"
				id="edit-user-account-form"
				@submit.prevent="editUser"
			>

				<div class="row justify-content-center">
					<div class="col-md-4 mb-3">
						<div class="group">
							<input
								type="text"
								class="form-control"
								v-model="first_name_player"
								id="first_name_player"
								disabled
								name="first_name_player"
								data-vv-as="first_name_player"
								v-validate="{required:true, max: 128}"
								:class="{ 'is-invalid': errors.has('first_name_player'), valid: !!first_name_player }"
							>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label
								class="niceInputLabel"
								for="first_name_player"
							>Nome</label>
							<div
								v-if="errors.has('first_name_player')"
								class="invalid-feedback"
							>
								{{ errors.first("first_name_player") }}
							</div>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="group">
							<input
								type="text"
								class="form-control"
								v-model="last_name_player"
								id="last_name_player"
								disabled
								name="last_name_player"
								data-vv-as="Cognome"
								v-validate="{required:true, max: 128}"
								:class="{ 'is-invalid': errors.has('last_name_player'), valid: !!last_name_player }"
							>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label
								class="niceInputLabel"
								for="last_name_player"
							>Cognome</label>

							<div
								v-if="errors.has('last_name_player')"
								class="invalid-feedback"
							>
								{{ errors.first("last_name_player") }}
							</div>
						</div>
					</div>
				</div>

				<div class="row justify-content-center">
					<div class="col-md-4 mb-3 ">
						<div class="group">
							<input
								type="text"
								v-model="email_player"
								class="form-control"
								id="email_player"
								name="email_player"
								disabled
								data-vv-as="Email"
								v-validate="{required:true,email: true, temporaryEmail:true, plusIssueEmail: true, max: 56}"
								:class="{ 'is-invalid': errors.has('email') || existEmail, valid: !!email_player }"
							>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label
								class="niceInputLabel"
								for="email_player"
							>Email</label>

							<div
								v-if="existEmail"
								class="invalid-feedback"
								style="display:block;"
							>
								<span v-if="existEmailSocial">Email digitato già presente. Sei registrato con: <strong class="text-capitalize">{{ existEmailSocial }}</strong></span>
								<span v-else>Email digitato già presente</span>
							</div>
							<div
								v-if="errors.has('email_player')"
								class="invalid-feedback"
							>
								{{ errors.first("email_player") }}
							</div>
						</div>
					</div>

					<div class="col-md-4 mb-3 ">
						<div class="group">
							<input
								type="text"
								class="form-control"
								v-model="birth_date_player"
								id="birth_date_player"
								disabled
								name="birth_date_player"
								data-vv-as="Cognome"
								v-validate="{required:true, max: 128}"
								:class="{ 'is-invalid': errors.has('birth_date_player'), valid: !!birth_date_player }"
							>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label
								class="niceInputLabel"
								for="birth_date_player"
							>Data di nascita *</label>

							<div
								v-if="errors.has('birth_date_player')"
								class="invalid-feedback"
							>
								{{ errors.first("birth_date_player") }}
							</div>
						</div>
					</div>

				</div>



		



				<!--	<div class="row justify-content-center">
      <div class="col-md-8 mb-3">
        <div class="form-check">
          <div class="group">
            <input class="form-check-input" type="checkbox" v-model="privacy"  id="privacy" name="privacy" data-vv-as="Informativa Privacy" v-validate="{required: true}" :class="{ 'is-invalid': errors.has('privacy') }">
            <label class="form-check-label" for="privacy">

              Dichiaro di aver letto e compreso il <a href="regolamento.pdf" class="yellow underline" target="_blank">regolamento</a> di partecipazione al concorso: Regolamento


            </label>
            <div v-if="errors.has('privacy')" class="invalid-feedback">
              {{ errors.first("privacy") }}
            </div>
          </div>
        </div>

        <div class="form-check">
          <div class="group">
            <input class="form-check-input" type="checkbox" v-model="privacy2"  id="privacy2" name="privacy2" data-vv-as="Informativa privacy2" v-validate="{required: true}" :class="{ 'is-invalid': errors.has('privacy2') }">
            <label class="form-check-label" for="privacy2">

              Dichiaro di aver ricevuto e preso visione <a class="yellow underline"  href="privacy.pdf" target="_blank">dell’Informativa</a> per il trattamento dei dati

            </label>
            <div v-if="errors.has('privacy2')" class="invalid-feedback">
              {{ errors.first("privacy2") }}
            </div>
          </div>
        </div>

        <div class="form-check">

          <p class="mt-4">
            Avendo preso visione dell’Informativa per il trattamento dei dati, consapevole che il mio consenso è puramente facoltativo, oltre che revocabile in qualsiasi momento:
          </p>

          <div class="group">

            <label class="form-check-label widthFull" for="privacy3">
            -	 esprimo il consenso al trattamento dei miei dati da parte di Davide Campari S.p.A. per le finalità finalità di marketing: comunicazioni promozionali e commerciali relative a servizi/prodotti o eventi aziendali;
            </label>

            <div class="form-check form-check-inline mt-2">
              <label class="form-check-label" v-on:click="privacy3 = 1">
                <input type="radio" class="form-check-input inline mr-3" v-model="privacy3" v-validate="'required|included:1,0'" value="1" name="privacy3" :class="{ 'is-invalid': errors.has('privacy3') }">ACCONSENTO
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label" v-on:click="privacy3 = 0">
                <input type="radio" class="form-check-input inline mr-3" v-model="privacy3" value="0" name="privacy3" :class="{ 'is-invalid': errors.has('privacy3') }">NON ACCONSENTO
              </label>
            </div>
            <br/>
            <span class="errorMes" v-show="errors.has('privacy3')">
              Il consenso deve essere accettato oppure rifiutato
            </span>

          </div>
        </div>

        <div class="form-check">
          <div class="group">

            <label class="form-check-label widthFull" for="privacy4">
              - esprimo il consenso al trattamento dei miei dati da parte di Davide Campari S.p.A. per le finalità di profilazione: analisi delle preferenze, abitudini, comportamenti, interessi, al fine di inviare comunicazioni commerciali personalizzate.
            </label>


            <div class="form-check form-check-inline mt-2">
              <label class="form-check-label" v-on:click="privacy4 = 1">
                <input type="radio" class="form-check-input inline mr-3" v-model="privacy4" v-validate="'required|included:1,0'" value="1" name="privacy4" :class="{ 'is-invalid': errors.has('privacy4') }">ACCONSENTO
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label" v-on:click="privacy4 = 0">
                <input type="radio" class="form-check-input inline mr-3" v-model="privacy4" value="0" name="privacy4" :class="{ 'is-invalid': errors.has('privacy4') }">NON ACCONSENTO
              </label>
            </div>
            <br/>
            <span class="errorMes" v-show="errors.has('privacy4')">
              Il consenso deve essere accettato oppure rifiutato
            </span>



          </div>
        </div>

        <div class="form-check mt-5">
          <p>

          </p>
        </div>

      </div>

		</div>-->
				<div class="row justify-content-center mt-3 mt-md-5">
					<div class="col-12 center">
						<router-link
							v-if="moment() < moment(finish)"
							class="mr-md-4 mb-md-5 btn btn-primary  Regular uppercase"
							to="/play"
						> Partecipa </router-link>
					</div>
				</div>
				<div
					class="col-md-12 center badge badge-danger"
					v-if="editStatus == 'error'"
				>
					Errore conessione
				</div>

				<!--<div class="col-md-12 mb-5 pb-5 center">
			<button class="btn btn-light" type="submit">
        Salva
      </button>
		</div>-->

			</form>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { USER_REQUEST } from "actions/user";
import { locationMixin } from "../../mixins/locationMixin";
import { sharedEnvMixin } from "../../mixins/sharedEnvMixin";
import { playFunctionMixin } from "../../mixins/playFunctionMixin";
import { axiosMixin } from "../../mixins/axiosMixin";
const moment = require("moment");

export default {
  name: "account",
  components: {},
  mixins: [locationMixin, sharedEnvMixin, axiosMixin, playFunctionMixin],
  data() {
    return {
      email_player: "",
      first_name_player: "",
      last_name_player: "",
      birth_date_player:'',
      cf:'',

      address: "",
      civic: "",
      zip: "",
      txt1: "",
      phone: "",
      agreement1_player: "",
      agreement1_player: "",
      agreement1_player: "",
      agreement1_player: "",
      cities: [],
      citiesState: false,
      zips: [],
      zipsState: false,
      tempMail: "",
      existEmail: false,
      existEmailSocial: false,
      loadingState: false,
      editStatus: false,
      userToken: "",
      addressData: false,
      editEnable: true,
      moment: require("moment"),
    };
  },
  methods: {
    async updateUser() {
      this.$validator.validate().then((valid) => {
        if (valid && !this.existEmail) {
          this.editUser();
        }
      });
    },
    showAlertError(value) {
      // Use sweetalret2
      this.$swal({
        type: "error",
        title: "Oops...",
        text: value + " !",
      });
    },
    showAlertSuccess(value) {
      // Use sweetalret2
      this.$swal({
        type: "success",
        title: value,
        timer: 4500,
      });
    },

    setProfileData: function () {
      this.email_player = this.profile.email_player;
      this.birth_date_player = this.profile.birth_date_player;
      this.first_name_player = this.profile.first_name_player;
      this.last_name_player = this.profile.last_name_player;
      this.userToken = this.profile.player_token_player;
      this.txt1 = this.profile.txt1_player;
      this.phone = this.profile.phone_player.substring(2);

      this.agreement1_player = this.profile.agreement1_player;
      if (this.profile.agreement2_player == 1) {
        this.agreement2_player = 1;
      } else {
        this.agreement2_player = 0;
      }
      if (this.profile.agreement3_player == 1) {
        this.agreement3_player = 1;
      } else {
        this.agreement3_player = 0;
      }
      if (this.profile.agreement4_player == 1) {
        this.agreement4_player = 1;
      } else {
        this.agreement4_player = 0;
      }
    },
  },

  mounted: function () {
    this.populateProvinces();

    var _vue = this;
    _vue.loadingState = true;
    setTimeout(function () {
      _vue.setProfileData((_vue.loadingState = false));
    }, 1500);
  },

  computed: {
    ...mapState({ profile: (state) => state.user.profile }),
    timeStore() {
      return this.$timestore.now;
    },
  },
};
</script>
