
<template>
  <div class="widthFull">
    <Header />

    <!-- COME PARTECIPARE  -->
    <Section01 id="section01" />

    <!-- PREMIO -->
    <Section02 id="section02" />


    <!-- <Play id="play" /> -->

    <!-- <Footer /> -->

    <!-- <CookieBanner /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FROM_SET } from "actions/auth";
import Login from "components/login";
import Header from "./header.vue";

import Section01 from "./section-01.vue";
import Section02 from "./section-02.vue";
import CookieBanner from "./cookie-banner.vue";

// import Footer from './footer.vue';
// import Play from '../play/wizard_no_ocr.vue';
import Play from "../play/index.vue";

export default {
  components: {
    // Footer,
    // Play,
    Login,
    Header,
    Section01,
    Section02,
    CookieBanner,
  },
  name: "home",
  computed: {
    ...mapGetters(["isAuthenticated", "authStatus", "getFrom"]),
    loading: function () {
      return this.authStatus === "loading" && !this.isAuthenticated;
    },
  },
  methods: {
    checkFrom(value) {
      this.$store.dispatch(FROM_SET, value);
    },
  },

  created: function () {},
  mounted: function () {
    if (this.$route.query.from) {
      // SETTO FROM NELLO STORE
      this.checkFrom(this.$route.query.from);
    }

    // REDIRECT TO /RECEIPTS SE AUTH E FROMWA
    if (this.isAuthenticated && this.$store.getters.getFrom == "wa") {
      this.$router.push("/receipts");
    }

    // REDIRECT TO /LOGIN SE FROMWA
    if (!this.isAuthenticated && this.$store.getters.getFrom == "wa") {
      this.$router.push("/login");
    }

    var _vue = this;
    var hash = _vue.$route.hash;
  },
};
</script>


