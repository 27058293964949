 <template>
  <div class="widthFull spotBack h-100" style="min-height: 85vh">
    <div class="track"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="container-fluid pt-5 pb-5">
          <div class="row pt-5 pb-3 center">
            <!--<h1 class="Regular white mainTitleSize widthFull center uppercase" >
     					<b>OPS!</b>
     				</h1>
          --></div>
        </div>

        <h1 class="glade Medium center widthFull mb-4">
          Il concorso tornerá attivo tra:
        </h1>

        <div class="col-md-7 pb-2 mb-5 pt-5 center orange_bg">
          <countdown :time="countdown">
            <template slot-scope="props">
              <div class="row center justify-content-center pb-5 pt-1">
                <div
                  class="
                    inlineTimeElements
                    pl-0
                    pr-0
                    ml-4
                    mr-4
                    text-center
                    relative
                  "
                >
                  <div class="timeElement darkBlue BoldFont redBg white">
                    {{ props.days }}
                  </div>
                  <p
                    v-if="props.days == 1"
                    class="classic darkBlue BoldFont mt-2 mb-0"
                  >
                    giorno
                  </p>
                  <p v-else class="classic darkBlue BoldItalic mt-2 mb-0">
                    giorni
                  </p>
                </div>

                <div
                  class="
                    inlineTimeElements
                    pl-0
                    pr-0
                    ml-4
                    mr-4
                    text-center
                    relative
                  "
                >
                  <div class="timeElement darkBlue BoldFont redBg white">
                    {{ props.hours }}
                  </div>
                  <p
                    v-if="props.hours == 1"
                    class="classic darkBlue BoldFont mt-2 mb-0"
                  >
                    ora
                  </p>
                  <p v-else class="classic darkBlue BoldItalic mt-2 mb-0">
                    ore
                  </p>
                </div>

                <div
                  class="
                    inlineTimeElements
                    pl-0
                    pr-0
                    ml-4
                    mr-4
                    text-center
                    relative
                  "
                >
                  <div class="timeElement darkBlue BoldFont redBg white">
                    {{ props.minutes }}
                  </div>
                  <p
                    v-if="props.minutes == 1"
                    class="classic darkBlue BoldFont mt-2 mb-0"
                  >
                    minuto
                  </p>
                  <p v-else class="classic darkBlue BoldItalic mt-2 mb-0">
                    minuti
                  </p>
                </div>

                <div
                  class="
                    inlineTimeElements
                    pl-0
                    pr-0
                    ml-4
                    mr-4
                    text-center
                    relative
                  "
                >
                  <div class="timeElement darkBlue BoldFont redBg white">
                    {{ props.seconds }}
                  </div>
                  <p
                    v-if="props.seconds == 1"
                    class="classic darkBlue BoldFont mt-2 mb-0"
                  >
                    secondo
                  </p>
                  <p v-else class="classic darkBlue BoldItalic mt-2 mb-0">
                    secondi
                  </p>
                </div>
              </div>
            </template>
          </countdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require("moment");
export default {
  name: "NotActive",
  data() {
    return {
      time: false,
      countdown: 0,
      datenow: "",
    };
  },
  methods: {
    timeReactive() {
      var self = this;
      var hour = this.timeStore.format("H");

      if (
        hour >= parseFloat(process.env.VUE_APP_OPEN) &&
        hour < parseFloat(process.env.VUE_APP_CLOSE) + 1
      ) {
        self.$router.push("/");
      } else {
        setTimeout(function () {
          self.timeReactive();
        }, 1500);
      }
    },
  },

  created: function () {
    //// COUNDOWN
    //var data = '2019-09-09 00:00:00'
    var _vue = this;
    setInterval(_vue.timeReactive(), 1);

    var now = this.timeStore;
    var then = now.toDate();

    var hour = now.format("H");

    if (hour < parseFloat(process.env.VUE_APP_OPEN)) {
    } else {
      var then = moment(now).add(1, "days").toDate();
    }
    then.setHours(parseFloat(process.env.VUE_APP_OPEN));
    then.setMinutes(0);
    then.setSeconds(0);
    then.setMilliseconds(0);

    then = moment(then);

    var dif = then.diff(now);

    //moment.utc(moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(then,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")
    this.countdown = dif;
  },
  computed: {
    timeStore() {
      return this.$timestore.now;
    },
  },
};
</script>

<style lang="scss" scoped>
$phone: 667px;
$tablet: 1024px;
$desktop: 1324px;
$lgscreen: 1524px;

.timeElement {
  background: white;
  color: #eb6608;
  padding: 18px;
  width: 64px;
  height: 64px;
  font-size: 20px;
  border-radius: 7px;
  font-family: Bold;
}

.headerLogo {
  max-width: 115px;

  @media screen and (max-width: $phone) {
    max-width: 70px;
  }
  @media screen and (min-width: $phone) and (max-width: $tablet) {
    max-width: 100px;
  }
  @media screen and (min-width: $tablet) and (max-width: $desktop) {
  }
  @media screen and (min-width: $desktop) and (max-width: $lgscreen) {
  }
  @media screen and (min-width: $lgscreen) {
  }
}

.redBg {
  background-color: #0b3f78;
}

.coca-logo {
  top: -5px;
  height: 40px;
  position: relative;

  @media screen and (max-width: $phone) {
    height: 20px;
  }
  @media screen and (min-width: $phone) and (max-width: $tablet) {
    height: 23px;
  }
}

.ce-logo {
  top: -15px;
  height: 65px;
  position: relative;

  @media screen and (max-width: $phone) {
    top: -7px;
    height: 37px;
  }
  @media screen and (min-width: $phone) and (max-width: $tablet) {
    top: -7px;
    height: 37px;
  }
}

.mainFont {
  font-size: 14px;
}

.big-red {
  font-family: "Gotham Rounded", sans-serif;
  font-weight: bold;
  font-size: 26px;
  color: #e30613;

  @media screen and (max-width: $phone) {
    font-size: 14px;
  }
  @media screen and (min-width: $phone) and (max-width: $tablet) {
    font-size: 16px;
  }
}

.main {
  width: 100%;
  height: 100%;
  position: relative;
  background-position: 0% 50% !important;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
