<template>
  <div class="play w-100">
    <loading v-if="loadingState || !progress" />
    <loading-ocr :active="loadingStateOcr"></loading-ocr>
    <div class="col-12">
      <div v-if="progress == 'START'" class="
          row
          justify-content-center
          pb-5
          mt-5
          pt-5
          center
          min-height-calc--footer
        ">
        <div class="col-12 col-lg-9">
          <h1 class="col-12 center Regular pb-2 color-1 uppercase">
            Partecipa
          </h1>

          <h3 class="Regular text-center color-1">{{ wizardTitle }}</h3>

          <form-wizard title="" subtitle="" class="mt-0 pb-5 custForm"
            :class="{ clearfix0: $route.hash == '#step1' || $route.hash == '' }"
            style="background: transparent !important" nextButtonText="PROCEDI" backButtonText="INDIETRO"
            finishButtonText="PARTECIPA" @on-change="stepIndex" :stepSize="stepSize" @on-complete="onComplete"
            color="#2a2316" errorColor="#b60920">
            <tab-content title="Carica lo scontrino" icon="" :before-change="validate3Tab">
              <form data-vv-scope="form-1" class="widthFull" autocomplete="qwerqrt">
                <div class="row justify-content-center relative pb-3 mt-0">
                  <div v-if="
                    documentType != 'Scontrino' && documentType != 'documento'
                  " class="blockInputs" v-on:click="shakeDocTypeTrigger"></div>
                  <div v-else-if="documentType == 'Scontrino'" class="blockInputs white" v-on:click="infoPopUp"></div>

                  <div class="col-sm-6 col-lg-4 mb-3">
                    <div :class="{ hideFileUpload: !showFrontInput }" v-if="
                      documentType != 'Scontrino' &&
                      documentType != 'documento'
                    "></div>

                    <div class="center mb-4 front-image">
                      <input :disabled="showFrontInput" type="file" v-if="documentType == 'documento'"
                        v-on:change="uploadFile" ref="fileInput1" name="invoiceFront" id="invoiceFront"
                        class="fileInput" data-vv-as="Fronte" v-validate="{
                          required: true,
                          size: 10240,
                          ext: ['jpeg', 'jpg', 'png', 'pdf'],
                          mimes: [
                            'image/jpeg',
                            'image/jpg',
                            'image/png',
                            'application/pdf',
                          ],
                        }" :class="{
  'is-invalid': errors.has('form-1.invoiceFront'),
  valid: !!invoiceFront,
  helper: !!invoiceFrontBucket,
}" />
                      <input type="file" v-else ref="fileInput1" :disabled="!!invoiceFrontBucket"
                        v-on:change="uploadStep2" name="invoiceFront" id="invoiceFront" class="fileInput"
                        data-vv-as="Fronte" v-validate="{
                          required: true,
                          size: 10240,
                          ext: ['jpeg', 'jpg', 'png', 'pdf'],
                          mimes: [
                            'image/jpeg',
                            'image/jpg',
                            'image/png',
                            'application/pdf',
                          ],
                        }" :class="{
  'is-invalid': errors.has('form-1.invoiceFront'),
  valid: !!invoiceFront,
  helper: !!invoiceFrontBucket,
}" />
                      <img class="img img-fluid" style="max-height: 100%; max-width: 200px" /><br /><br />
                      <b v-if="invoiceFrontBucket_jwt" class="color-2 Regular small uppercase underline">scontrino
                        caricato con successo!</b>
                      <label v-if="!filename1" class="niceInputLabel labelFileInput" for="invoiceFront">
                        <span v-if="!filename1" class="center red backImg" :style="{
                          backgroundImage:
                            'url(' + invoiceFrontBucket_jwt + ')!important',
                        }">
                          <p v-if="!filename1 && documentType != 'documento'" class="
                              Medium
                              center
                              white
                              back-2
                              rounded
                              absolute-p
                            "></p>

                          <p v-if="!filename1 && documentType == 'documento'" class="
                              smallText
                              sizeX
                              center
                              white
                              mb-0
                              pb-0
                              btn-primary
                            ">
                            Carica l’immagine*
                          </p>
                        </span>
                      </label>
                      <!--  <div
                        v-if="errors.has('form-1.invoiceFront')"
                        style="bottom: 10px"
                        class="invalid-feedback"
                      >
                        {{ errors.first("form-1.invoiceFront") }}
                      </div>-->

                      <!-- <div
												v-if="uploadFrontPercentage"
												class="progress"
												id="progFront"
											>
												<div
													class="progress-bar"
													id="progress-bar-front"
													role="progressbar"
													v-bind:style="{
                            width: uploadFrontPercentage + '%',
                          }"
													aria-valuenow="50"
													aria-valuemin="0"
													aria-valuemax="100"
												>
													{{ uploadFrontPercentage }} %
												</div>
											</div> -->
                    </div>
                    <p class="Regular color-1 center pt-3 h5" v-if="!filename1">
                      *Obbligatorio:
                      <br />rendere visibile nella foto l’intera prova
                      d’acquisto
                    </p>
                  </div>

                  <div v-if="!UFO && documentType != 'documento'" class="col-sm-6 col-lg-4 mb-3">
                    <div :class="{ hideFileUpload: !showRearInput }" v-if="
                      documentType != 'Scontrino' &&
                      documentType != 'documento'
                    " v-on:click="shakeDocTypeTrigger"></div>
                    <div class="front-image-1 center mb-4">
                      <input type="file" v-if="documentType == 'documento'" v-on:change="uploadFileRear"
                        :disabled="!!invoiceRearBucket" ref="fileInput2" name="invoiceRear" id="invoiceRear"
                        class="fileInput" data-vv-as="Retro" v-validate="{
                          size: 10240,
                          ext: ['jpeg', 'jpg', 'png', 'pdf'],
                          mimes: [
                            'image/jpeg',
                            'image/jpg',
                            'image/png',
                            'application/pdf',
                          ],
                        }" :class="{
  'is-invalid': errors.has('form-1.invoiceRear'),
  valid: !!invoiceRear,
  helper: !!invoiceRearBucket,
}" />
                      <input type="file" v-else ref="fileInput2" :disabled="!!invoiceRearBucket"
                        v-on:change="uploadFile" name="invoiceRear" id="invoiceRear" class="fileInput"
                        data-vv-as="Retro" v-validate="{
                          size: 10240,
                          ext: ['jpeg', 'jpg', 'png', 'pdf'],
                          mimes: [
                            'image/jpeg',
                            'image/jpg',
                            'image/png',
                            'application/pdf',
                          ],
                        }" :class="{
  'is-invalid': errors.has('form-1.invoiceRear'),
  valid: !!invoiceRear,
  helper: !!invoiceRearBucket,
}" />
                      <img class="img img-fluid" style="max-height: 100%; max-width: 200px" /><br /><br />
                      <b v-if="invoiceRearBucket_jwt" class="color-2 Regular uppercase small underline">Retro caricato
                        correttamente</b>
                      <label v-if="!filename2" class="niceInputLabel labelFileInput" for="invoiceRear">
                        <span v-if="!filename1" class="center red retroImage backImg" :style="{
                          backgroundImage:
                            'url(' + invoiceRearBucket_jwt + ')!important',
                        }">
                          <p v-if="!filename2" class="
                              Medium
                              center
                              white
                              back-2
                              rounded
                              absolute-p
                            "></p>
                        </span>
                        <!--<span
                              v-else
                              class="smallText center red retroImage backImg"
                              :style="{
                                backgroundImage:
                                  'url(' + invoiceRearBucket + ')!important',
                              }"
                            >
                              <p class="smallText center red mt-4 mb-0 pb-0">
                                {{ filename2 }}
                              </p>
                            </span>-->
                      </label>
                      <div v-if="errors.has('form-1.invoiceRear')" style="bottom: 25px" class="invalid-feedback">
                        {{ errors.first("form-1.invoiceRear") }}
                      </div>
                      <!-- <div
												v-if="uploadRearPercentage"
												class="progress"
												id="progFront"
											>
												<div
													class="progress-bar"
													id="progress-bar-front"
													role="progressbar"
													v-bind:style="{
                            width: uploadRearPercentage + '%',
                          }"
													aria-valuenow="50"
													aria-valuemin="0"
													aria-valuemax="100"
												>
													{{ uploadRearPercentage }} %
												</div>
											</div> -->
                    </div>
                    <p class="Regular center color-1 pt-3 h5" v-if="!filename2">
                      Facoltativo:
                      <br />caricare il retro solo nel caso siano riportati dati
                      aggiuntivi
                    </p>
                  </div>

                  <div class="col-8 mt-2" v-if="!filename1">
                    <div class="row justify-content-center">
                      <p class="Regular center color-1 pt-0 h5 col-12 col-md-10">
                        La scansione/foto dello scontrino dovrà riportare
                        chiaramente l’acquisto dei prodotti coinvolti nella
                        promozione e tutti i dati relativi all’acquisto (punto
                        vendita, data, ora, importo e numero).
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </tab-content>

            <tab-content title="Conferma dati" subtitle="Tutti i campi sono obbligatori" icon=""
              :before-change="validate4Tab">
              <form data-vv-scope="form-2" class="widthFull pt-3 pr-md-3 custForm" autocomplete="qwerqrt">
                <div class="row justify-content-center relative mt-3">
                  <div v-viewer v-if="!invoiceFrontBucket.toUpperCase().includes('.PDF')"
                    class="col-md-4 col-lg-5 backImageInvoice mb-5" ref="inv" style="max-height: 400px !important">
                    <font-awesome-icon @click="showViever" class="zoomImage fa-3x" icon="search-plus" size="lg" />
                    <img :src="invoiceFrontBucket_jwt" class="image img-fluid" id="inv" style="left: 0" />
                    <img v-if="invoiceRearBucket_jwt" :src="invoiceRearBucket_jwt" class="image img-fluid d-none"
                      id="inv" />
                  </div>
                  <div v-else class="col-md-4 col-lg-5 backImageInvoice mb-5" ref="inv"
                    style="max-height: 400px !important">
                    <a :href="invoiceFrontBucket_jwt" target="_blank" class="underline color-2">Clicca qui per vedere il
                      pdf</a>
                  </div>
                  <div class="col-md-8 col-lg-7 mb-4">
                    <div class="row">
                      <div class="col-md-12 col-lg-12 mb-1" :class="{ closedBlock: !!ocrDataReceipt.date }">
                        <div class="group text-left">
                          <label for="date" class="mb-0">Data prova d'acquisto</label>
                          <datepicker :language="it" v-model="date" :disabled="!!ocrDataReceipt.date"
                            :full-month-name="true" :initialView="'month'" :format="'dd MMMM yyyy'" :monday-first="true"
                            :disabledDates="disabledDates" id="date" name="date" input-class="form-control ">
                          </datepicker>

                          <input type="text" v-show="false" v-model="date" class="form-control datepicker" id="date"
                            name="date" data-vv-as="Data scontrino" v-validate="{ required: true }" :class="{
                              'is-invalid': errors.has('form-2.date'),
                              valid: !!date,
                            }" />

                          <span class="highlight"></span>
                          <span class="bar"></span>

                          <div v-if="errors.has('form-2.date')" class="invalid-feedback">
                            {{ errors.first("form-2.date") }}
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-lg-12 mb-1" :class="{ closedBlock: !!ocrDataReceipt.time }">
                        <div class="group text-left">
                          <label for="time" class="mb-0">Ora prova d'acquisto</label><br />
                          <vue-timepicker v-if="!ocrDataReceipt.time" v-model="time" class="form-control"
                            :disabled="!!ocrDataReceipt.time" :hideClearButton="true" data-vv-validate-on="none"
                            id="time" name="time" data-vv-as="Ora Scontrino" v-validate="{ emptyTime: true }" :class="{
                              disabledTime: !!time.HH && !!time.mm,
                              'is-invalid': errors.has('form-2.time'),
                              valid: !!time.HH != '',
                            }"></vue-timepicker>

                          <div v-else class="form-control valid">
                            {{ ocrDataReceipt.time }}
                          </div>

                          <span class="highlight"></span>
                          <span class="bar"></span>

                          <div v-if="errors.has('form-2.time')" class="invalid-feedback">
                            {{ errors.first("form-2.time") }}
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-lg-12 mb-1" :class="{ closedBlock: !!ocrDataReceipt.amount }">
                        <div class="group text-left">
                          <label for="price " class="mb-0">Importo totale prova d'acquisto</label>

                          <div class="d-flex justify-content-between">
                            <div class="flex-grow-1">
                              <input type="text" v-model="price" @focus="priceFocused = true"
                                :disabled="!!ocrDataReceipt.amount" class="form-control text-right euroInput" id="price"
                                name="price" data-vv-as="Importo" v-validate="{
                                  required: true,
                                  regex: /^([0-9][0-9]*)$/,
                                  max: 4,
                                }" :class="{
  'is-invalid': errors.has('form-2.price'),
  valid: !!price,
}" />
                              <span v-if="priceFocused || priceCent || price" class="pseudoEuro color-2">&euro;</span>

                              <div v-if="errors.has('form-2.price')" class="invalid-feedback">
                                {{ errors.first("form-2.price") }}
                              </div>
                            </div>
                            <div>
                              <h2 class="text-center Bold">,</h2>
                            </div>
                            <div class="flex-grow-1">
                              <input type="text" v-model="priceCent" @focus="priceFocused = true"
                                :disabled="!!ocrDataReceipt.amount" class="form-control text-left centInput"
                                id="priceCent" name="priceCent" data-vv-as="Centesimi" v-validate="{
                                  required: true,
                                  min: 2,
                                  max: 2,
                                  regex: /^([0-9]+)$/,
                                }" :class="{
  'is-invalid': errors.has('form-2.priceCent'),
  valid: !!priceCent,
}" />
                              <span v-if="priceFocused || priceCent || price" class="pseudoCent color-2">Cent.</span>

                              <div v-if="
                                errors.has('form-2.priceCent') &&
                                !errors.has('form-2.price')
                              " class="invalid-feedback">
                                {{ errors.first("form-2.priceCent") }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-lg-12 mb-1">
                        <div class="group text-left">
                          <label for="number" class="mb-0">Numero prova d'acquisto</label>
                          <input type="number" v-model="number" :disabled="!!ocrDataReceipt.number" class="form-control"
                            id="number" name="number" data-vv-as="Numero prova d'acquisto" v-validate="{
                              required: true,
                              integer: true,
                              is_not: 0,
                              max: 15,
                              min_value: 0,
                            }" :class="{
  'is-invalid': errors.has('form-2.number'),
  valid: !!number,
}" />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <!-- <label
												class="niceInputLabel"
												for="number"
											>Numero scontrino</label> -->

                          <div v-if="errors.has('form-2.number')" class="invalid-feedback">
                            {{ errors.first("form-2.number") }}
                          </div>
                        </div>
                      </div>

                      <div class="text-left mt-2 col-12" v-if="hasProduct">
                        <label class="">Indica i prodotti Kozel acquistati (minimo 4
                          prodotti):</label>
                        <ProductInsertVueSelect v-on:check="productSelected" />
                        <div v-if="!validProductQnt" class="red col-12 text-center mt-4">
                          <h5 class="Particular mod">
                            Devi acqusitare almeno 4 prodotti
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </tab-content>
          </form-wizard>
          <h5 class="Regular">
            Hai bisogno di aiuto?
            <a class="underline black" href="mailto:info@premiocerto.kozelbeer.it">info@premiocerto.kozelbeer.it</a>
          </h5>
        </div>

        <div class="container-fluid" v-if="showInfo">
          <div class="row justify-content-center">
            <div class="col-12 py-4 Medium">
              <h3 class="Regular">Regole per la prova d'acqusito</h3>
              <h4 class="Regular">
                Assicurati che la prova d'acquisto abbia le seguenti
                caratteristiche:
              </h4>

              <div>
                <!-- <img
									v-if="vw >= 768 "
									src="../../assets/images/fullscreen_rules.png"
									:alt="altImg"
									class="img-fluid"
								>
								<img
									v-else
									src="../../assets/images/phone_rules.png"
									:alt="altImg"
									class="img-fluid"
								> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="progress == 'SUCCESS_WIN'" class="row customBg min-height-calc padding-top-calc" id="SUCCESS_WIN">
        <div class="col-12 pt-4">
          <h1 class="col-12 center Regular pb-2 color-1 uppercase"></h1>
        </div>

        <div class="col-12" v-if="hasShipData">
          <form @submit.prevent="validateShipData" class="pt-0 custForm">
            <div class="row justify-content-center">
              <div class="col-12">
                <h4 class="Bold text-center my-5">
                  Inserisci i dati per la spedizione
                </h4>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-3 col-md-6 mb-3">
                <div class="group">
                  <input type="text" autocomplete="randomq" class="form-control" v-model="xuserx" id="xuserx"
                    name="xuserx" data-vv-as="Nome" v-validate="{ required: true, max: 128, min: 2 }" :class="{
                      'is-invalid': errors.has('xuserx'),
                      valid: !!xuserx,
                    }" />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label class="niceInputLabel" for="xuserx">Nome *</label>
                  <div v-if="errors.has('xuserx')" class="invalid-feedback">
                    {{ errors.first("xuserx") }}
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mb-3">
                <div class="group">
                  <input type="text" autocomplete="offw" class="form-control" v-model="lastnamex" id="lastnamex"
                    name="lastnamex" data-vv-as="Cognome" v-validate="{ required: true, max: 128, min: 2 }" :class="{
                      'is-invalid': errors.has('lastnamex'),
                      valid: !!lastnamex,
                    }" />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label class="niceInputLabel" for="lastnamex">Cognome *</label>

                  <div v-if="errors.has('lastnamex')" class="invalid-feedback">
                    {{ errors.first("lastnamex") }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-3 col-md-6 mb-3">
                <div class="group">
                  <input type="text" autocomplete="randome" class="form-control" v-model="phone" id="phone" name="phone"
                    data-vv-as="Telefono" v-validate="{
                      required: true,
                      max: 11,
                      min: 9,
                      notZeros: true,
                      regex: /^[0,3]+/,
                    }" :class="{
  'is-invalid': errors.has('phone'),
  valid: !!phone,
}" />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label class="niceInputLabel" for="phone">Telefono *</label>
                  <div v-if="errors.has('phone')" class="invalid-feedback">
                    {{ errors.first("phone") }}
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mb-3">
                <div class="group">
                  <input type="text" autocomplete="randomr" class="form-control" v-model="address" id="address"
                    name="address" data-vv-as="Indirizzo" v-validate="{ required: true, max: 128, min: 5 }" :class="{
                      'is-invalid': errors.has('address'),
                      valid: !!address,
                    }" />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label class="niceInputLabel" for="address">Indirizzo *</label>
                  <div v-if="errors.has('address')" class="invalid-feedback">
                    {{ errors.first("address") }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-3 col-md-6 mb-3">
                <div class="group">
                  <input type="text" autocomplete="randomt" class="form-control" v-model="civic" id="civic" name="civic"
                    data-vv-as="Civico" v-validate="{ required: true, max: 15 }" :class="{
                      'is-invalid': errors.has('civic'),
                      valid: !!civic,
                    }" />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label class="niceInputLabel" for="civic">Civico *</label>
                  <div v-if="errors.has('civic')" class="invalid-feedback">
                    {{ errors.first("civic") }}
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mb-3">
                <div class="group">
                  <select v-model="province" autocomplete="randomy" id="province" name="province" class="form-control"
                    data-vv-as="Provincia" v-validate="{ required: true }" :class="{
                      'is-invalid': errors.has('province'),
                      valid: !!province,
                    }">
                    <option v-if="!shipData.province" disabled value="" selected="selected"></option>
                    <option v-else :value="shipData.province" selected="selected">
                      {{ shipData.province }}
                    </option>
                    <option v-for="province in provinces" :key="province.sigla" v-bind:value="province.sigla"
                      :selected="province.sigla == shipData.province">
                      {{ province.sigla }} - {{ province.provincia }}
                    </option>
                  </select>
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label class="niceInputLabel" for="province">Provincia *</label>
                  <div v-if="provincesState">
                    <font-awesome-icon class="loadSpinner" id="loadProvince" icon="spinner" size="1x" pulse />
                  </div>
                  <div v-if="errors.has('province')" class="invalid-feedback">
                    {{ errors.first("province") }}
                  </div>
                </div>
              </div>
              <div class="col-12"></div>
              <div class="col-lg-3 col-md-6 mb-3">
                <div class="group">
                  <select class="form-control" autocomplete="randomu" v-model="city" v-on:click="checkProvince"
                    id="city" name="city" data-vv-as="Località" v-validate="{ required: true }" :disabled="citiesState"
                    :class="{
                      'is-invalid': errors.has('city'),
                      valid: !!city,
                    }">
                    <option v-if="!shipData.city" disabled value="" selected="selected"></option>
                    <option else :value="{ city: shipData.city }" selected="selected">
                      {{ shipData.city }}
                    </option>
                    <option v-for="city in cities" :key="city.comune" v-bind:value="{
                      cityistat: city.istat,
                      city: city.comune,
                    }" :selected="city.comune == shipData.city">
                      {{ city.comune }}
                    </option>
                  </select>

                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label class="niceInputLabel" for="city">Località *<span v-if="cityError" class="formError">Prima
                      scegli Provincia</span></label>
                  <div v-if="citiesState">
                    <font-awesome-icon class="loadSpinner" id="loadCity" icon="spinner" size="1x" pulse />
                  </div>
                  <div v-if="errors.has('city')" class="invalid-feedback">
                    {{ errors.first("city") }}
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mb-3">
                <div class="group">
                  <select class="form-control" autocomplete="randomi" v-model="zip" v-on:click="checkZip" id="zip"
                    name="zip" data-vv-as="Cap" v-validate="{ required: true }" :disabled="zipsState" :class="{
                      'is-invalid': errors.has('zip'),
                      valid: !!zip,
                    }">
                    <option v-if="!shipData.zip" value="" disabled :selected="true"></option>
                    <option v-for="zip in zips" :key="zip.cap" v-bind:value="zip.cap"
                      :selected="zip.cap == shipData.zip">
                      {{ zip.cap }}
                    </option>
                  </select>
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label class="niceInputLabel" for="zip">Cap *<span v-if="zipError" class="formError">Prima scegli
                      Località</span>
                  </label>
                  <div v-if="zipsState">
                    <font-awesome-icon class="loadSpinner" id="loadZips" icon="spinner" size="1x" pulse />
                  </div>
                  <div v-if="errors.has('zip')" class="invalid-feedback">
                    {{ errors.first("zip") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-6 col-md-12 mb-1">
                <div class="group">
                  <input type="text" autocomplete="randomo" class="form-control" v-model="near" id="near" name="near"
                    data-vv-as="Presso" v-validate="{ max: 128, min: 2 }" :class="{
                      'is-invalid': errors.has('near'),
                      valid: !!near,
                    }" />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label class="niceInputLabel" for="lastname">Presso / Nome sul citofono
                  </label>

                  <div v-if="errors.has('near')" class="invalid-feedback">
                    {{ errors.first("near") }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-12 center py-5">
                <button class="btn btn-primary uppercase" type="submit">
                  Salva
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div v-else-if="progress == 'SUCCESS_LOSE'" class="row customBg--repeat min-height-calc" id="SUCCESS_LOSE">
        <div class="text-center Bold w-100 flex-center">
          <div class="row justify-content-center">
            <div class="col-8">
              <div class="text-center color-1">
                <h3 class="uppercase color-2">Peccato,</h3>
                <h2 class="uppercase color-2">Non hai vinto.</h2>

                <h3 class="Regular px-md-5 px-1 px-md-5">
                  Conserva comunque lo scontrino per partecipare all'eventuale
                  estrazione di recupero, che sarà effettuata entro il 15
                  febbraio 2023. <br /><br />
                  Partecipa con una nuova prova d'acquisto <br />
                  entro le ore 24:00 del 15 gennaio 2023.
                </h3>

                <button class="btn btn-primary uppercase mt-5" @click="reload()">
                  Prova a vincere
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="progress == 'ALREADY_WIN'" class="row justify-content-center pt-md-5">
        <div class="col-10 pt-md-5 pt-5 mt-4 center">
          <h1 class="
              color-1
              mt-md-5
              widthFull
              center
              pb-0
              pt-0
              mb-0
              zindex1
              uppercase
              widthFull
              FCB
            ">
            <b>ATTENZIONE!</b>
          </h1>
        </div>
        <div class="col-12 pb-5 mb-5 mt-5 pt-5 center back-color-8">
          <h5 class="mega-1 Bold uppercase color-1">
            HAI GIá UNA PARTECIPAZIONE VINCENTE, PUOI ACCEDERE ALLA TUA AREA PER
            VEDERNE I DETTAGLI
          </h5>
        </div>
        <div class="col-12 center">
          <router-link to="/receipts" class="btn btn-primary btn-lg Bold uppercase mb-5 mt-5">
            Le tue partecipazioni
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import { USER_REQUEST, USER_SET_DEFAULT_SHIP_DATA } from "actions/user";
import Datepicker from "vuejs-datepicker";
import { it } from "vuejs-datepicker/dist/locale";
import VueTimepicker from "vue2-timepicker";
// import VideoBackground from 'vue-responsive-video-background-player';

import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import { locationMixin } from "../../mixins/locationMixin";
import { checkUniqueMixin } from "../../mixins/checkUniqueMixin";
import { sharedEnvMixin } from "../../mixins/sharedEnvMixin";
import { toDateMixin } from "../../mixins/toDateMixin";
import { innerWidthMixin } from "../../mixins/innerWidthMixin";
import { playFunctionMixin } from "../../mixins/playFunctionMixin";
import { popupConfigurationMixin } from "../../mixins/popupConfigurationMixin";
import { gtagMixin } from "../../mixins/gtagMixin";

import Product from "../lib/product-insert.vue";
import ProductInsertVueSelect from "../lib/produc-insert-vue-select.vue";

const moment = require("moment");

export default {
  name: "play",
  mixins: [
    locationMixin,
    checkUniqueMixin,
    sharedEnvMixin,
    toDateMixin,
    innerWidthMixin,
    playFunctionMixin,
    popupConfigurationMixin,
    gtagMixin,
  ],
  components: {
    ProductInsertVueSelect,
    Product,
    Datepicker,
    VueTimepicker,
    // VideoBackground,
    FormWizard,
    TabContent,
  },
  data() {
    return {
      productValidated: false,
      productToValidate: [],
      wizardTitle: "Carica la tua prova d'acquisto",
      videoLoaded: false,
      videoOpacity: 1,
      showDateInfoVar: false,
      it: it,
      timeVal: "",
      stepPopUp: true,
      priceFocused: false,
      colorType: "",
      product: "",
      advisor: false,
      productIndex: 1,
      showProdError: false,
      quantity: "",
      disabledDates: {
        to: "",
        from: "",
      },
      pdvs: [],
      productValidatedFlag: false,
      pdvsState: false,
      pdvState: false,
      pdvsError: false,
      pdv_prov: "",
      prodSel1: false,
      prodSel2: false,
      pdv: "",
      pdv_city: "",
      pdv_cities: [],
      items: {},
      provincesState: true,
      cities: [],
      citiesState: false,
      zips: [],
      zipsState: false,
      allInvoiceInfo: false,
      question: "",
      canScroll: true,
      scrollFinished: false,
      stepSize: "md",
      rebate: "",
      totPrice: "",
      priceError: false,
      timeC: false,
      countdown: 0,
      datenow: "",
      showInfo: false,
      token: "",
      id_image: "",
      zip: "",
      city: "",
      province: "",
      endedVideo: true,
      taglia: "",
      taglie: [],
    };
  },
  methods: {
    validateShipData() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.updateShipData();
        }
      });
    },

    showSuccessUpdateSHIP() {
      // Use sweetalret2
      this.$swal({
        type: "success",
        title:
          "<h3>I dati per la spedizione sono stati correttamente aggiornati</h3>",
        timer: 4500,
      });
    },
    ended: function () {
      this.endedVideo = true;
    },
    videoReady: function () {
      this.videoLoaded = true;
    },
    async recaptcha() {
      var _vue = this;
      const recaptcha = _vue.$recaptchaInstance;

      // (optional) Wait until recaptcha has been loaded.
      await _vue.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      this.token = await _vue.$recaptcha("login");

      // Do stuff with the received token.
    },
    stepIndex(from, to) {
      if (this.scrActiv) {
        //this.$scrollTo('.main', 700, { offset: -170 });
        if (to == 0) {
          this.$route.hash != "#step1"
            ? this.$router.push({ hash: "#step1" })
            : "";
        }
        if (to == 1) {
          this.$route.hash != "#step2"
            ? this.$router.push({ hash: "#step2" })
            : "";
        }
        if (to == 2) {
          this.$route.hash != "#step3"
            ? this.$router.push({ hash: "#step3" })
            : "";
        }
      }

      switch (to) {
        case 0:
          this.wizardTitle = "Carica la tua prova d'acqusto";
          break;

        case 1:
          this.wizardTitle = "Conferma i dati della prova d’acquisto";

          break;

        default:
          this.wizardTitle = "Carica la tua prova d'acqusto";
          break;
      }
    },

    productSelected(prod, valid) {
      this.productToValidate = prod;
      this.productValidated = valid;
      this.productValidatedFlag = valid;
    },

    showViever() {
      var el = this.$refs.inv;
      const viewer = el.$viewer;
      viewer.show();
    },
    timeReactive() {
      if (this.timeStore > moment(process.env.VUE_APP_FINISH)) {
        window.location.replace("/");
      }
    },

    sessionFail: function () {
      this.$swal({
        type: "error",
        title: "",
        html: '<h4 class="smallText">La tua sessione è scaduta. Effettua nuovamente l`operazione.</h4>',
        confirmButtonText: "CHIUDI",
        onClose: () => {
          this.$router.push("/");
          setTimeout(function () {
            window.location.reload();
          }, 500);
        },
      });
    },
    reload: function () {
      window.location.reload();
    },

    validateFirstTab: function () {
      return this.$validator.validateAll("form-1");
    },
    validateSecondTab: function () {
      return this.$validator.validateAll("form-3");
    },
    validate3Tab: function () {
      if (this.invoiceFront) {
        return this.$validator.validateAll("form-1");
      } else {
        return false;
      }
    },
    validate4Tab: function () {
      return this.$validator.validateAll("form-2");
    },
    onComplete: function () {
      let _vue = this;
      if (!this.checkMinProdQnt()) return false;

      this.$validator.validate().then((valid) => {
        if (valid) {
          if (_vue.hasProduct) {
            _vue.productToValidate = _vue.checkArrayProd(
              _vue.productToValidate
            );
            if (_vue.productValidatedFlag) _vue.play();
          } else {
            _vue.play();
          }
        }
      });
    },
    checkArrayProd(arr) {
      let removeProductIncomplete = [];

      for (let i = 1; i < arr.length; i++) {
        const element = arr[i];

        for (const key in element) {
          // console.log(element[key]);
          // console.log(element[key] === '');

          if (
            element[key] === '' ||
            element[key] === 0 ||
            element[key] === '0'
          ) {
            removeProductIncomplete.push(i);
            // console.log(removeProductIncomplete);
            break;
          }
        }
      }

      let removeProductIncompleteReverse = removeProductIncomplete.reverse();

      removeProductIncompleteReverse.forEach((element) => {
        arr.splice(element, 1);
      });

      // console.log(arr);
      this.productValidatedFlag = true
      return arr;
    },
    checkProdotti() {
      if (
        !this.rows[0].name_product ||
        !this.rows[0].quantity_product ||
        !this.rows[0].amount_product
      ) {
        return false;
      } else {
        return true;
      }
    },

    lockPremi() {
      // chiamata api per lock premio
      let data = this.$encryptByAES({
        tipo_premio: this.taglia.id,
        user_token: this.profile.userToken,
        HBGRF: process.env.VUE_APP_HBGRF,
        vector_HBGRF: process.env.VUE_APP_ICODE,
      });

      this.$callLog("lockPremi", data);

      if (taglia) {
        axios({
          data: { data },
          url: process.env.VUE_APP_ROOT_API + "api/lockPremi",
          method: "POST",
          headers: {
            Authorization: "Bearer " + process.env.VUE_APP_KITTY,
            "Content-type": "application/json",
          },
        })
          .then((resp) => {
            resp.data = this.$decryptWORMY(resp.data);
            this.$respLog("lockPremi", resp.data);
          })
          .catch((resp) => {
            resp.response.data = this.$decryptWORMY(resp.response.data);
            this.$respLog("lockPremi", resp.response);
          });
      }
    },

    showDateInfo() {
      if (this.showDateInfoVar) {
        this.showDateInfoVar = false;
      } else {
        this.showDateInfoVar = true;
      }
    },
    showError(text) {
      this.$swal({
        type: "error",
        title: '<strong class="modalText danger">' + text + "</strong>",
        html: "",
        confirmButtonText: "CHIUDI",
      });
    },
    showDublicateImage(img) {
      this.$swal({
        type: "error",
        title: "Ops!",
        html:
          '<p class="center smallText">Lo scontrino:</p> <p class="center smallText">' +
          img +
          '</p><p class="center smallText"> risulta già caricata</p>',
        confirmButtonText: "CHIUDI",
        onClose: () => {
          window.location.reload();
        },
      });
    },

    infoPopUp() {
      this.$swal({
        //input: 'text',
        confirmButtonText: "Carica FRONTE &rarr;",
        imageUrl: require("../../assets/images/invoice-example.png"),
        imageClass: "minImg",
        focusCancel: false,
        focusConfirm: true,
        cancelButtonText: "Annulla",
        showCancelButton: true,
        title: "",
        html:
          '<p class="smallText text-left"><ul  class="smallText text-left"><li class="smallText">I dati sullo scontrino devono essere ben visibili. </li>' +
          '<li class="smallText">Se lo scontrino è troppo lungo piegalo a fisarmonica per mostrare solo i dati necessari</li>' +
          '<li class=" smallText text-left mt-2">La foto caricata deve avere un peso compreso fra 12KB e 10MB</li></ul></p>',
      }).then((result) => {
        if (result.value) {
          this.$refs.fileInput1.click();
        }
      });
    },

    UploadError() {
      this.$swal({
        type: "error",
        title:
          '<strong class="modalText danger">Errore caricamento immagine</strong>',
        html: "",
        confirmButtonText: "CHIUDI",
        confirmButtonColor: "#15267",
      });
    },

    scrollToTop: function () {
      var _vue = this;
      setTimeout(function () {
        //_vue.$scrollTo('.main', 700, { offset: -120 });
      }, 100);
    },
    visibilityHow(isVisible, entry) {
      if (isVisible && this.scrollFinished) {
        this.$route.hash != "#come_partecipare"
          ? this.$router.push({ hash: "#come_partecipare" })
          : "";
      }
    },
    visibilityPlay(isVisible, entry) {
      if (isVisible && this.scrollFinished) {
        this.$route.hash != "#partecipa"
          ? this.$router.push({ hash: "#partecipa" })
          : "";
      }
    },
    visibilityLogin(isVisible, entry) {
      if (isVisible && this.scrollFinished) {
        this.$route.hash != "#login"
          ? this.$router.push({ hash: "#login" })
          : "";
      }
    },

    setProfileData: async function () {
      if (process.env.VUE_APP_HAS_LOGIN == "ON") {
        await this.retrivePlays();
      } else {
        this.progress = "START";
      }

      if (process.env.VUE_APP_HAS_LOGIN == "ON") {
        this.email = this.profile.email;
        this.userToken = this.profile.id;
      } else {
        this.email = this.email;
        this.userToken = makeid(10);
      }
    },
  },

  watch: {
    prod: {
      handler(val) {
        if (val) {
          this.prodSel1 = true;
          this.advisor = false;
        } else {
          this.prodSel1 = false;
          this.advisor = false;
        }
      },
    },
    prod2: {
      handler(val) {
        if (val) {
          this.prodSel2 = true;
          this.advisor = false;
        } else {
          this.prodSel2 = false;
          this.advisor = false;
        }
      },
    },
    date: function (val) { },
    time: function (val) { },

    xuserx: function (val) {
      if (val) {
        this.xuserx = val.replace(/\d+/g, "");
        this.xuserx = this.xuserx.replace(
          /[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi,
          ""
        );
        this.xuserx =
          this.xuserx.charAt(0).toUpperCase() + this.xuserx.slice(1);
      }
    },
    lastnamex: function (val) {
      if (val) {
        this.lastnamex = val.replace(/\d+/g, "");
        this.lastnamex = this.lastnamex.replace(
          /[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi,
          ""
        );
        this.lastnamex =
          this.lastnamex.charAt(0).toUpperCase() + this.lastnamex.slice(1);
      }
    },
    pdv_city: function (val) {
      if (val) {
        this.pdv_city = val.replace(/\d+/g, "");
        this.pdv_city = this.pdv_city.replace(
          /[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi,
          ""
        );
        this.pdv_city =
          this.pdv_city.charAt(0).toUpperCase() + this.pdv_city.slice(1);
      }
    },
    number: function (val) {
      if (val) {
        this.number = val.replace(/\D+/g, "");
      }
      if (val < 0) {
        this.number = val.replace(val, 0);
      }
    },
    phone: function (val) {
      if (val) {
        this.phone = val.replace(/\D+/g, "");
      }
    },

    getProfile: function (val) {
      if (val.player_token_player) {
        this.setProfileData();
      }
    },
  },
  updated: function () { },
  created() {
    this.$validator.extend("notZeros", {
      getMessage: (field) =>
        " Il campo Numero di telefono non ha un formato valido.",
      validate: (value) => {
        // value must be > zero
        if (
          value > 1000000 &&
          value.length > 1 &&
          value != 30000000 &&
          value != 300000000 &&
          value != 3000000000 &&
          value != 30000000000
        )
          return true;
        return false;
      },
    });

    var now = this.timeStore;
    var then = moment(process.env.VUE_APP_OCR_END);
    var dif = then.diff(now, "days", true);
    this.setProfileData();

    this.countdown = parseInt(dif * 14.28);
  },
  async mounted() {
    if (this.getProfile.player_token_player) {
      this.setProfileData();
    }
    // else {
    // 	this.getProfile;
    // 	this.progress = 'START';
    // }

    this.populateProvinces();

    var now = this.timeStore;
    var then = moment(process.env.VUE_APP_OCR_END);
    var dif = then.diff(now, "hours", true);

    this.countdown = parseInt(dif);

    if (this.canScroll) {
      this.canScroll = false;
    }
    if (process.env.VUE_APP_HAS_LOGIN == "ON") {
      if (this.isAuthenticated) {
        this.$watch(
          () => { },
          (val) => { }
        );
      }
    }

    var _vue = this;

    setInterval(_vue.timeReactive, 5000);

    setTimeout(function () {
      _vue.populateProvinces(),
        (_vue.disabledDates = {
          to: _vue.calcToDate(),
          from: _vue.calcFromDate(),
        });

      _vue.scrollFinished = true;
    }, 1500);
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "xkey",
      "isAuthenticated",
      "isProfileLoaded",
      "acceptCookies",
      "checkAge",
      "getPopup",
    ]),
    ...mapState({
      profile: (state) => state.user.profile,
      shipData: (state) => state.user.defaultShipData,
    }),
    timeStore() {
      return this.$timestore.now;
    },
  },
};
</script>

<style lang="scss" scoped>
.pseudoEuro,
.pseudoCent {
  top: 3rem !important;
}
</style>
