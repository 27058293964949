<template>
	<div
		class="widthFull relative customBg  padding-top-calc  min-height-calc loginBack"
		id="forgotPasswordH"
	>

		<div class="container-fluid pt-5">
		<div class="row pt-5  pb-5 justify-content-center">

			<div class="text-center  pb-3"><img
					src="../../assets/images/recupero_password.png"
					class="img-fluid  title-img"
				/>

			</div>
		</div>
			<loading v-if="loadingState" />

			<div class="row justify-content-center  pt-4">

				<div class="col-8 text-center">
					<h1 class="col-12 center Regular pb-2 color-1 uppercase">
						Ripristina Password </h1>
				</div>

			</div>

			<form
				class=" container "
				id="forgot-form"
				@submit.prevent="forgotValidation"
			>
				<div
					v-if="!state"
					class="pb-5 "
				>
					<div class="form-row justify-content-center">

						<div class="col-md-8 col-lg-4 mb-3">
							<div class="group">
								<input
									type="text"
									v-model="username_player"
									class="form-control"
									id="username_player"
									name="username_player"
									data-vv-as="Email"
									v-validate="{
                  required: true,
                  email: true,
                  temporaryEmail: true,
                  plusIssueEmail: true,
                  max: 56,
                }"
									:class="{
                  'is-invalid': errors.has('username_player'),
                  valid: !!username_player,
                }"
								/>
								<span class="highlight"></span>

								<label
									class="niceInputLabel"
									for="username_player"
								>La tua mail</label>

								<div
									v-if="errors.has('username_player')"
									class="invalid-feedback"
								>
									{{ errors.first("username_player") }}
								</div>
								<div
									v-if="error && oksee"
									class="mb-3"
								>
									<span class="formError ml-1">{{ error }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="form-row justify-content-center">
						<div class="col-md-6 mb-3 mt-3 text-center">
							<button
								class="btn btn-primary"
								type="submit"
							>
								RECUPERA

							</button>
						</div>
					</div>
				</div>
				<div v-else-if="state">
					<div class="form-row justify-content-center">
						<div class="col-md-6 mb-3 mt-3">
							<h4
								for="email"
								class="center Regular"
								role="alert"
							>

								Ti è stata inviata una mail con il link per reimpostare la tua
								password di accesso, controlla nella tua posta in arrivo. <br><br>

								Se non dovessi trovarla controlla anche nella casella spam.
							</h4>
						</div>
					</div>
					<div class="form-row justify-content-center">
						<div class="col-md-6 mb-3 text-center">
							<router-link
								class="btn btn-primary mb-4 px-5"
								to="/"
							>CONTINUA</router-link>
						</div>
					</div>
				</div>
			</form>

		</div>
	</div>
</template>

<script>
	import { AUTH_REQUEST } from 'actions/auth';
	import { mapGetters, mapState } from 'vuex';

	import { popupConfigurationMixin } from '../../mixins/popupConfigurationMixin';
	import { axiosMixin } from '../../mixins/axiosMixin';

	export default {
		name: 'forgotpassword',

		components: {},
		mixins: [popupConfigurationMixin, axiosMixin],
		data() {
			return {
				username_player: '',
				state: false,
				error: '',
				loadingState: false,
				oksee: false,
			};
		},
		methods: {
			async forgotCall() {
				let data = {
					username_player: this.username_player,
					from: 'forgot_pwd_email',
				};
				this.loadingState = true;
				let r = await this.axiosCall('api/resendEmailForUser', data, 'POST');
				let message = r.data.message;
				this.loadingState = false;
				try {
					if (r.status < parseInt(process.env.VUE_APP_CATCH)) {
						if (message) {
							this.state = true;
							this.oksee = true;
						}
					} else {
						this.popupErrorText(
							'error',
							'Ops...',
							'<h4>Utente non presente</h4>',
							3000
						);
						this.state = false;
						this.error = process.env.VUE_APP_MSG_EMAIL_NOT_FOUND;
						this.oksee = true;
					}
				} catch (error) {
					this.popupErrorText(
						'error',
						'Ops...',
						'<h4>Utente non presente</h4>',
						3000
					);
					this.state = false;
					this.error = process.env.VUE_APP_MSG_EMAIL_NOT_FOUND;
					this.loadingState = false;
					this.oksee = true;
				}
			},

			forgotValidation: function () {
				const { username_player } = this;

				this.$validator.validate().then((valid) => {
					if (valid) {
						this.forgotCall();
					}
				});
			},
		},
		watch: {
			username_player(value) {
				this.oksee = false;
			},
		},
		computed: {
			...mapGetters([
				'getProfile',
				'loginerror',
				'isAuthenticated',
				'isProfileLoaded',
			]),
			...mapState({
				//login_error: state => `${state.login_error}`,
			}),
		},
	};
</script>